import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { MainScreenComponent } from './main-screen/main-screen.component';
import { ChartsModule } from 'ng2-charts';
import { DashboardComponent } from './main-screen/dashboard/dashboard.component';
import { MyFinancialComponent } from './main-screen/my-financial/my-financial.component';
import { AboutComponent } from './main-screen/my-financial/about/about.component';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MoneyMeasuresComponent } from './main-screen/my-financial/money-measures/money-measures.component';
import { GaugeChartModule } from 'angular-gauge-chart'
import { MyInvestmentsComponent } from './main-screen/my-financial/money-measures/my-investments/my-investments.component'
import { IncomeComponent } from './main-screen/my-financial/money-measures/income/income.component';
import { InsuranceComponent } from './main-screen/my-financial/money-measures/insurance/insurance.component';
import { LiabilitiesComponent } from './main-screen/my-financial/money-measures/liabilities/liabilities.component';
import { FinancialGoalsComponent } from './main-screen/my-financial/financial-goals/financial-goals.component';
import { GoalsComponent } from './main-screen/my-financial/financial-goals/goals/goals.component';
import { RetirementComponent } from './main-screen/my-financial/financial-goals/retirement/retirement.component';
import { MyPlanComponent } from './main-screen/my-financial/my-plan/my-plan.component';
import { CalendarComponent } from './main-screen/calendar/calendar.component';
import { CalculatorComponent } from './main-screen/calculator/calculator.component';
import { InvestmentChartComponent } from './main-screen/my-financial/money-measures/my-investments/investment-chart/investment-chart.component';
import { IncomeChartComponent } from './main-screen/my-financial/money-measures/my-investments/income-chart/income-chart.component';
import { InsuranceChartComponent } from './main-screen/my-financial/money-measures/insurance/insurance-chart/insurance-chart.component';
import { LiabilitiesChartComponent } from './main-screen/my-financial/money-measures/liabilities/liabilities-chart/liabilities-chart.component';
import { QuestionAnswerComponent } from './main-screen/my-financial/about/question-answer/question-answer.component';

// import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import * as $ from 'jquery';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

@NgModule({
  declarations: [
    AppComponent,
    
    HeaderComponent,
    MainScreenComponent,
    DashboardComponent,
    MyFinancialComponent,
    AboutComponent,
    MoneyMeasuresComponent,
    MyInvestmentsComponent,
    IncomeComponent,
    InsuranceComponent,
    LiabilitiesComponent,
    FinancialGoalsComponent,
    GoalsComponent,
    RetirementComponent,
    MyPlanComponent,
    SidebarComponent,
    CalendarComponent,
    CalculatorComponent,
    InvestmentChartComponent,
    IncomeChartComponent,
    InsuranceChartComponent,
    LiabilitiesChartComponent,
    QuestionAnswerComponent
  ],
  imports: [
    BrowserModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule,

    AppRoutingModule,
    ChartsModule,
    
    // MatStepperModule,
    //  MatInputModule,
      FormsModule,
      GaugeChartModule,
      ReactiveFormsModule,
    TabsModule.forRoot() ,
    
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })

    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
