import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormControl } from "@angular/forms";

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {
  viewMode="tab1";
  strUse:any;
  expense:any;
  constructor() { }

  ngOnInit(): void {
    
  }
  Tabchange(route:any)
  {
    this.viewMode=route;
  }
  profileForm = new FormGroup({
    Monthly: new FormControl(''),
    Items: new FormControl('')
  });
  items:any=[];
  rows:any = [];
  save()
  {
    this.items=this.profileForm.value;
    this.rows.push(this.items)
  }
  delete(row:any)
  {
    let i=this.rows.indexOf(row)
    this.rows.splice(i,1);
  }
  edit(name:any)
  {
    let i=this.rows.indexOf(name)
    this.rows.splice(i);
  }
  dropdownchange()
  {
  this.strUse = (<HTMLInputElement>document.getElementById("investment")).value;
  this.expense = (<HTMLInputElement>document.getElementById("expense")).value;
  }


}
