
<div class="row column-align-geojit-financial" style="padding-top: 12px;">


  <div class="col-lg-3 sub-section-geojit-financial"  >
    <div class="col-md-12 card bg-gradient-danger card-img-holder text-white tab-canvas-geojit-dashboard grid-margin  hide-tabview-geojit-myfinancial" style="height: 311px;  ">
      <div class="row">

        <div class="tab-slider--nav">
          <ul class="tab-slider--tabs" style="width: 100%;display: grid; ">
            <li [routerLink]="['/my-financial/about']" class="tab-slider--trigger col-md-3" [class.active]="viewMode == '/my-financial/about'" rel="tab1"
              (click)="tabChange('/my-financial/about')" style="max-width: 100%;width: 100%;">1. About Me <br><span
                class="tab-subtitle-geojit-financial">Your details & risk
                profiler</span><img *ngIf="viewMode == '/my-financial/about'" class="tick-icon-geojit-financial"
                src="assets/tabs/financial-tab-active.svg">
              <img *ngIf="viewMode== '/my-financial/money-measure' || viewMode== '/my-financial/financial-goals' || viewMode== '/my-financial/my-plan'"
                class="tick-icon-geojit-financial" src="assets/tabs/financial-tab.svg">
            </li>
            <li class="tab-slider--trigger col-md-3" [class.active]="viewMode == '/my-financial/money-measure'"
              rel="tab2" (click)="tabChange('/my-financial/money-measure')" style="max-width: 100%;width: 100%;">2.
              Money Measures<br><span class="tab-subtitle-geojit-financial">All your
                financial Planning</span><img *ngIf="viewMode == '/my-financial/money-measure'"
                class="tick-icon-geojit-financial" src="assets/tabs/financial-tab-active.svg">
              <img *ngIf="viewMode== '/my-financial/about' || viewMode== '/my-financial/financial-goals' || viewMode== '/my-financial/my-plan'"
                class="tick-icon-geojit-financial" src="assets/tabs/financial-tab.svg">
            </li>
            <li class="tab-slider--trigger col-md-3" [class.active]="viewMode == '/my-financial/financial-goals'" rel="/my-financial/financial-goals"
              (click)="tabChange('/my-financial/financial-goals')" style="max-width: 100%;width: 100%;">3. Financial Goals<br><span
                class="tab-subtitle-geojit-financial">Goals and
                retirement planning</span><img *ngIf="viewMode == '/my-financial/financial-goals'" class="tick-icon-geojit-financial"
                src="assets/tabs/financial-tab-active.svg">
              <img
                *ngIf="viewMode== '/my-financial/about' || viewMode== '/my-financial/money-measure' || viewMode== '/my-financial/my-plan'"
                class="tick-icon-geojit-financial" src="assets/tabs/financial-tab.svg">
            </li>
            <li class="tab-slider--trigger col-md-3" [class.active]="viewMode == '/my-financial/my-plan'" rel="/my-financial/my-plan"
              (click)="tabChange('/my-financial/my-plan')" style="max-width: 100%;width: 100%;">4. My Plan<br><span
                class="tab-subtitle-geojit-financial">Generate your
                financial plan</span><img *ngIf="viewMode == '/my-financial/my-plan'" class="tick-icon-geojit-financial"
                src="assets/tabs/financial-tab-active.svg">
              <img
                *ngIf="viewMode== '/my-financial/about' || viewMode== '/my-financial/money-measure' || viewMode== '/my-financial/financial-goals'"
                class="tick-icon-geojit-financial" src="assets/tabs/financial-tab.svg">

            </li>
          </ul>
        </div>

      </div>
    </div>

    <div [ngSwitch]="showInvestment">

      <div *ngSwitchCase="'Personal Profile'"
        class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas"
        style="height: 212px;    ">
        <img src="assets/dash/kyc.svg" style="width: 119px;height: 30px;margin-top: 28px;">
        <div class="bottom-right">KYC Details</div>
        <div class="card-body kyc-card-geojit-dashboard ">
          <div style="display: block">
            <p>The details given here are taken from your last updated KYC form. If you wish to update this,you may
              contact your branch manager</p>
          </div>
        </div>
      </div>


      <div *ngSwitchCase="'Risk Profiler'"
        class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas"
        style="height: 518px;    ">
        <img src="assets/dash/Yellow-kyc.svg" style="width: 165px;height: 30px;margin-top: 28px;">
        <div class="bottom-right">Profile Description</div>
        <div class="card-body kyc-card-geojit-dashboard profile-card-geojit-financial">
          <div style="display: block">
            <h6>CONSERVATIVE</h6>
            <p class="py-0">A Conservative investor values protecting principal over seeking appreciation.</p>
            <h6 class="py-0">MODERATE</h6>
            <p class="py-0">TA Moderate investor values reducing risks and enhancing returns equally</p>
            <h6 class="py-0">MODERATELY AGGRESSIVE</h6>
            <p class="py-0">A Moderately Aggressive investor primarily values higher long-term returns and is willing to
              accept>significant risk.</p>
            <h6 class="py-0">AGGRESSIVE</h6>
            <p class="py-0">An Aggressive investor values maximizing returns and is willing toaccept substantial risk.
            </p>
          </div>
        </div>
      </div>


      <div *ngSwitchCase="'Investments'">
        <app-investment-chart></app-investment-chart>
      </div>
      <div *ngSwitchCase="'Income'">
        <app-income-chart></app-income-chart>
      </div>
      <div *ngSwitchCase="'Insurance'">
        <app-insurance-chart></app-insurance-chart>
      </div>
      <div *ngSwitchCase="'Assets'">
        <app-liabilities-chart></app-liabilities-chart>
      </div>
      <div *ngSwitchCase="'Goals'">
        <div class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas goals-subsection-geojit-financial" >
          <img src="assets/goal/man-goal.svg" style="width: 100%;">
          <div class="card-body kyc-card-geojit-dashboard " style="padding: 0px 7px;">
            <div class="row" style="padding-top: 14px;">

              <div class="col-md-6 goal-investment-geojit-goal">₹ 17,168<span><br>Available<br> investable
                  Surplus</span></div>
              <div class="col-md-6 goal-investment-geojit-goal">₹ 21,982<span><br>Total monthly<br> investment
                  required</span></div>

            </div>
            <table class="table-goal-geojit-goal" style="width:100%">
              <tr>
                <th>Eshana gibin school /<br>college admission</th>
                <td>₹ 2,013</td>
              </tr>
              <tr>
                <th>Gibin new car</th>
                <td>₹ 5,557</td>
              </tr>
              <tr>
                <th>Savings bank account</th>
                <td>₹ 6,586</td>
              </tr>
              <tr>
                <th>PPF balance</th>
                <td>₹ 3,354</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'Plan'"></div>
    </div>
  </div>
  <div class="col-lg-9 width-mobile-geojit-financial">
    <div class="col-md-12 card bg-gradient-danger card-img-holder text-white tab-canvas-geojit-dashboard grid-margin" style="margin-bottom: 80px;padding: 0px;">
      <div class="row">
        <router-outlet></router-outlet>

      </div>

    </div>
    
     
      