import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormControl } from "@angular/forms";
import { ModelService } from '../../../services/model.service';
import * as $ from 'jquery';
import 'jqueryui';
@Component({
  selector: 'app-financial-goals',
  templateUrl: './financial-goals.component.html',
  styleUrls: ['./financial-goals.component.scss']
})
export class FinancialGoalsComponent implements OnInit {


  viewMode="tab1";

  constructor(public ModelService:ModelService) {
    $('#draggable-point').draggable({
      axis: 'x',
      containment: "#audio-progress"
    });
    
    $('#draggable-point').draggable({
      drag: function() {
        var offset = $(this).offset();
        var xPos = (100 * parseFloat($(this).css("left"))) / (parseFloat($(this).parent().css("width"))) + "%";
       
        $('#audio-progress-bar').css({
          'width': xPos
        });
      }
    });
}

  ngOnInit(): void {
   
  }
  Tabchange(route:any)
  {
    this.viewMode=route;

  }
  profileForm = new FormGroup({
    Monthly: new FormControl(''),
    Items: new FormControl('')
  });
  items:any=[];
  rows:any = [];
  save()
  {
    this.items=this.profileForm.value;
    this.rows.push(this.items)
  }
  delete(row:any)
  {
    let i=this.rows.indexOf(row)
    this.rows.splice(i,1);
  }
  edit(name:any)
  {
    let i=this.rows.indexOf(name)
    this.rows.splice(i);
  }
  showModal(value:any)
  {
    this.ModelService.callComponentMethod(value);
  }

}
