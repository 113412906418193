import { Component, ViewChild, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements  AfterViewInit  {
  viewMode:any='tab1';
  constructor() { }
  statusClass = 'active';
  changeClass='enable';
  change='hide';

  ngOnInit(): void {
  }
  setActiveClass(route:any)
  {
    this.viewMode=route;
    if(route=='tab1')
    {
      this.statusClass = 'active';
      this.changeClass='enable';
      this.change='hide';

    }
    else
    {
      this.statusClass = 'not-active';
      this.changeClass='disable';
      this.change='show';

    }
  }

  @Input() colors = ['#29B473', '#FFDD00'];
  @Input() data =  [ 12563,  11587];
  canvas: any;
  ctx: any;
  doughnutChartRef:any;
  @ViewChild('retirement') retirement:any;
  ngAfterViewInit() {

    this.canvas = this.retirement.nativeElement; 
    this.ctx = this.canvas.getContext('2d');

    this.doughnutChartRef = new Chart(this.ctx, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data,
            backgroundColor: this.colors
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
			}
        }
    });


   
 }



}
