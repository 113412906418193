import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { MainScreenComponent } from './main-screen/main-screen.component';
const routes: Routes = [
  {
    path: '',
    component: MainScreenComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./main-screen/main-screen.module').then(m => m.MainScreenModule)
      }]
  },
  { path: '',   redirectTo: 'dashboard', pathMatch: 'full' },
  // {
  //   path: 'main',
  //   component: MainScreenComponent,
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
