import { Injectable } from '@angular/core';
import {  Subject,ReplaySubject } from 'rxjs'
import {Component, Input} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root',
  
})


export class ModelService {
  private customSubject = new Subject<any>();
  private customSubject2 = new Subject<any>();
  private showChartCards = new ReplaySubject<any>();

  customObservable = this.customSubject.asObservable();
  customObservable2 = this.customSubject2.asObservable();
  showcards = this.showChartCards.asObservable();

  constructor() { }
  callComponentMethod(value:any) 
  {
    this.customSubject.next(value);
  }
  callTabActive(value:any) 
  {
    this.customSubject2.next(value);
  }
  showCards(route:any)
  {
    this.showChartCards.next(route);

  }

}
