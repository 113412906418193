import { Component, ViewChild, Input, OnDestroy, AfterViewInit,ElementRef,Attribute,OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements  AfterViewInit,OnInit  {

  @Input() colors = ['#29B473', '#ED809B',];
  @Input() colors2 = ['#29B473', '#FFDD00', '#0E7150','#69DBDE','#EF8C57','#7793CE'];
  @Input() colors3 = ['#29B473', '#FFDD00',];

  @Input() data =  [ 12563,  11587];
  @Input() data2 =  [ 12563,  5833];
  @Input() data3 =  [ 2586,853,2146,2236,232,0];
  captcha:boolean=false;
  canvas2: any;
  canvas: any;
  canvas3: any;

  ctx: any;
  ctx2: any;
  ctx3: any;
  IsmodelShow:boolean=false;
    doughnutChartRef:any;
  @ViewChild('retirement') retirement:any;
  @ViewChild('netWorth') netWorth:any;
  @ViewChild('portfolio') portfolio:any;
  @ViewChild('cont')el!: ElementRef;
constructor(public ModelService:NgbModal)
{
}
ngOnInit()
{
  let tisnew=this;

  let data=sessionStorage.getItem('myLSkey');
    if(data==null)
    {
      setTimeout(function(){  
        tisnew.open(tisnew.el); 
      }, 3000);
    }  
}
@HostListener('window:popstate', ['$event'])
onPopState(event:any) {
  window.location.reload();
}
open(modal:any)
{
  this.ModelService.open(this.el, {ariaLabelledBy: 'modal-basic-title'}).result.then((result:any) => {
    // this.closeResult = `Closed with: ${result}`;
  }, (reason:any) => {
    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


  });
  // [attr.data-target]="'#' + theme.themeId"

}
showSpinner!:boolean;
interval:any;
domLoaded!:boolean;
  ngAfterViewInit() {

    let data = {
      'token': 'token2',
      'name': 'name'
    };
    sessionStorage.setItem('myLSkey', JSON.stringify(data));
    this.canvas = this.retirement.nativeElement; 
this.canvas2=this.netWorth.nativeElement;
this.canvas3=this.portfolio.nativeElement;

    this.ctx3 = this.canvas3.getContext('2d');
    this.ctx = this.canvas.getContext('2d');
    this.ctx2 = this.canvas2.getContext('2d');

    this.doughnutChartRef = new Chart(this.ctx, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data,
            backgroundColor: this.colors
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
			}
        }
    });


    this.doughnutChartRef = new Chart(this.ctx3, {
      type: 'doughnut',
      data: {
      datasets: [{
          borderWidth: 3,
          weight: 20,
          hoverBorderWidth: 1,
          data: this.data3,
          backgroundColor: this.colors2
      }],
    },
      options: {
        legend: {
          display: true,
        },
        cutoutPercentage: 75,
     elements: {
    }
      }
  });
    this.doughnutChartRef = new Chart(this.ctx2, {
      type: 'doughnut',
      data: {
      datasets: [{
          borderWidth: 3,
          weight: 20,
          hoverBorderWidth: 1,
          data: this.data2,
          backgroundColor: this.colors3
      }],
    },
      options: {
        legend: {
          display: true,
        },
        cutoutPercentage: 75,
     elements: {
    }
      }
  });

 }

//  ngOnDestroy() {
//    this.doughnutChartRef.destroy();
//  }
viewModel=1;

stepNext(viewModel:any)
{
  this.viewModel=viewModel;
  for(let i=1;i<3;++i)
  {
    if(i==viewModel){this.viewModel=i+1;}
  }
}
stepPrevious(viewModel:any)
{
  this.viewModel=viewModel;
  for(let i=3;i>1;--i)
  {
    if(i==viewModel){this.viewModel=i-1;}
  }
}

}



