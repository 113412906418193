import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { Chart } from 'chart.js';
import { ModelService } from '../../../services/model.service';

@Component({
  selector: 'app-money-measures',
  templateUrl: './money-measures.component.html',
  styleUrls: ['./money-measures.component.scss']
})
export class MoneyMeasuresComponent implements OnInit {

  name = '!!!';
  viewMode = 'Investments';
  items: any = [];
  profileForm = new FormGroup({
    Member_Name: new FormControl(''),
    url: new FormControl(''),
    Relationship: new FormControl(''),
    Date_of_Birth: new FormControl(''),
    PAN_Number: new FormControl(''),
  });
  url1: any = '';
  @ViewChild('iAmNotReal') childComp: any;
  @ViewChild('fileInput') el!: ElementRef;
  imageUrl: any = 'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
  editFile: boolean = true;
  removeUpload: boolean = false;
  registrationForm = this.fb.group({
    file: [null]
  })
  constructor(public fb: FormBuilder, private cd: ChangeDetectorRef, public ModelService: ModelService) {
    this.tabChange('Investments');

  }

  rows: any = [];
  headers = ["Photo", "Member Name", "Relationship", "Date of Birth", "PAN Number", ""];

  ngOnInit(): void {
    this.items = this.fb;
  }
  save() {
    // this.items=this.fb;
    this.items = this.profileForm.value;
    this.rows.push(this.items)
    this.profileForm.value.url = this.imageUrl;
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value 
  }

  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.registrationForm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  delete(row: any) {
    let i = this.rows.indexOf(row)
    this.rows.splice(i, 1);
  }
  edit(name: any) {
    let i = this.rows.indexOf(name)
    this.rows.splice(i);
  }
  tabChange(route: any) {
    this.viewMode = route;
    this.ModelService.showCards(route);
  }



}
