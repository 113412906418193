import { Component, OnInit, ViewChild, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { reduce } from 'd3-array';
import { bindCallback } from 'rxjs';
import { Chart } from 'chart.js';
import { ModelService } from '../../../services/model.service';
@Component({
  selector: 'app-my-plan',
  templateUrl: './my-plan.component.html',
  styleUrls: ['./my-plan.component.scss']
})
export class MyPlanComponent implements AfterViewInit {
  goals = [{goal: 'Goal Defined Date',  admission: '14/12/2017', education: '2021-11-26', retirement: '15/10/2019', car: '19/06/2019',marriage:'26/03/2018'},
  {goal: 'Achieved By Date',  admission: '01/01/2022', education: '01/01/2033', retirement: '15/07/2044', car: '06/07/2023',marriage:'01/04/2045'},
  {goal: "Today's Value of Goal",  admission: '100,000', education: '1,000,000', retirement: '15,000', car: '100,000',marriage:'1,500,000'},
  {goal: "Amount Required On Goal Date",  admission: '126,611', education: '2,403,589', retirement: '11,287,165', car: '126,591',marriage:'3,5238'},
  {goal: "Achieved By Date",  admission: '4778', education: '11,287,165', retirement: '248', car: '25568',marriage:'5,52,523'},
  {goal: "Today's Value of Goal",  admission: '2369', education: '14395', retirement: '1789', car: '23488',marriage:'55,2358'},
  {goal: "Amount Required On Goal Date",  admission: '4488', education: '88395', retirement: '657', car: '5644',marriage:'2347'},
  {goal: "Achieved By Date",  admission: '2589', education: '254', retirement: '784', car: '2356',marriage:'128'},
  {goal: "Today's Value of Goal",  admission: '24488', education: '4455', retirement: '369', car: '7854',marriage:'53'},
  {goal: "Amount Required On Goal Date",  admission: '6954', education: '1478', retirement: '68', car: '125',marriage:'10'},
  {goal: "Amount Required On Goal Date",  admission: '369', education: '56977', retirement: '4789', car: '868',marriage:'68'},
];

  constructor(public ModelService:ModelService) {
  }
  activateTab(value:any)
  {
    this.ModelService.callTabActive(value);
  }

  ngOnInit(): void {
  }
  public canvasWidth = 350
  public canvasheight = 350

  public canvasWidth2 =  250
  public canvasWidth3 =  190
  public canvasWidth4 =  100

  public needleValue = 60
  public centralLabel = ''
  public name2 = ''
  public arcPadding = 10;
  public options = {
    needleUpdateSpeed: 6000,
    arcPadding: 5,
    arcPaddingColor: 'white',
    hasNeedle: true,
    needleColor: "black",
    needleStartValue: 10,
    arcColors: ["#DA6526", "#DA6526", "#DA6526", "#DA6526", "#DA6526", "#DA6526", "#DA6526",
      "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)",
      "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)"],
    arcDelimiters: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
    rangeLabelFontSize: 0
  }
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
  public barChartType: any = 'bar';
  public barChartLegend = false;

  public barChartData = [
    {
      label: 'My First Dataset',
      data: [3900, 5900, 7500, 4500, 5600, 5500, 4000, 3900, 5900, 7500, 6500, 5600, 5500, 4000, 6000],
      backgroundColor: [
        '#EBFFF6',
        '#66C3C5',
        '#EBFFF6',
        '#EBFFF6',
        '#EBFFF6',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [

        'rgba(255, 99, 132, 0.2)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 6,
      borderRadius: 16,
      barThickness: 9
    }
  
  ];

  public barChartOptions1 = {
    options: {
      borderColor: 'rgba(0, 0, 0, 0.1)',

      title: {
        display: true,
        text: 'Example Chart'
      },
      scales: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        xAxes: [{
          borderColor: 'rgba(0, 0, 0, 0.1)',
          linePercentage: 87.1,
          position: 'bottom',
          gridLines: {
            zeroLineColor: "white",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'x axis'
          },
          stacked: true
        }],
        yAxes: [{
          linePercentage: 89.1,
          stacked: true,
          display: false,
          position: 'left',
          gridLines: {
            zeroLineColor: "black",
            display: false
          },
          scaleLabel: {
            display: false,
            labelString: 'y axis'
          }
        }]
      }
    }
  };
  public barChartLabels1 = ['0', '1'];
  public barChartType1 = 'line';
  public barChartLegend1 = false;
  public barChartData1 = [
    {
      data: [65, 65, 56],
      label: 'Remote',
      fill: false,
    },
  ];
  @Input() colors = ['#29B473', '#FFDD00'];
  @Input() data = [60, 40];
  @Input() colors2 = ['#29B473', '#FFDD00', '#ED809B'];
  @Input() data2 = [60, 40,30];
  @Input() colors3 = ['#29B473', '#FFDD00'];
  @Input() data3 = [60, 40];
  captcha: boolean = false;
  canvas: any;
  ctx: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  doughnutChartRef: any;
  @ViewChild('retirement') retirement: any;
  @ViewChild('AssetWatch') AssetWatch: any;
  @ViewChild('LiabilityChart') LiabilityChart: any;


  ngAfterViewInit() {

    this.canvas = this.retirement.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.doughnutChartRef = new Chart(this.ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 3,
          weight: 20,
          hoverBorderWidth: 1,
          data: this.data,
          backgroundColor: this.colors
        }],
      },
      options: {
        legend: {
          display: true,
        },
        cutoutPercentage: 75,
        elements: {
        }
      }
    });
    this.canvas2 = this.AssetWatch.nativeElement;
    this.ctx2 = this.canvas2.getContext('2d');
    this.doughnutChartRef = new Chart(this.ctx2, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 3,
          weight: 20,
          hoverBorderWidth: 1,
          data: this.data2,
          backgroundColor: this.colors2
        }],
      },
      options: {
        legend: {
          display: true,
        },
        cutoutPercentage: 75,
        elements: {
        }
      }
    });
    this.canvas3 = this.LiabilityChart.nativeElement;
    this.ctx3= this.canvas3.getContext('2d');
    this.doughnutChartRef = new Chart(this.ctx3, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 3,
          weight: 20,
          hoverBorderWidth: 1,
          data: this.data3,
          backgroundColor: this.colors3
        }],
      },
      options: {
        legend: {
          display: true,
        },
        cutoutPercentage: 75,
        elements: {
        }
      }
    });
  }
}
