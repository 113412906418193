import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelService } from './../services/model.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss'],


})
export class MainScreenComponent implements OnInit {
 url:any;
  name = '!!!';
  viewMode:any;
  viewModel=1;
  tabactive:any='disabled';
  @ViewChild('content') el!: ElementRef;
  
  @ViewChild('investment') el2!: ElementRef;

 
  ctx: any;

  public canvasWidth =300
  public needleValue = 60
  public centralLabel = ''
  public name2= ''
  public bottomLabel = 'Contingency Cash Position'
  public bottomLabel2 = 'Debt to Asset Position'
  public bottomLabel3 = 'Savings Ratio'
  public bottomLabel4 = 'Debt Service ratio'
  public arcPadding=10;

  public options = {
      needleUpdateSpeed: 6000,
      arcPadding:5,
      arcPaddingColor: 'white',
        hasNeedle: true,
        needleColor: "black",
        needleStartValue: 10,
        arcColors: ["#DA6526","#DA6526","#DA6526","#DA6526","#DA6526","#DA6526","#DA6526",
        "rgb(239,214,19)","rgb(239,214,19)","rgb(239,214,19)", "rgb(239,214,19)","rgb(239,214,19)","rgb(239,214,19)","rgb(239,214,19)",
        "rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)"],
        arcDelimiters: [1,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95],
        rangeLabelFontSize:0
  }
  public options2 = {
    needleUpdateSpeed: 6000,
    arcPadding:5,
    arcPaddingColor: 'white',
      hasNeedle: true,
      needleColor: "black",
      needleStartValue: 10,
      arcColors: [
        "rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)",
      "rgb(239,214,19)","rgb(239,214,19)","rgb(239,214,19)", "rgb(239,214,19)","rgb(239,214,19)",
      "#EF8C57","#EF8C57","#EF8C57","#EF8C57","#EF8C57","#EF8C57",
      "#DA6526","#DA6526","#DA6526","#DA6526","#DA6526"

    ],
      arcDelimiters: [1,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95],
      rangeLabelFontSize:0
}



  constructor(public Router:Router,private modalService: NgbModal,public openModal:ModelService) { 
  
    this.openModal.showcards.subscribe((res:any) => {
        this.url=res;
    });
      // this.viewMode='main/dashboard';
      // this.Router.navigate([this.viewMode]);
      // this.Router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.viewMode=this.Router.url;
      const data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [{
          label: 'Weekly Sales',
          data: [18, 12, 6, 9, 12, 3, 9],
          backgroundColor: [
            'rgba(255, 26, 104, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(0, 0, 0, 0.2)'
          ],
          borderColor: [
            'rgba(255, 26, 104, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(0, 0, 0, 1)'
          ],
          borderWidth: 1
        }]
      };
    
      // config 
      const config:any = {
        type: 'bar',
        data,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };

  }
click()
{
  // this.Router.navigate(['my-financial']);
}
  ngOnInit(): void {
    this.openModal.customObservable.subscribe((res:any) => {
      if(res=='content'){this.open(this.el); }
      else{this.open(this.el2); }
      });
  
  
  this.openModal.customObservable2.subscribe((res:any) => {
    this.tabactive=res });

}
  Tabchange(route:any)
  {
    this.viewMode=route;
    this.Router.navigate([route]);
   
  }


  open(content:any) {
   

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result:any) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason:any) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;


    });
  }
 
  stepNext(viewModel:any)
  {
    this.viewModel=viewModel;
    for(let i=1;i<5;++i)
    {
        if(i==viewModel)
        {
          this.viewModel=i+1;
        }
    }
  }
  stepPrevious(viewModel:any)
  {
    this.viewModel=viewModel;
    for(let i=5;i>1;--i)
    {
        if(i==viewModel)
        {
          this.viewModel=i-1;
        }
    }
  }
}
