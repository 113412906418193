import { Component, ViewChild, Input,OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-income-chart',
  templateUrl: './income-chart.component.html',
  styleUrls: ['./income-chart.component.scss']
})
export class IncomeChartComponent implements OnInit {
  @ViewChild('retirement') retirement:any;
  @ViewChild('netWorth') netWorth:any;
  @ViewChild('portfolio') portfolio:any;
  constructor() { }

  ngOnInit(): void {
  }
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
  public barChartType: any = 'bar';
  public barChartLegend = false;

  public barChartData = [
    {
      label: 'My First Dataset',
      data: [3900, 5900, 7500, 4500, 5600, 5500, 4000, 3900, 5900, 7500, 6500, 5600, 5500, 4000, 6000],
      backgroundColor: [
        '#EBFFF6',
        '#66C3C5',
        '#EBFFF6',
        '#EBFFF6',
        '#EBFFF6',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
        '#EBFFF6',
        '#EBFFF6',
        '#EBFFF6'
      ],
      borderColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 6,
      borderRadius: 16,
      barThickness: 9
    }
    // {data: [2800, 48, 40, 81,45], label: 'Visit'},
    // {data: [2800, 48, 40, 81,45], label: 'Visit'},
    // {data: [2800, 48, 40, 81,45], label: 'Visit'},
    // {data: [6500, 59, 80, 60, 50,34], label: 'Remote'},
    // {data: [2800, 48, 40, 81,45], label: 'Visit'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [6500, 59, 80, 60, 50,34], label: 'Remote'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [65, 59, 80, 60, 50,34], label: 'Remote'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'},
    // {data: [28, 48, 40, 81,45], label: 'Visit'}
  ];

  public barChartOptions1 = {
    options: {
      indexAxis: 'y',
      borderColor: 'rgba(0, 0, 0, 0.1)',

      title: {
        display: true,
        text: 'Example Chart'
      },
      scales: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        xAxes: [{
          borderColor: 'rgba(0, 0, 0, 0.1)',
          linePercentage: 87.1,
          position: 'bottom',
          gridLines: {
            zeroLineColor: "white",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'x axis'
          },
          stacked: true
        }],
        yAxes: [{
          linePercentage: 89.1,
          stacked: true,
          display: false,
          position: 'left',
          gridLines: {
            zeroLineColor: "black",
            display: false
          },
          scaleLabel: {
            display: false,
            labelString: 'y axis'
          }
        }]
      }
    }
  };
  public barChartLabels1 = ['0', '1'];
  public barChartType1 = 'line';
  public barChartLegend1 = false;
  public barChartData1 = [
    {
      data: [65, 65, 56],
      label: 'Remote',
      fill: false,
    },
    // { data: [81, 81,567], label: 'Visit', fill: false },
    // { data: [10, ,70, 70], 
    //   fill: false,
    // },
    // { data: [81, 81,87,76], label: 'Visit', fill: false }
  ];

  @Input() colors2 = ['#29B473', '#FFDD00'];

  @Input() data2 =  [ 12563,  5833];
  captcha:boolean=false;
  canvas2: any;
  ctx2: any;
  doughnutChartRef:any;
 ngAfterViewInit() {

  this.canvas2=this.netWorth.nativeElement;
  
      this.ctx2 = this.canvas2.getContext('2d');
  
  
    
  
     
      this.doughnutChartRef = new Chart(this.ctx2, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data2,
            backgroundColor: this.colors2
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
      }
        }
    });
  }



}
