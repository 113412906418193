<div   class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 250px;margin-top: 11px;">
    <h6 class="bottom-right ">ASSETS/LIABILITY</h6>
    <div class="card-body kyc-card-geojit-dashboard ">
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas #retirement style="width: 106px;height: 106px;"></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 2,833<br><span>Assets</span></li>
          </ul>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'>₹ 1,863<br><span>Liability</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div   class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 310px;margin-top: 11px;">
    <h6 class="bottom-right " >ASSETS</h6>
    <div class="card-body kyc-card-geojit-dashboard ">
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas #assets style="width: 106px;height: 106px;"></canvas>
        </div>
        <div class="col-md-7 chart-label-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 2,833<br><span>Near Cash</span></li>
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;width: max-content;'>₹ 1,863<br><span>PPF,EPF & Gratuity</span></li>
          </ul>
        </div>
        <div class="col-md-5 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 455<br><span>Physical</span></li>
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 0<br><span>Financial</span></li>

          </ul>
        </div>
      </div>
    </div>
  </div>
  <div   class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 250px;margin-top: 11px;">
    <h6 class="bottom-right " >LIABILITY</h6>
    <div class="card-body kyc-card-geojit-dashboard ">
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas #liability style="width: 106px;height: 106px;"></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'>₹ 2,833<br><span>CD Due</span></li>
          </ul>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/orangel.svg");height: 58px;'>₹ 1,863<br><span>Loan Due</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>