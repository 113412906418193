<div class="col-sm-12 calculater-main-geojit-caculator" >
  <div class="calculater-section-geojit-caculator" [ngClass]="statusClass">
    <div class="col tab-slider--nav" [ngClass]="changeClass">
      <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;height: 54px; ">
        <li class="tab-slider--trigger col-md-6" rel="tab1" [class.active]="viewMode == 'tab1'"
          (click)="setActiveClass('tab1')">SIP Calculator</li>
        <li class="tab-slider--trigger col-md-6" rel="tab2" [class.active]="viewMode == 'tab2'"
          (click)="setActiveClass('tab2')">EMI Calculator</li>
      </ul>
      <div [ngSwitch]="viewMode" class="sip-calculator-geojit-calculator">
        <div id="tab1" *ngSwitchCase="'tab1'">
          <table style="width:100%">
            <tr class="table-head header-table-geojit-common">
              <th style="width: 70%;">Items</th>
              <th style="width: 30%;">Amount
              </th>
            </tr>
            <tr>
              <td>Amount required</td>
              <td><input type="email" class="form-control"  placeholder=" " ></td>
            </tr>
            <tr>
              <td>After how many years</td>
              <td><input type="email" class="form-control"  placeholder=" " ></td>
            </tr>
            <tr style="border-bottom:1px solid #EFEFEF;;">
              <td>Rate of return?</td>
              <td><input type="email" class="form-control"  placeholder=" " ></td>
            </tr>
            <tr>
              <td style="color: #555555;">Monthly investment required</td>
              <td>₹20,046</td>
            </tr>
          </table>
        </div>
        <div id="tab2" *ngSwitchCase="'tab2'">
          
          <table style="width:100%">
            <tr class="table-head header-table-geojit-common">
              <th style="width: 70%;">Items</th>
              <th style="width: 30%;">Amount</th>
            </tr>
            <tr>
              <td>Amount required</td>
              <td><input type="email" class="form-control"  placeholder=" " ></td>
            </tr>
            <tr>
              <td>After how many years</td>
              <td><input type="email" class="form-control"  placeholder=" "></td>
            </tr>
            <tr style="border-bottom:1px solid #EFEFEF;;">
              <td>Rate of return?</td>
              <td><input type="email" class="form-control" placeholder=" "></td>
            </tr>
            <tr>
              <td style="color: #555555;">EMI</td>
              <td>₹12,938</td>
            </tr>
            <tr>
              <td style="color: #555555;">Principal</td>
              <td>₹64,691</td>
            </tr>
            <tr>
              <td style="color: #555555;">Principal</td>
              <td>₹50,000</td>
            </tr>
          </table>
       

        </div>
        </div>
      </div>
    

      <div class="row  chart-height-geojit-calculator"  >
        <div class="col-md-6 for-mobile-view-common" [ngClass]="change" >
            <canvas id="myChart" #retirement style="width: 165px;height: 165px;"></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard for-mobile-view-common" [ngClass]="change">
            <ul style="padding-left: 0px;padding-top: 35px;width: 88px;float: left;">
            <li style='background-image: url("/assets/dash/green.svg");'>₹ 12,563<br><span>Principal</span></li>
            <li style='background-image: url("/assets/dash/yellow.svg");'>₹ 11,587<br><span>Interest</span></li>
        </ul>
        </div>
      
    </div>
    </div>
    
  </div>