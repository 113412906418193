


<!-- 
<div id="drag-wrapper">
  <div class="drag-border">
    <div id="drag-container">
      <div class="drag-bar"></div>
      <div class="drag-elem"></div>
    </div>
  </div>
</div> -->


<!-- <input type="range" id="myRange" value="1" max="100">
<div id="rangeValue">1</div> -->


<!-- <p>Audio settings:</p>

<div>
  <input type="range" id="volume" name="volume"
         min="0" max="11">
  <label for="volume">Volume</label>
</div>

<div>
  <input type="range" id="cowbell" name="cowbell" 
         min="0" max="100" value="90" step="10">
  <label for="cowbell">Cowbell</label>
</div> -->


<!-- <div> -->
    <div class="row">
      <div class="tab-slider--nav">
        <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 1px solid #EFEFEF;height: 50px; margin: 0px;">
          <li class="tab-slider--trigger col-md-3 mobile-tabwidthfull-geojit-goals" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode ='tab1'">Goals</li>
          <li class="tab-slider--trigger col-md-3 mobile-tabwidthfull-geojit-goals"  [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode ='tab2'">Retirement Plan </li>
          <li class="tab-slider--trigger col-md-3 mobile-tabwidth-geojit-goals" ></li>
          <li class="tab-slider--trigger col-md-3 mobile-tabwidth-geojit-goals" ></li>
        </ul>
      </div>
    </div>
  <!-- </div> -->

  <div [ngSwitch]="viewMode" class="section-height-geojit-financial" >
    <section id="tab1" *ngSwitchCase="'tab1'" style="padding: 0px;margin: 0px;"  class="section-tab-geojit-goal">
      <div  class="row goal-section-geojit-goal">
        <div class="col-md-12">
    
    <!-- <h6>Assumptions (Expected rates - Pre tax)<img (click)="showModal('investment')" style="padding-left: 6px;" src="assets/about/dependant.svg"></h6> -->
    <h6>Assumptions (Expected rates - Pre tax)<img  style="padding-left: 6px;" src="assets/about/dependant.svg" data-toggle="modal" data-target="#myModal"></h6>

    </div>
    <div class="row"  style="padding-top: 18px;">
      <div class="col-md-3"> <div class="col" style="padding: 0px;"><label>Inflation</label></div>
      <progress class="Inflation-geojit-goal" id="file" value="85" max="100"> 32% </progress>  
          
      85%</div>
      <div class="col-md-3"> <div class="col" style="padding: 0px;"><label>Equity </label></div><progress class="Equity-geojit-goal " id="file" value="45" max="100"> 32% </progress>45%</div>
    <div class="col-md-3" >  <div class="col" style="padding: 0px;"><label>Fixed Income </label></div> <progress class="Fixed-geojit-goal " id="file" value="54" max="100"> 32% </progress>54%</div>
    <div class="col-md-3"><div class="col" style="padding: 0px;"><label>Gold</label></div> <progress class="Gold-geojit-goal" id="file" value="28" max="100"> 32% </progress>28%</div>
    </div>
    <p style="padding-top: 20px;margin-bottom: 0px;">
      We have set a few default rates. If you change those rates, the related amounts also will change. You can set different set of rates for each goal.</p>
    </div>

    </section>
      <h6   id="tab1" *ngSwitchCase="'tab1'" class="define-goal-geojit-goals">Define your goal here</h6>
      <div id="tab1" *ngSwitchCase="'tab1'" style="padding: 0px;margin: 0px;    margin-top: 14px; background: white;height: 517px;" >
      <app-goals></app-goals>
      </div>
    
    <section id="tab2" *ngSwitchCase="'tab2'" style="padding: 0px;margin: 0px;"  class="section-tab-geojit-goal">
      <div  class="row goal-section-geojit-goal">
        <div class="col-md-12">
        <h6>Retirement Plan</h6>
        </div>
    <div class="row"  style="padding-top: 18px;">
   
      <div class="col-md-3"> <div class="col" style="padding: 0px;"><label>Inflation</label></div>
      <progress class="Inflation-geojit-goal" id="file" value="85" max="100"> 32% </progress>
      <!-- <div id="audio-player-container">
        <div class="audio-progress" id="audio-progress">
          <div id="draggable-point" style="left:75%;position:absolute;" class="draggable ui-widget-content">
            <div id="audio-progress-handle"></div>
          </div>
          <div id="audio-progress-bar" class="bar" style="width:75%">
          </div>
        </div>
      </div>
      
      <div id="posX"></div> -->
      85%</div>
      <div class="col-md-3"> <div class="col" style="padding: 0px;"><label>Equity </label></div><progress class="Equity-geojit-goal " id="file" value="45" max="100"> 32% </progress>45%</div>
    <div class="col-md-3" >  <div class="col" style="padding: 0px;"><label>Fixed Income </label></div> <progress class="Fixed-geojit-goal " id="file" value="54" max="100"> 32% </progress>54%</div>
    <div class="col-md-3"><div class="col" style="padding: 0px;"><label>Gold</label></div> <progress class="Gold-geojit-goal" id="file" value="28" max="100"> 32% </progress>28%</div>
    </div>
    <!-- <p style="padding-top: 20px;">We have set a few default rates. If you change those rates, the related amounts also will change. You can set different set of rates for each goal.</p> -->

    </div>
    </section>
    <section id="tab2" *ngSwitchCase="'tab2'" style="padding: 0px;margin: 0px;    margin-top: 14px;">
      <app-retirement></app-retirement>
      </section>
</div>

<div class="container">
  <div class="modal" id="myModal" style="padding-right: 0px;">
    <div class="modal-dialog" style="max-width: 1023px;">
      <div class="modal-content modal-content-geojit-goals" style="max-width: 1023px;">
      
        <!-- Modal Header -->
        <div class="modal-header" style="padding: 0px;border: none;height: 10px;">
          <button type="button" class="close" data-dismiss="modal" style="padding-top: 30px;padding-right: 25px;"> 
            <img src="assets/dash/close.svg"></button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body modal-investment-geojit-about">
          <h6>Select Your Investments For<span style="font-weight: 600;"> Eshana Gibin School/College</span> Admission</h6>
          <div class="row" style="padding-left: 50px;">
            <div class="col-md-4  progress-modal-geojit-goal" >  <div class="col progress-modal-geojit-goal" ><label>Equity </label></div> <progress class="Inflation-geojit-goal" id="file" value="54" max="100"> 32% </progress><span>14.36%</span></div>
            <div class="col-md-4  progress-modal-geojit-goal"> <div class="col progress-modal-geojit-goal " ><label>Debt </label></div><progress class="Equity-geojit-goal" id="file" value="85" max="100"> 32% </progress><span>6.36%</span></div>
            <div class="col-md-4  progress-modal-geojit-goal"><div class="col progress-modal-geojit-goal" ><label>Gold </label></div> <progress class="Fixed-geojit-goal" id="file" value="28" max="100"> 32% </progress><span>21%</span></div>
            </div>
            <table class="table-common-geojit table-checkbox-geojit-goals" style="margin-top: 19px;">
              <tr class="header-table-geojit-common">
                  <th><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></th>
                  <th>Schemes</th>
                  <th>Amount</th>
                  <th>Investment Type</th>
                  <th>Available Quantity</th>
                  <th style="width: 20%;">Allocated Quantity</th>

              </tr>
              <tr class="networth-table-geojit-plan">
                <td><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></td>
                  <td >ACC</td>
                  <td>1,101,52</td>
                  <td>Equity</td>
                  <td>458</td>
                  <td><input type="email" class="form-control input-modal-geojit-goal" id="name" placeholder=" " ></td>
              </tr>
              <tr class="networth-table-geojit-plan">
                <td><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></td>
                <td >Geojit Financial Services Limited</td>
                <td>1,101,52</td>
                <td>Equity</td>
                <td>458</td>
                <td><input type="email" class="form-control input-modal-geojit-goal" id="name" placeholder=" " ></td>
            </tr>
            <tr class="networth-table-geojit-plan">
              <td><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></td>
              <td >Geojit Financial Services Limited</td>
              <td>1,101,52</td>
              <td>Equity</td>
              <td>458</td>
              <td><input type="email" class="form-control input-modal-geojit-goal" id="name" placeholder=" " ></td>
          </tr>
          <tr class="networth-table-geojit-plan">
            <td><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></td>
            <td >Geojit Financial Services Limited</td>
            <td>1,101,52</td>
            <td>Equity</td>
            <td>458</td>
            <td><input type="email" class="form-control input-modal-geojit-goal" id="name" placeholder=" " ></td>
        </tr>
        <tr class="networth-table-geojit-plan">
          <td><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></td>
          <td >Geojit Financial Services Limited</td>
          <td>1,101,52</td>
          <td>Equity</td>
          <td>458</td>
          <td><input type="email" class="form-control input-modal-geojit-goal" id="name" placeholder=" " ></td>
          </tr>
          <tr class="networth-table-geojit-plan">
            <td><label class="container-check"><input type="checkbox"  ><span class="checkmark"></span></label></td>
            <td >Geojit Financial Services Limited</td>
            <td>1,101,52</td>
            <td>Equity</td>
            <td>458</td>
            <td><input type="email" class="form-control input-modal-geojit-goal" id="name" placeholder=" " ></td>
            </tr>
             
          </table>
          <div >
            <div class="row" style="padding-top: 30px;">
                <div class="col-sm-3 amount-text-geojit-goals">Required Amount :<span> ₹ 126,611</span></div>
                <div class="col-sm-5 amount-text-geojit-goals">Allocated Amount :<span> ₹ 0</span></div>
            </div>
            <div class="row" style="padding-top: 30px;">
              <div class="col-sm-3 amount-text-geojit-goals">Balance Amount :<span> ₹ 126,611</span></div>
              <div class="col-sm-5 amount-text-geojit-goals">Goal Date Value of Allocated Amount :<span> ₹ 126,611</span></div>
              <div class="col-sm-4 amount-text-geojit-goals">Goal date :<span> 01/01/2022</span></div>
          </div>
          <div class="row" style="padding-top: 30px;">
            <div class="col-sm-3 amount-text-geojit-goals" style="color: #29B473;">Reference Rates</div>
            <div class="col-sm-9" style="text-align: right;"> <button class="button mr-2"style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Cancel</button>
          <button class="button" type="submit" style="width: 107px;" >Save</button></div>
        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>