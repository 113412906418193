<div   class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 305px;margin-top: 11px;">
    <h6 class="bottom-right " style="align-self: center;padding-left:0px">ACCUMULATED PREMIUM ALLOCATION</h6>
    <div class="card-body kyc-card-geojit-dashboard ">
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas #retirement style="width: 106px;height: 106px;"></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 12,563<br><span>Term</span></li>
            <li style='background-image: url("/assets/dash/blue.svg");height: 58px;'>₹0<br><span>Money Back</span></li>
          </ul>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'>₹ 12,563<br><span>Endowment</span></li>
            <li style='background-image: url("/assets/dash/pink.svg");height: 58px;'>₹0<br><span>Equity Linked</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>