<div class="enter-info-geojit-income">
  <span>Please enter your net income and expense details here.Spouse income could be clubbed</span>
</div>
<div class="tab-slider--nav">
  <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 1px solid #EFEFEF;
    height: 54px; ">
    <li class="tab-slider--trigger col-md-2" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="Tabchange('tab1')">
      Income</li>
    <li class="tab-slider--trigger col-md-2" [class.active]="viewMode == 'tab2'" rel="my-financial"
      (click)="Tabchange('tab2')">Expense</li>
  </ul>
</div>
<div [ngSwitch]="viewMode">

  <!-- section tab 1 -->
  <div id="tab1" *ngSwitchCase="'tab1'">
    <form [formGroup]="profileForm">
      <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars"  placeholder=" "
            style="padding: 9px 8px;    height: 42px;"  >
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Name</label>
        </div>
      
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars" id="investment" placeholder=" "
            style="padding: 9px 8px;    height: 42px;"  (change)="dropdownchange()">
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Income Details</label>
        </div>
        <div *ngIf="strUse=='Other'" class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" "
            formControlName="Monthly"><label>Specify Other</label>
        </div>
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars"  placeholder=" "
            style="padding: 9px 8px;    height: 42px;"  >
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Head Of Income</label>
        </div>
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars"  placeholder=" "
            style="padding: 9px 8px;    height: 42px;"  >
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Currency</label>
        </div>
      
        <div class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" "
            ><label>Expected Growth(%)</label>
        </div>
      </div>
      <div class="form-row form-rowbtn-geojit-measure">
        <div class="form-group col-md-12" style="    display: contents;">
          <button class="button mr-2"
            style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
            id="prev">Cancel</button>
          <button class="button" type="submit" style="width: 107px;background: transparent;color: #29B473;"
            (click)="save()">Save</button>
        </div>
      </div>
      <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>
      <div class="form-row" style="    margin-top: 35px; height: auto;width: 100%;">
        <div class="form-group col-md-12" style="    padding-right: 25px;padding-left: 25px;">
          <table class="table-common-geojit">
            <tr class="header-table-geojit-common">
              <th style="width: 50%;">Items</th>
              <th>Monthly</th>
              <th>Yearly</th>
              <th></th>
            </tr>
            <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
              <td>Profession</td>
              <td>85,000</td>
              <td>660,000</td>
              <td style="text-align: right;">
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/delete.svg" /></button>
              </td>
            </tr>
            <tr style="height: 50px;">
              <td>Salary</td>
              <td>85,000</td>
              <td>660,000</td>
              <td style="text-align: right;">
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/delete.svg" /></button>
              </td>
            </tr>
           
          </table>
        </div>
      </div>
      <div  class="form-row form-rowbtn-geojit-measure">
        <div class="form-group col-md-12" style="    display: contents;">
          <button class="button mr-2"
            style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
            (click)="Tabchange('tab1')">Back</button>
          <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab2')">Next</button>
        </div>
      </div>
    </form>
  </div>
  <div id="tab2" *ngSwitchCase="'tab2'">
    <form [formGroup]="profileForm">
      <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="carsq" id="expense" placeholder=" "
            style="padding: 9px 8px;    height: 42px;" (change)="dropdownchange()">
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Expense Details</label>
        </div>
        <div *ngIf="expense=='Other'" class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" "
           ><label>Specify Other</label>
        </div>
        <div class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" "
            ><label>Monthly Amount</label>
        </div>
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars"  placeholder=" "
            style="padding: 9px 8px;    height: 42px;"  >
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Head Of Income</label>
        </div>
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars"  placeholder=" "
            style="padding: 9px 8px;    height: 42px;"  >
            <option disabled selected>Select</option>
            <option value="Single">Single</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <label>Currency</label>
        </div>
      </div>
      <div class="form-row form-rowbtn-geojit-measure">
        <div class="form-group col-md-12" style="    display: contents;">
          <button class="button mr-2"
            style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
            id="prev">Cancel</button>
          <button class="button" type="submit" style="width: 107px;background: transparent;color: #29B473;"
            (click)="save()">Save</button>
        </div>
      </div>
      <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>
      <div class="form-row" style="    margin-top: 68px; height: auto;">
        <div class="form-group col-md-12" style="    padding-right: 25px; padding-left: 25px;">
          <table class="table-common-geojit">
            
            <tr class="header-table-geojit-common">
              <th style="width: 50%;">Items</th>
              <th>Monthly</th>
              <th>Yearly</th>
              <th></th>
            </tr>
            <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
              <td>Salary</td>
              <td>85,000</td>
              <td>660,000</td>
              <td style="text-align: right;">
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/delete.svg" /></button>
              </td>
            </tr>
            <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
              <td>Profession</td>
              <td>85,000</td>
              <td>660,000</td>
              <td style="text-align: right;">
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/delete.svg" /></button>
              </td>
            </tr>
            <tr style="height: 50px;">
              <td>Profession</td>
              <td>85,000</td>
              <td>660,000</td>
              <td style="text-align: right;">
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img
                    src="assets/about/delete.svg" /></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div  class="form-row form-rowbtn-geojit-measure">
        <div class="form-group col-md-12" style="    display: contents;">
          <button class="button mr-2"
            style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
            (click)="Tabchange('tab1')">Back</button>
          <button class="button" type="submit" style="width: 107px;" >Next</button>
        </div>
      </div>
    </form>
  </div>
</div>