<div class="tab-slider--nav">
    <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 1px solid #EFEFEF;
    height: 54px; ">
        <li class="tab-slider--trigger col-md-2 tab-width-geojit-common" [class.active]="viewMode == 'tab1'" rel="tab1"(click)="Tabchange('tab1')">Endowment</li>
        <li class="tab-slider--trigger col-md-2 tab-width-geojit-common" [class.active]="viewMode == 'tab2'"rel="my-financial" (click)="Tabchange('tab2')">Money Back </li>
        <li class="tab-slider--trigger col-md-2 tab-width-geojit-common" rel="tab3" [class.active]="viewMode == 'tab3'"(click)="viewMode ='tab3'">Term</li>
        <li class="tab-slider--trigger col-md-2 tab-width-geojit-common" rel="tab4" [class.active]="viewMode == 'tab4'"(click)="viewMode ='tab4'">Equity Linked</li>
    </ul>
</div>
<div [ngSwitch]="viewMode">
    <!-- section tab 1 -->
    <div id="tab1" *ngSwitchCase="'tab1'">
        <form [formGroup]="profileForm">
            <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
                <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" " ><label>Name of endowment</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="date" class="form-control "  placeholder=" " >
                    <label>Date of Commencement</label>
                </div>
                <div class="form-group col-md-3">
                    <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                        style="padding: 9px 8px;    height: 42px;" >
                        <option disabled selected>Select</option>
                        <option value="volvo">Single</option>
                        <option value="saab">Female</option>
                        <option value="mercedes">Other</option>
                    </select>
                    <label>Life Insured</label>
                </div>
                <div class="form-group col-md-3">
                    <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                        style="padding: 9px 8px;    height: 42px;" >
                        <option disabled selected>Select</option>
                        <option value="volvo">Single</option>
                        <option value="saab">Female</option>
                        <option value="mercedes">Other</option>
                    </select>
                    <label>Premium Type</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" " ><label>Yearly/Single Premium</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="email" class="form-control " placeholder=" " ><label>Sum Assured</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" " ><label>Premium Payment Frequency</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" " ><label>Premium Payment Period</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="date" class="form-control " placeholder=" " >
                    <label>Maturity Date</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" " ><label>Maturity Amount (Optional)</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" " ><label>Coverage Period</label>
                </div>
                <div class="form-group col-md-12" style="text-align: right;">
                    <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Cancel</button>
                    <button class="button" type="submit" style="width: 107px;color: #29B473;background: transparent;" (click)="save()">Save</button>
                </div>
            </div>
            <div class="form-row  input-box-geojit-common" style="padding: 0px 20px;">
                <div class="form-group col-md-4 card-geojit-insurance">
                    <div class="card-geojit-insurance" style="background-image: url(/assets/money-measures/green.svg);">
                        <h6>₹ 5,000<br></h6><span>Yearly Premium Commitment</span>
                    </div>
                </div>
                <div class="form-group col-md-4 card-geojit-insurance">
                    <div class="card-geojit-insurance"
                        style="background-image: url(/assets/money-measures/yellow.svg);">
                        <h6 style="color: #752C04;">₹ 40,000<br></h6><span style="color: #752C04;">Total Accumulated Premium</span>
                    </div>
                </div>
                <div class="form-group col-md-4 card-geojit-insurance">
                    <div class=" card-geojit-insurance"style="background-image: url(/assets/money-measures/orange.svg);">
                        <h6>₹ 200,000<br></h6><span>Grand Total Sum assured On Self</span>
                    </div>
                </div>
            </div>
            <div class="form-row" style="height: auto;">
                <div class="form-group col-md-12" style="padding-right: 25px;padding-left: 25px;">
                    <table class="table-common-geojit">
                        <tr class="header-table-geojit-common">
                            <th>Name of Policy</th>
                            <th>Start Date</th>
                            <th>Sum Assured</th>
                            <th>Premium Type</th>
                            <th>Premium Amount</th>
                            <th>Maturity Date</th>
                            <th>Maturity Amount</th>
                            <th></th>
                        </tr>
                        <tr  style="height: 50px;">
                            <td>LIC</td>
                            <td>01/04/2013</td>
                            <td>200,000</td>
                            <td>Regular</td>
                            <td>5,000</td>
                            <td>01/04/2033</td>
                            <td>660,000</td>
                            <td>
                                <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                                <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                            </td>
                        </tr>
                       
                    </table>
                </div>
            </div>
            <div class="form-row form-rowbtn-geojit-measure">
                <div class="form-group col-md-12" style="    display: contents;">
                    <button class="button mr-2"
                        style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
                        (click)="Tabchange('tab2')">Back</button>
                    <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab4')">Next</button>
                </div>
            </div>
        </form>
        </div>

<div id="tab2" *ngSwitchCase="'tab2'">
    <form [formGroup]="profileForm">
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Name of money back</label>
            </div>
            <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " >
                <label>Date of Commencement</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Life Insured</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Premium Type</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" "><label>Sum Assured</label>
            </div>
            <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " >
                <label>Maturity Date</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Maturity Amount (Optional)</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Money Back Frequency (Years)</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Amount of Money Back</label>
            </div>
            <div class="form-group col-md-12" style="text-align: right;">
                <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Cancel</button>
                <button class="button" type="submit" style="width: 107px;color: #29B473;background: transparent;" (click)="save()">Save</button>
            </div>
        </div>
        <div class="form-row  input-box-geojit-common" style="padding: 0px 20px;">
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class="card-geojit-insurance" style="background-image: url(/assets/money-measures/green.svg);">
                    <h6>₹ 5,000<br></h6><span>Yearly Premium Commitment</span>
                </div>
            </div>
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class="card-geojit-insurance"
                    style="background-image: url(/assets/money-measures/yellow.svg);">
                    <h6 style="color: #752C04;">₹ 40,000<br></h6><span style="color: #752C04;">Total Accumulated Premium</span>
                </div>
            </div>
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class=" card-geojit-insurance"style="background-image: url(/assets/money-measures/orange.svg);">
                    <h6>₹ 200,000<br></h6><span>Grand Total Sum assured On Self</span>
                </div>
            </div>
        </div>
        <div class="form-row" style="height: auto;">
            <div class="form-group col-md-12" style="padding-right: 25px;padding-left: 25px;">
                <table class="table-common-geojit">
                    <tr class="header-table-geojit-common">
                        <th>Name of Policy</th>
                        <th>Start Date</th>
                        <th>Sum Assured</th>
                        <th>Premium Type</th>
                        <th>Premium Amount</th>
                        <th>Maturity Date</th>
                        <th>Maturity Amount</th>
                        <th></th>
                    </tr>
                     <tr  style="height: 50px;">
                        <td>LIC</td>
                        <td>01/04/2013</td>
                        <td>200,000</td>
                        <td>Regular</td>
                        <td>5,000</td>
                        <td>01/04/2033</td>
                        <td>660,000</td>
                        <td>
                            <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                            <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                        </td>
                    </tr>
                   
                </table>
            </div>
        </div>
        <div  class="form-row form-rowbtn-geojit-measure">
            <div class="form-group col-md-12" style="    display: contents;">
                <button class="button mr-2"
                    style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
                    (click)="Tabchange('tab2')">Back</button>
                <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab4')">Next</button>
            </div>
        </div>
    </form>
</div>
<div id="tab3" *ngSwitchCase="'tab3'">
    <form [formGroup]="profileForm">
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Name of term</label>
            </div>
            <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " >
                <label>Date of Commencement</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Life Insured</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Premium Type</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Sum Assured</label>
            </div>
            <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " >
                <label>Maturity Date</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Maturity Amount (Optional)</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>With Return of Premium?</label>
            </div>
           
            <div class="form-group col-md-12" style="text-align: right;">
                <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Cancel</button>
                <button class="button" type="submit" style="width: 107px;color: #29B473;background: transparent;" (click)="save()">Save</button>
            </div>
        </div>
        <div class="form-row  input-box-geojit-common" style="padding: 0px 20px;">
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class="card-geojit-insurance" style="background-image: url(/assets/money-measures/green.svg);">
                    <h6>₹ 5,000<br></h6><span>Yearly Premium Commitment</span>
                </div>
            </div>
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class="card-geojit-insurance"
                    style="background-image: url(/assets/money-measures/yellow.svg);">
                    <h6 style="color: #752C04;">₹ 40,000<br></h6><span style="color: #752C04;">Total Accumulated Premium</span>
                </div>
            </div>
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class=" card-geojit-insurance"style="background-image: url(/assets/money-measures/orange.svg);">
                    <h6>₹ 200,000<br></h6><span>Grand Total Sum assured On Self</span>
                </div>
            </div>
        </div>
        <div class="form-row" style="height: auto;">
            <div class="form-group col-md-12" style="padding-right: 25px;padding-left: 25px;">
                <table class="table-common-geojit">
                    <tr class="header-table-geojit-common">
                        <th>Name of Policy</th>
                        <th>Start Date</th>
                        <th>Sum Assured</th>
                        <th>Premium Type</th>
                        <th>Premium Amount</th>
                        <th>Maturity Date</th>
                        <th></th>
                    </tr>
                    <tr  style="height: 50px;">
                        <td>TATA</td>
                        <td>01/04/2013</td>
                        <td>200,000</td>
                        <td>Regular</td>
                        <td>5,000</td>
                        <td>01/04/2033</td>
                        <td>
                            <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                            <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                        </td>
                    </tr>
                   
                </table>
            </div>
        </div>
        <div  class="form-row form-rowbtn-geojit-measure">
            <div class="form-group col-md-12" style="    display: contents;">
                <button class="button mr-2"
                    style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
                    (click)="Tabchange('tab2')">Back</button>
                <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab4')">Next</button>
            </div>
        </div>
    </form>
</div>
<div id="tab4" *ngSwitchCase="'tab4'">
    <form [formGroup]="profileForm">
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Name of equity linked</label>
            </div>
            <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " >
                <label>Date of Commencement</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Life Insured</label>
            </div>
            <div class="form-group col-md-4">
                <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                    style="padding: 9px 8px;    height: 42px;" >
                    <option disabled selected>Select</option>
                    <option value="volvo">Single</option>
                    <option value="saab">Female</option>
                    <option value="mercedes">Other</option>
                </select>
                <label>Premium Type</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Sum Assured</label>
            </div>
            <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " >
                <label>Maturity Date</label>
            </div>
            <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " ><label>Current Value</label>
            </div>
            <div class="form-group col-md-8" style="text-align: right;">
                <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Cancel</button>
                <button class="button" type="submit" style="width: 107px;color: #29B473;background: transparent;" (click)="save()">Save</button>
            </div>
        </div>
        <div class="form-row  input-box-geojit-common" style="padding: 0px 20px;">
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class="card-geojit-insurance" style="background-image: url(/assets/money-measures/green.svg);">
                    <h6>₹ 5,000<br></h6><span>Yearly Premium Commitment</span>
                </div>
            </div>
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class="card-geojit-insurance"
                    style="background-image: url(/assets/money-measures/yellow.svg);">
                    <h6 style="color: #752C04;">₹ 40,000<br></h6><span style="color: #752C04;">Total Accumulated Premium</span>
                </div>
            </div>
            <div class="form-group col-md-4 card-geojit-insurance">
                <div class=" card-geojit-insurance"style="background-image: url(/assets/money-measures/orange.svg);">
                    <h6>₹ 200,000<br></h6><span>Grand Total Sum assured On Self</span>
                </div>
            </div>
        </div>
        <div class="form-row" style="height: auto;">
            <div class="form-group col-md-12" style="padding-right: 25px;padding-left: 25px;">
                <table class="table-common-geojit">
                    <tr class="header-table-geojit-common">
                        <th>Name of Policy</th>
                        <th>Start Date</th>
                        <th>Sum Assured</th>
                        <th>Premium Type</th>
                        <th>Premium Amount</th>
                        <th>Maturity Date</th>
                        <th>Current Value</th>
                        <th></th>
                    </tr>
                    <tr  style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                        <td>TATA</td>
                        <td>01/04/2013</td>
                        <td>200,000</td>
                        <td>Regular</td>
                        <td>5,000</td>
                        <td>01/04/2033</td>
                        <td>660,000</td>
                        <td>
                            <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                            <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                        </td>
                    </tr>
                    <tr  style="height: 50px;">
                        <td>TATA</td>
                        <td>01/04/2013</td>
                        <td>200,000</td>
                        <td>Regular</td>
                        <td>5,000</td>
                        <td>01/04/2033</td>
                        <td>660,000</td>

                        <td>
                            <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                            <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div  class="form-row form-rowbtn-geojit-measure">
            <div class="form-group col-md-12" style="    display: contents;">
                <button class="button mr-2"
                    style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
                    (click)="Tabchange('tab2')">Back</button>
                <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab4')">Next</button>
            </div>
        </div>
    </form>
    </div>
    </div>