<div id="svg_wrap" style="fill: rgb(14 230 4)important;    margin: 23px 48px 0px 228px;width: 390px;display: none;">
</div>

<div>
  <div class="row">

    <div class="tab-slider--nav">
      <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 2px solid #EFEFEF;
      height: 50px; " >

        <li class="tab-slider--trigger col-md-3"  [class.active]="viewMode == 'Personal Profile'" rel="Personal"(click)="tabChange('Personal Profile')" >Personal<br> Profile</li>
            <li class="tab-slider--trigger col-md-3"   [class.active]="viewMode == 'Contact Details'" rel="Contact"(click)="tabChange('Contact Details')">Contact<br> Details </li>
             <li class="tab-slider--trigger col-md-3"  rel="Family" [class.active]="viewMode == 'Family Details'" (click)="tabChange('Family Details')">Family<br> Details</li>
              <li class="tab-slider--trigger col-md-3"  rel="Risk" [class.active]="viewMode == 'Risk Profiler'" (click)="tabChange('Risk Profiler')">Risk<br> Profiler</li>
      </ul>
    </div>

</div>
</div>

<div [ngSwitch]="viewMode">
<section id="Personal Profile" *ngSwitchCase="'Personal Profile'">
  <form action="/action_page.php"  autocomplete="off">
    <h4>Personal Details <span>(Step 1 - 2)</span></h4>
    <div class="form-row " style="    margin-top: 22px;">
      <div class="form-group col-md-4 ">
        <input type="text" class="form-control" placeholder=" " autocomplete="off" ><label>First Name</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" "><label>Last Name</label>
      </div>
      <div class="form-group col-md-4">
        <input type="date" class="form-control"  placeholder=" ">
        <label>Date of birth</label>
      </div>
    </div>
    <div class="form-row ">
      <div class="form-group col-md-4">
        <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
          style="padding: 9px 8px;    height: 42px;">
          <option >Select</option>
          <option value="volvo">Male</option>
          <option value="saab">Female</option>
          <option value="mercedes">Other</option>
        </select>
        <label>Gender</label>
      </div>
      <div class="form-group col-md-4">
        <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
          style="padding: 9px 8px;    height: 42px;">
          <option >Select</option>
          <option value="volvo">Single</option>
          <option value="saab">Married</option>
          <option value="mercedes">Other</option>
        </select>
        <label>Marital Status</label>
      </div>
      <div class="form-group col-md-4">
        <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
          style="padding: 9px 8px;    height: 42px;">
          <option >Select</option>
          <option value="volvo">Post Graduate</option>
          <option value="saab">Female</option>
          <option value="mercedes">Other</option>
        </select>
        <label>Educational Qualification</label>
      </div>
    </div>
    <div class="form-row ">
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Residential Status</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>PAN</label>
      </div>
      <div class="form-group col-md-4">
        <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
          style="padding: 9px 8px;    height: 42px;">
          <option >Select</option>
          <option value="volvo">Private Sector Service</option>
          <option value="saab">Female</option>
          <option value="mercedes">Other</option>
        </select>
        <label>Occupation</label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Nationality</label>
      </div>
    </div>
    <div class="form-row" style="  align-items: center;
    float: right;padding-top: 50px;padding-top: 5px;">
      <div class="form-group col-md-4">
        <button class="button"  (click)="viewMode ='Contact Details'">Save & Continue </button>
      </div>
    </div>
  </form>
  <!-- <app-personal></app-personal> -->
</section>
<!-- section 2 -->

  <section id="Contact Details" *ngSwitchCase="'Contact Details'" style="
  height: 605px;">
  <form autocomplete="off">
    <h4>Communication Address</h4>
    <div class="form-row  mobile-responsive-common" style="    margin-top: 22px;">
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>House Number / Name</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Street Name / Number</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control" placeholder=" ">
        <label>Location</label>
      </div>
    </div>
    <div class="form-row  mobile-responsive-common">
      <div class="form-group col-md-4">
        <input type="email" class="form-control" placeholder=" ">
        <label>City</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>State</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control" placeholder=" ">
        <label>Pin Code</label>
      </div>
    </div>
    <h4>Permanent Address</h4>

    <div class="form-row  mobile-responsive-common" style="    margin-top: 22px;">
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>House Number / Name</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Street Name / Number</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Location</label>
      </div>
    </div>
    <div class="form-row  mobile-responsive-common">
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>City</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>State</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Pin Code</label>
      </div>
    </div>
    <h4>Communication Details</h4>

    <div class="form-row  mobile-responsive-common" style="    margin-top: 22px;">
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" ">
        <label>Email ID</label>
      </div>
      <div class="form-group col-md-4">
        <input type="number" class="form-control"  placeholder=" ">
        <label>Mobile Number</label>
      </div>
      <div class="form-group col-md-4">
        <input type="number" class="form-control" placeholder=" ">
        <label>Alternate Mobile</label>
      </div>
    </div>

    <div class="form-row  mobile-responsive-common" style="  align-items: center;
    float: right;padding-top: 50px;padding-top: 5px;">
      <div class="form-group col-md-4 form-group-geojit-about" style="    display: contents;">
        <button class="button mr-2 back-btn-geojit-common" id="prev" (click)="viewMode ='Personal Profile'">Back</button>

        <button class="button" id="next" (click)="viewMode ='Family Details'">Save & Continue </button>
      </div>
    </div>
  </form>
</section>
<!-- section 3 -->
<section style="height: 624px;" id="Family Details" *ngSwitchCase="'Family Details'">
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <h4>Family Details</h4>
    <div class="form-row mobile-responsive-common form-rowfamily-geojit-about" style="    margin-top: 22px;">
      <div class="form-group col-md-4" style="    margin-top: 6px;">
          <div  style=" border: 1px solid #EFEFEF;height: 108px;">
            <!-- Upload image -->
            <div class="avatar-upload    ">
              <div class="avatar-edit">
                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="uploadFile($event)"
                  style="border: none;"  formControlName="url"/>
                <button class="button-upload-geojit-about" (click)="fileInput.click()">Add Photo<br><span>(Max.size 30kb)</span></button>
              </div>
              <div class="avatar-preview">
                <div id="imagePreview"  [style.backgroundImage]="'url('+ imageUrl +')'">
                  <!-- <img src="imageUrl"> -->
                </div>
              </div>
            </div>
          </div>
         

       
      </div>
      <div class="form-group col-md-8" style="padding-left: 0px;">
        <div class="form-row mobile-responsive-common" style="height: 65px;">
          <div class="form-group col-md-6">
            <input type="email" class="form-control"  placeholder=" "  formControlName="Member_Name">
            <label>Name</label>
          </div>
          
          <div class="form-group col-md-6">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Relationship</label>
          </div>
        </div>
        <div class="form-row mobile-responsive-common">
            <div class="form-group col-md-6">
              <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "style="padding: 9px 8px;    height: 42px;">
                <option disabled selected>Select</option>
                <option value="volvo">Single</option>
                <option value="saab">Female</option>
                <option value="mercedes">Other</option>
              </select>
              <label>Occupation</label>
            </div>
            <div class="form-group col-md-3">
              <input type="email" class="form-control" placeholder=" " formControlName="PAN_Number">
              <label>PAN</label>
              
            </div>
            <div class="form-group col-md-3">
              <label style="position: initial;">
                <input type="radio"  >
                <span class="checkmark">PAN is not available</span>
              </label>
             
            </div>
  
        </div>
      </div>
    </div>
 

    <div class="form-row mobile-responsive-common display-mobile-geojit-about" style="margin-top: 54px;">
      <div class="form-group col-md-4">
        <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "style="padding: 9px 8px;    height: 42px;">
          <option disabled selected>Select</option>
          <option value="volvo">Single</option>
          <option value="saab">Female</option>
          <option value="mercedes">Other</option>
        </select>
        <label>Occupation</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" "  formControlName="Member_Name">
        <label>Occupation Details</label>
      </div>
      <div class="form-group col-md-4">
        <input type="email" class="form-control"  placeholder=" "  formControlName="Member_Name">
        <label>Age</label>
      </div>
    
      <div class="form-group col-md-12" style="text-align: center;">


        
        <div  class="dependant-member-geojit-about">
          <span class="dependant-radio-geojit-about" >Dependant Member<img style="padding-left: 6px;" src="assets/about/dependant.svg"></span>
          <label style="position: initial;">
            <input type="radio"  name="radio" data-toggle="modal" data-target="#family">
            <span class="checkmark" >Yes</span>
          </label>
          <label style="position: initial;">
            <input type="radio" name="radio">
            <span class="checkmark" checked="checked">No</span>
          </label>
        </div>
      </div>
      
    </div>

    <div class="form-row" style="height: auto;  align-items: center;float: right;padding-top: 50px;padding-top: 5px;">
      <div class="form-group col-md-12" style="    display: contents;">
        <button class="button mr-2 back-btn-geojit-common" id="prev">Cancel</button>
        <button class="button"  type="submit" (click)="save()" style="width: 110px;">Save</button>
      </div>
    </div>

    <div class="form-row" style="    margin-top: 68px; height: auto;">
      <div class="form-group col-md-12">
        <table>
          <tr class="familymem-table-geojit-about">
            <th *ngFor = "let column of headers" >
              {{column}}
            </th>
          </tr>
          <tbody>
            <tr  style="border-bottom: 2px solid #f6f8fa;">
              <td style="width: 20%;" >
                <img src="assets/about/profile1.png" class="profile-img-geojit-about" >
              </td>
              <td style="width: 20%">
                Gibin
              </td>
              <td style="width: 20%">
                Self
              </td>
              <td style="width: 20%">
                23/07/1984
              </td>
              <td >
                KL67288AS
              </td>
              <td >
                <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
              </td>
            </tr>
            <tr  style="border-bottom: 2px solid #f6f8fa;">
              <td style="width: 20%;" >
                <img src="assets/about/profile2.png" class="profile-img-geojit-about">
              </td>
              <td style="width: 20%">
                Reshmi Gibin
              </td>
              <td style="width: 20%">
                Spouse
              </td>
              <td style="width: 20%">
                23/07/1984
              </td>
              <td >
                KL67288AS
              </td>
              <td >
                <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
              </td>
            </tr>
            <tr  >
              <td style="width: 20%;" >
                <img src="assets/about/profile3.png" class="profile-img-geojit-about" >
              </td>
              <td style="width: 20%">
                Reshmi Gibin
              </td>
              <td style="width: 20%">
                Spouse
              </td>
              <td style="width: 20%">
                23/07/1984
              </td>
              <td >
                KL67288AS
              </td>
              <td >
                <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                <!-- <button (click)="delete(row)"></button> -->
                <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
              </td>
            </tr>
         </tbody>
         
        </table>
        
      </div>
      
    </div>
    <div class="form-row" style="  align-items: center;
    float: right;padding-top: 50px;padding-top: 5px;">
      <div class="form-group col-md-4 form-group-geojit-about" style="    display: contents;">
        <button class="button mr-2 back-btn-geojit-common" id="prev" (click)="viewMode ='Contact Details'">Back</button>

        <button class="button" id="next" (click)="viewMode ='Risk Profiler'" data-toggle="modal" data-target="#myModal">Save & Continue </button>
      </div>
    </div>

  </form>
</section>
<section id="Risk Profiler" *ngSwitchCase="'Risk Profiler'" class="section-question-geojit-about" >



<app-question-answer></app-question-answer>

</section>

</div>


  

<div class="container">
  <div class="modal" id="myModal">
    <div class="modal-dialog modal-dialog-geojit-about">
      <div class="modal-content" style="height:426px;max-width: 385px;">
        <!-- Modal Header -->
        <div class="modal-header" style="padding: 0px;border: none;height: 26px;">
          <button type="button" class="close" data-dismiss="modal" style="padding-top: 25px;padding-right: 23px;"> 
            <img src="assets/dash/close.svg"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body modal-manual-geojit-about">
          <img src="assets/about/modal-img.svg">
          <h6>User Manual</h6>
          <p>A detailed User Manual is available at<br> right side of the menu bar.</p>
          <button class="save-btn-geojit-common" style="margin-left: 15px;width: 178px;"><img src="assets/about/download.svg"> Download PDF</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container">
  <div class="modal" id="family" >
    <div class="modal-dialog " style="max-width: 872px;">
      <div class="modal-content modal-content-geojit-goals" style="max-width: 1023px;height: auto;">
      
        <!-- Modal Header -->
        <div class="modal-header" style="padding: 0px;border: none; height: 66px;">
          <button type="button" class="close" data-dismiss="modal" style="padding-top: 25px;padding-right: 17px;"> 
            <img src="assets/dash/close.svg"></button>
        </div>
        
        <!-- Modal body -->
        <div class="modal-body modal-family-geojit-about">
          <h5>Annual Declaration to Geojit Financial Services Limited (IA Division):</h5>
          <h6>I <span style="font-weight: 600;">Gibin</span>, having PAN <span style="font-weight: 600;">GFDT87766</span>, hereby Declare that the following are the details of my Dependent family members, which may be reckoned as ‘Family Account’ and with respect to the assets for which Investment Advise is sought from/provided by Geojit:</h6>
        
            <table class="table-common-geojit table-checkbox-geojit-goals" style="margin-top: 24px;">
              <tr class="header-table-geojit-common">
                  <th>SL. No.</th>
                  <th>Name</th>
                  <th>Nature of Relationship (dependent spouse, dependent children or dependent parents)</th>
                  <th>PAN No.</th>
                  <th>Aadhar Number ( if PAN not available)</th>
                  

              </tr>
              <tr class="networth-table-geojit-plan" style="border-bottom: 2px solid #f6f8fa;height: 70px;">
                <td>1</td>
                  <td >Gibin</td>
                  <td>Self</td>
                  <td>GFDT87766</td>
                  <td>4564 4455 3432 2345</td>
              </tr>
              <tr class="networth-table-geojit-plan" style="border-bottom: 2px solid #f6f8fa;height: 70px;">
                <td>2</td>
                  <td >Reshmi Gibin</td>
                  <td>Spouse</td>
                  <td>KL67288AS</td>
                  <td>4564 4455 3432 2345</td>
              </tr>
              <tr class="networth-table-geojit-plan" style="border-bottom: 2px solid #f6f8fa;height: 70px;">
                <td>3</td>
                  <td >Reshmi Gibin</td>
                  <td>Self</td>
                  <td>KL67288AS</td>
                  <td>4564 4455 3432 2345</td>
              </tr>
              <tr class="networth-table-geojit-plan" style="height: 70px;">
                <td>1</td>
                  <td >Gibin</td>
                  <td>Self</td>
                  <td>GFDT87766</td>
                  <td>4564 4455 3432 2345</td>
              </tr>
          
             
          </table>
          <div >
          <div class="row" style="padding-top: 30px;">
            <div class="col-sm-12" style="text-align: right;"> <button  data-dismiss="modal" class="button mr-2"style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Cancel</button>
          <button class="button" type="submit" style="width: 107px;"  data-dismiss="modal">Save</button></div>
        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>