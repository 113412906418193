import { Component, ViewChild, Input,OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-insurance-chart',
  templateUrl: './insurance-chart.component.html',
  styleUrls: ['./insurance-chart.component.scss']
})
export class InsuranceChartComponent implements OnInit,AfterViewInit {
  @ViewChild('retirement') retirement:any;

  constructor() { }

  ngOnInit(): void {
  }
  @Input() colors = ['#29B473', '#FFDD00'];

  @Input() data=  [ 12563,  5833];
  captcha:boolean=false;
  canvas: any;
  ctx2: any;
  doughnutChartRef:any;
 ngAfterViewInit() {

  this.canvas=this.retirement.nativeElement;
  
      this.ctx2 = this.canvas.getContext('2d');
  
  
    
  
     
      this.doughnutChartRef = new Chart(this.ctx2, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data,
            backgroundColor: this.colors
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
      }
        }
    });
  }

}
