
<header class="header">
    <nav class="navbar navbar-expand-sm fixed-top py-3 header-geojit-home">
        <div class="container">
            <img class="logo" [routerLink]="['dashboard']" src="assets/header/logo.svg">           
            <div id="navbarSupportedContent" class="collapse navbar-collapse header-right-geojit-landing ">  
            </div>
            <button class="border-0 " style="background: transparent;" (click)="showModal('content')"><img  style="width: 36px;height: 18px;" src="assets/header/graph.svg"></button>
            <a  class="notification">
                <img  src="assets/header/notification.svg" >           
                <span class="badge">3</span>
              </a>

                <img class="photourl-img-geojit-header"  src="assets/header/photoUrl.png"/>
             

               
                  <div  >
                    <div id="content" class="display-sidebar-geojit-sidebar">
                        <nav class=" navbar-expand-lg navbar-light">
                            <div class="container-fluid">
                                <button  type="button" id="sidebarCollapse" style="color: #7e7979!important;outline: none;
                                background-color: transparent;    border: none;" class="w3-button  w3-xlarge w3-right menu-btn-geojit-header" ><img src="assets/header/menu.svg" style="
                                width: 22px;height: 16px;"></button>
                            </div>
                        </nav>
                    </div>
                    

                </div>
        </div>
    </nav>
</header>

