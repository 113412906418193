import { Component, OnInit } from '@angular/core';
import { ModelService } from '../../services/model.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
doc:any;
  constructor(public ModelService:ModelService) { 
    
  }

  ngOnInit(): void {
    var element = document.getElementById("rightMenu");
    element!.style.display  = "none";
  }

  
  showModal(value:any)
  {
    this.ModelService.callComponentMethod(value);
  }
}
