import { Component, ViewChild, Input,OnInit, OnDestroy, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { ModelService } from '../../services/model.service';

@Component({

  selector: 'app-my-financial',
  templateUrl: './my-financial.component.html',
  styleUrls: ['./my-financial.component.scss']
})
export class MyFinancialComponent implements AfterViewInit {
  showInvestment = 'hide';
  showInsurance = 'hide';
url:any;
  @ViewChild('netWorth') netWorth:any;
  @ViewChild('portfolio') portfolio:any;
  constructor(public Router:Router,private modalService: NgbModal,public ModelService:ModelService,private cdRef:ChangeDetectorRef) {
    this.url = this.Router.url;
        this.tabChange(this.url);
   }

  // ngOnInit(): void {
  // }
  name = '!!!';
  viewMode = 'main/my-financial/about';
  
 tabChange(route:any)
 {
 

    this.viewMode=route;
    this.Router.navigate([route]);
    if(route=='/my-financial/financial-goals'){   this.ModelService.showCards('Goals');}
    if(route=='/my-financial/my-plan'){this.ModelService.showCards('Plan');}
 }

 @Input() colors = ['#29B473', '#EF8C57', '#69DBDE','#0E7150','#FFDD00','#CAD3E6'];
  @Input() colors2 = ['#29B473', '#FFDD00', '#ED809B'];

  @Input() data =  [ 4977,1863,3533,1423,1283,0];
  @Input() data2 =  [ 12563,  5833,3533];
  @Input() data3 =  [ 2586,853,2146,2236,232,0];
  captcha:boolean=false;
  canvas2: any;
  canvas: any;
  canvas3: any;

  ctx: any;
  ctx2: any;
  ctx3: any;

  doughnutChartRef:any;


 ngAfterViewInit() {

  this.ModelService.showcards.subscribe((res:any) => {
    if(res=='Contact Details' || res=='Family Details'){this.showInvestment='Personal Profile';  this.cdRef.detectChanges();}
    else{   
      this.showInvestment=res; this.cdRef.detectChanges();  }
    });
  this.canvas2=this.netWorth.nativeElement;
  this.canvas3=this.portfolio.nativeElement;
      this.ctx3 = this.canvas3.getContext('2d');
      this.ctx = this.canvas.getContext('2d');
      this.ctx2 = this.canvas2.getContext('2d');
  
  
      this.doughnutChartRef = new Chart(this.ctx, {
          type: 'doughnut',
          data: {
          datasets: [{
              borderWidth: 3,
              weight: 20,
              hoverBorderWidth: 1,
              data: this.data,
              backgroundColor: this.colors
          }],
        },
          options: {
            legend: {
              display: true,
            },
            cutoutPercentage: 75,
         elements: {
        }
          }
      });
  
  
      this.doughnutChartRef = new Chart(this.ctx3, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data3,
            backgroundColor: this.colors2
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
      }
        }
    });
      this.doughnutChartRef = new Chart(this.ctx2, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data2,
            backgroundColor: this.colors2
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
      }
        }
    });

}
}
