import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormGroup,FormControl } from "@angular/forms";
import { Chart } from 'chart.js';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-my-investments',
  templateUrl: './my-investments.component.html',
  styleUrls: ['./my-investments.component.scss']
})
export class MyInvestmentsComponent implements OnInit {
  viewMode="tab1";
  profileForm = new FormGroup({
    company: new FormControl(''),
    shares: new FormControl(''),
    price: new FormControl(''),
    date: new FormControl('')
  });
  items:any=[];
  imageUrl:any;
  rows:any = [];
    constructor(private builder: FormBuilder, private _sanitizer: DomSanitizer) { }
    headers = ["Photo", "Member Name", "Relationship", "Date of Birth", "PAN Number",""];

  ngOnInit(): void {
    
  }
  Tabchange(route:any)
  {
    this.viewMode=route;

  }
  save()
  {
    this.items=this.profileForm.value;
    this.rows.push(this.items)
  }
  delete(row:any)
  {
    let i=this.rows.indexOf(row)
    this.rows.splice(i,1);
  }
  edit(name:any)
  {
    let i=this.rows.indexOf(name)
    this.rows.splice(i);
  }

  uploadFile(event:any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = file.name;
        // this.registrationForm.patchValue({
        //   file: reader.result
        // });
        // this.editFile = false;
        // this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      // this.cd.markForCheck();        
    }
  }
}
