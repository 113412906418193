<section class="questions-section-geojit-retirement">
    <table style="width:100%">
        <tr>
          <th style="width: 70%;">3 Simple Questions</th>
          <th style="width: 30%;">Particulars
            <button class="button-edit-geojit-about padding-edit-geojit-about" style="padding-left: 6px;" ><img src="assets/about/share.svg" /></button>
            <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
            <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button></th>

        </tr>
        <tr>
          <td>What would be your monthly living expenses on retirements ? (Today's Value)</td>
          <td><input type="email" class="form-control" id="name" placeholder=" "  ></td>
        </tr>
        <tr>
            <td>At what age you plan to retire ?</td>
            <td><input type="email" class="form-control" id="name" placeholder=" " ></td>
          </tr>
          <tr>
            <td>After retirement, till what age you like to receive monthly income ?</td>
            <td><input type="email" class="form-control" id="name" placeholder=" "  ></td>
          </tr>
      </table>
</section>
<section class="monthly-section-geojit-retirement">
    <div style="padding: 0px 27px;">
    <table style="width:100%">
        <tr>
          <th class="border-dotted-geojit-retirement" style="font-weight: 400;padding: 0px;">Monthly Expense on Retirements</th>
          <td class="border-dotted-geojit-retirement" style="font-weight: 500;text-align: right;">₹45036</td>
        </tr>
        <tr>
            <th style="font-weight: 400;padding: 0px;">Total corpus that need to be accumulated (After allocations if any) To meet retirements Expenses</th>
            <td style="font-weight: 500;text-align: right;">₹11,258,156</td>
          </tr>
      </table>
    </div>
</section>
<section class="generatePin-section-geojit-retirement">
    
   <div class="col result-text-geojit-retirement" >
     
  <h6>Your Results</h6>
           <ul style="padding-left: 0px;">
            <li>On the day you retire you will require <span>₹11,287,165</span> to sustain your life style and meet expenses during your retirement life.</li>
            <li>Considering the monthly expense of 
              <span> ₹15000</span> which will become <span>₹63,526 </span>at a rate of inflation of <span>6%</span> after <span>25</span> years</li>
      <li>The monthly investment required to create the corpus of<span> ₹11,287,165</span> is <span>₹8,163</span> or the
        lump sum investment to be made today to achieve this corpus is <span>₹870,101</span></li>
          </ul>
           
        </div>
        <div class="col" style="text-align: right;">
           <button class="button btn-back-geojit-common mr-2"  id="prev" >Back</button>
                    <button class="button" type="submit" >Generate Plan</button>
                    </div>
</section>

