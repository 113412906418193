import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormControl } from "@angular/forms";

@Component({
  selector: 'app-liabilities',
  templateUrl: './liabilities.component.html',
  styleUrls: ['./liabilities.component.scss']
})
export class LiabilitiesComponent implements OnInit {

  viewMode="tab1";

  constructor() { }

  ngOnInit(): void {
  }
  Tabchange(route:any)
  {
    this.viewMode=route;

  }
  profileForm = new FormGroup({
    Monthly: new FormControl(''),
    Items: new FormControl('')
  });
  items:any=[];
  rows:any = [];
  save()
  {
    this.items=this.profileForm.value;
    this.rows.push(this.items)
  }
  delete(row:any)
  {
    let i=this.rows.indexOf(row)
    this.rows.splice(i,1);
  }
  edit(name:any)
  {
    let i=this.rows.indexOf(name)
    this.rows.splice(i);
  }



}
