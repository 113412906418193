import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  name = 'Angular 6';
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  tab4 : any
  tab5 : any
  tab6 : any
hide:boolean=false;
  public href: string = "";
  Clicked : boolean=false;
  constructor(private router: Router) {
    this.href = this.router.url;
        this.onClick(this.router.url);


   }
  ngOnInit(): void {
    console.log(window.location.pathname)
    this.tab=window.location.pathname.substr(1);

    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
          $('#sidebar').toggleClass('active');
      });
  });
  }
  onClick(route:any){
          this.tab = route;
          if(this.tab!=='my-financial')
          {
            $('#sidebar').toggleClass('active');
            this.router.navigate([route]);
          }
          
        }
    onActivate(event:any) {
      let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
  }
  hideSidebar()
  {
    this.hide=true;
  }

}
