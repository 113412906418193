import { Component, OnInit, ElementRef, ViewChild,ChangeDetectorRef,ViewEncapsulation } from '@angular/core';
import { FormBuilder,FormGroup,FormControl } from "@angular/forms";
import * as d3 from "d3";
// declare var d3:any;
import { ModelService } from '../../../services/model.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})

export class AboutComponent implements OnInit {
  captcha:boolean=false;
  gaugemap:any = {};
  name = '!!!';
    viewMode = 'Personal Profile';
    items:any=[];
    profileForm = new FormGroup({
    Member_Name: new FormControl(''),
    url: new FormControl(''),
    Relationship: new FormControl(''),
    Date_of_Birth: new FormControl(''),
    PAN_Number: new FormControl(''),
  });
  url1:any = '';
  @ViewChild('iAmNotReal') childComp:any;
  @ViewChild('fileInput') el!: ElementRef;
  imageUrl: any = 'assets/about/add_photo.svg';
  editFile: boolean = true;
  removeUpload: boolean = false;
  registrationForm = this.fb.group({
    file: [null]
  })  
  constructor( public fb: FormBuilder,private cd: ChangeDetectorRef,public ModelService:ModelService)  { 
    this.tabChange('Personal Profile');
     }

  
  rows:any = [];
  headers = ["Photo", "Member Name", "Relationship", "Date of Birth", "PAN Number",""];
  ngOnInit(): void 
  {
    this.items=this.fb;
  }
  tabChange(route:any)
  {
      this.viewMode=route;
      this.ModelService.showCards(route);
  }
  save()
  {
    this.items=this.profileForm.value;
    this.rows.push(this.items)
    this.profileForm.value.url=this.imageUrl;
  }
  onSubmit() {

  }
  uploadFile(event:any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.registrationForm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();        
    }
  }
  delete(row:any)
  {
    let i=this.rows.indexOf(row)
    this.rows.splice(i,1);
  }
  edit(name:any)
  {
    let i=this.rows.indexOf(name)
    this.rows.splice(i);
  }
  public canvasWidth = 350
  public needleValue = 60
  public centralLabel = ''
  public name2 = ''
  public arcPadding = 10;
  public options = {
    needleUpdateSpeed: 6000,
    arcPadding: 5,
    arcPaddingColor: 'white',
    hasNeedle: true,
    needleColor: "black",
    needleStartValue: 10,
    arcColors: ["#DA6526", "#DA6526", "#DA6526", "#DA6526", "#DA6526", "#DA6526", "#DA6526",
      "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)", "rgb(239,214,19)",
      "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)", "rgb(61,204,91)"],
    arcDelimiters: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
    // rangeLabel: ["5","100"],
    rangeLabelFontSize: 0

  }
}


