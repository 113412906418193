
<div [ngSwitch]="riskTab">
<div class="container container-geojit-question" id="1" *ngSwitchCase="1">
      <div class="row section-question-geojit-question">
        <div class="col-sm-4" style="padding: 0px;">
        <div class=" section-progress-geojit-question">
          <div class="question-start-geojit-question" >
          <p class="pt-4">Risk Profiling Questionnaire</p>
          <h6 > Just A Few Questions<br> Away To Check Your<br> Risk Profile</h6>
          <p style="color: #333333;opacity: 0.85;">Many desktop publishing packages<br> and web page editors</p>
         </div>
          <div class="progress-bar-geojit-question" style="padding-right: 30px;">
              <div id="myProgress" style="display: flex;">
                <div id="myBar"></div>
              </div>
            </div>
          </div>
          <span class="page-index-geojit-question" > {{questionsTab}}/14</span>
          <!-- <button (click)="move()">Click Me</button>  -->
        </div>
        <div class="col-sm-8">
          <div class="container question-container-geojit-question">
          <div class="row custom-wrapper">
            <div class="col-md-12 switch-case-geojit-question" [ngSwitch]="questionsTab">
              <!-- Form starts -->
              <div style="text-align: left;padding-top: 20px;"><button class="btn-back-geojit-question" (click)="stepPrevious(questionsTab)">
                <img src="assets/about/Back.svg" style="padding-right: 5px;padding-bottom: 2px;"> Back</button></div>
              <section id="1" *ngSwitchCase="1">
              <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                <div class="group-gap">
                  <h5 class="mb-3" ><label>1. </label><label class="mb-0" style="float: left;padding-left: 3px;"> How old are you ?</label></h5>
                  <div class="d-block my-3">
                    <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                      <label style="position: initial;display: flex;" *ngFor="let q1 of Q1" class="answer-text-geojit-question">
                        <input type="radio" checked="checked" name="radio">
                        <span ></span>{{q1}}
                      </label> 
                    </div>
                  </div>
                </div>
              </form>
              </section>
              <section id="2" *ngSwitchCase="2">
                <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                  <div class="group-gap">
                    <h5 class="mb-3" ><label>2. </label><label class="mb-0" style="float: left;padding-left: 3px;"> What is your purpose for investment ?</label></h5>
                    <div class="d-block my-3">
                      <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                        <label style="position: initial;display: flex;" *ngFor="let q2 of Q2" class="answer-text-geojit-question">
                          <input type="radio" checked="checked" name="radio">
                          <span ></span>{{q2}}
                        </label> 
                      </div>
                    </div>
                  </div>
                </form>
                </section>
                <section id="3" *ngSwitchCase="3">
                  <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                    <div class="group-gap">
                      <h5 class="mb-3" ><label>3. </label><label class="mb-0" style="float: left;padding-left: 3px;"> What is your current financial situation ?</label></h5>
                      <div class="d-block my-3">
                        <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                          <label style="position: initial;display: flex;" *ngFor="let q3 of Q3" class="answer-text-geojit-question">
                            <input type="radio" checked="checked" name="radio">
                            <span ></span>{{q3}}
                          </label> 
                        </div>
                      </div>
                    </div>
                  </form>
                  </section>
                  <section id="4" *ngSwitchCase="4">
                    <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                      <div class="group-gap">
                        <!-- <div style="display: flex; " class="question-text-geojit-question"><span>{{questionsTab}}.</span><h5 class="mb-3" >How concerned are you about rising inflation increasing your cost of living?</h5></div> -->
                        <h5 class="mb-3" ><label>4. </label><label class="mb-0" style="float: left;padding-left: 3px;">How concerned are you about rising inflation increasing your cost of living?</label></h5>
                        <div class="d-block my-3">
                          <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                            <label style="position: initial;display: flex;" *ngFor="let q4 of Q4" class="answer-text-geojit-question">
                              <input type="radio" checked="checked" name="radio">
                              <span ></span>{{q4}}
                            </label> 
                          </div>
                        </div>
                      </div>
                    </form>
                    </section>
                      <section id="5" *ngSwitchCase="5">
                        <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                          <div class="group-gap">
                            <h5 class="mb-3" ><label>5. </label><label class="mb-0" style="float: left;padding-left: 3px;">Which of the following portfolio would you choose for investment?</label></h5>
                            <div class="d-block my-3">
                              <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                <label style="position: initial;display: flex;" *ngFor="let q5 of Q5" class="answer-text-geojit-question">
                                  <input type="radio" checked="checked" name="radio">
                                  <span ></span>{{q5}}
                                </label> 
                              </div>
                            </div>
                          </div>
                        </form>
                        </section>
                        <section id="6" *ngSwitchCase="6">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>6. </label><label class="mb-0" style="float: left;padding-left: 3px;"> Are you willing to risk short term loss in capital for a possible higher return in the long term?</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q6" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="7" *ngSwitchCase="7">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>7. </label><label class="mb-0" style="float: left;padding-left: 3px;"> You have planned for a foreign vacation after two months. meanwhile you lose your job (or incur loss in business/ profession).what will you do?</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q7" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="8" *ngSwitchCase="8">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>8. </label><label class="mb-0" style="float: left;padding-left: 3px;">From a game show, you are offered following options. which option will you take?</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q8" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="9" *ngSwitchCase="9">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>9. </label><label class="mb-0" style="float: left;padding-left: 3px;"> How secure is your current source of income (e.g. salary, business, profession, investment etc...)</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q9" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="10" *ngSwitchCase="10">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>10. </label><label class="mb-0" style="float: left;padding-left: 3px;"> The value of your investment falls by 20% in one week. assuming that you still have 5 years until you start withdrawing your money from the investment, you will___________</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q10" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="11" *ngSwitchCase="11">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>11. </label><label class="mb-0" style="float: left;padding-left: 3px;"> How would you describe your approach to investing in assets like equity shares compared to investing in other assets like gold, property, currency etc?</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q11" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="12" *ngSwitchCase="12">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>12. </label><label class="mb-0" style="float: left;padding-left: 3px;"> In case of an emergency, will you consider withdrawing money from your investments?</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q12" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="13" *ngSwitchCase="13">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>13. </label><label class="mb-0" style="float: left;padding-left: 3px;">You always invest when you come across a sales pitch at a bank or any financial institution. you seldom take your own investment initiative</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q13" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <section id="14" *ngSwitchCase="14">
                          <form [formGroup]="registrationForm"  class="question-box-geojit-question" >
                            <div class="group-gap">
                              <h5 class="mb-3" ><label>14. </label><label class="mb-0" style="float: left;padding-left: 3px;"> Your investments are up by 25% in three months and you hear that the markets are about to crash. you will__________.</label></h5>
                              <div class="d-block my-3">
                                <div class="custom-control custom-radio" style="text-align: left;padding-left: 0px;">
                                  <label style="position: initial;display: flex;" *ngFor="let q6 of Q14" class="answer-text-geojit-question">
                                    <input type="radio" checked="checked" name="radio">
                                    <span ></span>{{q6}}
                                  </label> 
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
  

              <div  class="btn-next-geojit-question"><button style="width: 110px;" class="button " (click)="stepNext(questionsTab)">Next</button></div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div id="2" *ngSwitchCase="2">

  <div  class="row risk-profile-geojit-about">
    
 <div class="col-sm-1" style="    margin-top: -7px;"> <img src="assets/about/like.svg">

</div>
  <div class="col-sm-11 risk-text-geojit-question" >
  <h6>Your risk profile is Moderate. Always make investment decisions to match your profile.</h6>

  </div>

  </div>
 
  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [options]="options"
  [name]="name2" class="gauge-chart-geojit-modal">
  </rg-gauge-chart>

<rg-gauge-chart [canvasWidth]="canvasWidth2" [needleValue]="needleValue" [options]="options"
[name]="name2" class="gauge-chartmobile-geojit-modal">
</rg-gauge-chart>

<div class=" col-md-12 chart-label-geojit-dashboard for-mobile-view-common  padding-geojit-mobile width-canvas" >
  <ul class="riskprofile-label-geojit-about">
  <li style='background-image: url("/assets/dash/green.svg");'>Conservative</li>
  <li style='background-image: url("/assets/dash/yellow.svg");'>Moderate</li>
  <li style='background-image: url("/assets/dash/orangel.svg");'>Moderately Aggressive</li>
  <li style='background-image: url("/assets/dash/orange.svg");margin-right: 0px;' >Aggressive</li>
  </ul>
</div>
<div  class="row verify-text-geojit-about" ><p >On verifying the answers in the questionnaire, it has been observed that your risk profile is <span style="font-weight: 500;">MODERATE</span> Hence we recommend a portfolio with 40% in Equity Related Investments, 50% in Fixed Income Investments and 10% in Gold .</p></div>



 <button class="back-btn-geojit-common" style="margin-top: 20px;width: 111px;">Back</button>

    <button class="save-btn-geojit-common" style="margin-left: 15px;">Next</button>



</div>
  </div>