

<div class="col-sm-12 calculater-main-geojit-caculator">
    <div class="calculater-section-geojit-caculator">

<div class="row text-center" style="padding-top: 17px;">
    <div class="col-md-4">
      
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="view = CalendarView.Month"
          [class.active]="view === CalendarView.Month">
          Month
        </div>
        <div
          class="btn btn-primary"
          (click)="view = CalendarView.Week"
          [class.active]="view === CalendarView.Week">
          Week
        </div>
        <div
          class="btn btn-primary"
          (click)="view = CalendarView.Day"
          [class.active]="view === CalendarView.Day">
          Day
        </div>
      </div>
    </div>
   
    
  </div>
  <br>
  <div>
    <div style="height: 60px;border: 1px solid #EFEFEF;margin: 0px;" class="row">
        <div class="col-md-4" style="align-self: center;
        text-align: left;">
            <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
          </div>
        <div class="col-md-8 calendar-center-geojit-calendar" style="align-self: center;text-align: right;">
            <div class="btn-group" style="padding-right: 10px;">
                <div
                  class="btn btn-primary btn-primary-geojit-calendar"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="activeDayIsOpen = false" style="background: transparent;">
                  <img src="assets/calendar/Back.svg">
                </div>
                <div
                  class="btn btn-outline-secondary"
                  mwlCalendarToday
                  [(viewDate)]="viewDate" style="width: auto;padding: 10px 0px 4px 22px;" >
                  Today
                </div>
                <div
                  class="btn btn-primary btn-primary-geojit-calendar"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="activeDayIsOpen = false" style="background: transparent;">
                  <img src="assets/calendar/Forword.svg">
                </div>
              </div>
          <!-- <button
          class="btn btn-primary pull-right"
          (click)="addEvent()">
          Add new
        </button> -->
        </div>
    
    </div>
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>
</div>
  <br><br><br>
        </div>
    </div>






