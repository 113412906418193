<app-header (click)="click()"></app-header>
<app-sidebar></app-sidebar>

<div class="main-part ">

  <div class="row mobile-hide-common" style="background: #F0F0F0;    height: 54px;--bs-gutter-x: 0px;">
    <div class="container">
      <div class="tab-slider--nav">
        <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 2px solid #EFEFEF;
      height: 54px; ">
          <li class="tab-slider--trigger col-md-2" [class.active]="viewMode == '/dashboard'" rel="dashboard"
            (click)="Tabchange('/dashboard')" [routerLink]="['dashboard']"><img style="padding: 0px 7px 4px 0px;"
              class="tick-icon-geojit-financial" src="assets/tabs/dash.svg" >Dashboard</li>

          <li class="tab-slider--trigger col-md-2" [class.active]="viewMode == '/my-financial/about'"
            rel="my-financial" (click)="Tabchange('/my-financial/about')" [routerLink]="['my-financial']"><img style="padding: 0px 7px 4px 0px;"
              class="tick-icon-geojit-financial" src="assets/tabs/my-fina.svg" >My Financial Canvas </li>

          <li class="tab-slider--trigger col-md-2" *ngIf="tabactive=='tabactive'" rel="tab3"
            [class.active]="viewMode == '/report'" (click)="viewMode ='tab3'" [routerLink]="['report']" (click)="Tabchange('/report')"><img style="padding: 0px 7px 4px 0px;"
              class="tick-icon-geojit-financial" src="assets/tabs/report.svg">Report</li>

          <li class="tab-slider--trigger col-md-2" *ngIf="tabactive=='tabactive'" rel="tab4"
            [class.active]="viewMode == '/calendar'" (click)="viewMode ='tab4'" [routerLink]="['calendar']" (click)="Tabchange('/calendar')"><img style="padding: 0px 7px 4px 0px;"
              class="tick-icon-geojit-financial" src="assets/tabs/calender.svg">Calendar</li>

          <li class="tab-slider--trigger col-md-2" *ngIf="tabactive=='disabled'" rel="tab3"
            [class.active]="viewMode == 'tab3'" style="color:#929292"><img style="padding: 0px 7px 4px 0px;"
              class="tick-icon-geojit-financial" src="assets/tabs/report-dis.svg">Report</li>

          <li class="tab-slider--trigger col-md-2" *ngIf="tabactive=='disabled'" rel="tab4"
            [class.active]="viewMode == 'tab4'" style="color:#929292"><img style="padding: 0px 7px 4px 0px;"
              class="tick-icon-geojit-financial" src="assets/tabs/calender-dis.svg">Calendar</li>

          <li class="tab-slider--trigger col-md-2" rel="tab5" [class.active]="viewMode == '/calculator'"
            (click)="viewMode ='tab5'" [routerLink]="['calculator']" (click)="Tabchange('/calculator')"><img style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial"
              src="assets/tabs/calcu.svg" >Calculator</li>



          <li class="tab-slider--trigger col-md-2" rel="tab6" [class.active]="viewMode == 'tab6'"
            (click)="viewMode ='tab6'" [routerLink]="['user-manual']"><img style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial"
              src="assets/tabs/manual.svg">User Manual</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container">
    <div *ngIf="viewMode !== '/dashboard'">
    <div *ngIf="viewMode == '/calendar' || viewMode == '/calculator'|| viewMode == '/report'"  class="url-text-geojit-common">Dashboard <span style="padding-left: 5px;"> {{viewMode}}</span></div>
    <div *ngIf="viewMode == '/my-financial/about'">

      <div *ngIf="url == 'Personal Profile' || url == 'Contact Details'|| url == 'Family Details' || url == 'Risk Profiler'" class="url-text-geojit-common">About me /<span style="padding-left: 5px;">{{url}} </span></div>
      <div *ngIf="url == 'Investments' || url == 'Income'|| url == 'Insurance' || url == 'Assets'" class="url-text-geojit-common">Money Measures /<span style="padding-left: 5px;">{{url}} </span></div>
      <div *ngIf="url == 'Goals' " class="url-text-geojit-common">Financial Goals /<span style="padding-left: 5px;">{{url}} </span></div>
    </div>


  </div>
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #content let-modal
  style="width: 392px;border: none;height: 100%; display: flex; top: 5%;align-items: center;text-align: -webkit-center;">
  <div [ngSwitch]="viewModel" style="text-align: -webkit-center;">
    <div style="text-align: right;">
      <button class="btn-close-geojit-modal" aria-hidden="true" style="cursor: pointer;"
        (click)="modal.dismiss('Cross click')">
        <img src="assets/dash/close.svg">
      </button>
    </div>
    <section id="2" *ngSwitchCase="2" style="height: 366px;">

      <div class="modal-body" style="text-align: center;">
        <h6>Financial Wellness Checkup</h6>
        <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [centralLabel]="centralLabel"
          [options]="options" [name]="name2" [bottomLabel]="bottomLabel" class="gauge-chart-geojit-modal">
        </rg-gauge-chart>


        <!-- <div class="chartBox"> -->
        <!-- <canvas id="myChart"></canvas> -->
        <!-- </div> -->
        <div class="row">
          <div class="col-md-6 chart-label-geojit-dashboard ideal-padding-geojit-mobile">
            <ul>
              <li style='background-image: url("/assets/dash/green.svg");height: 37px;background-position: 2px 6px;
                  padding-left: 18px;    text-align: left;font-weight: 400'>Good</li>
              <li style='background-image: url("/assets/dash/yellow.svg");height: 37px;background-position: 2px 6px;
                  padding-left: 18px;    text-align: left;font-weight: 400'>Adequate</li>
              <li style='background-image: url("/assets/dash/orange.svg");height: 37px;background-position: 2px 6px;
                  padding-left: 18px;    text-align: left;font-weight: 400'>Need Improvement</li>
            </ul>
          </div>
          <div class="col-md-6 chart-label-geojit-dashboard" style="    text-align: left;">
            <ul class="table-padding-geojit-mobile">
              <li style="padding-left: 0px;    height: 50px;"><span>Ideal Measure</span><br>Between 3 and 6</li>
              <li style="padding-left: 0px;    height: 50px;"><span>Your Score</span><br>1.39</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="1" *ngSwitchCase="1" style="height: 366px;">
      
      <div class="modal-body" style="text-align: center;">
        <h6>Risk Profiler</h6>
        <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [centralLabel]="centralLabel"
          [options]="options2" [name]="name2" [bottomLabel]="bottomLabel4">
        </rg-gauge-chart>
        <div class="row">
          <div class="col-md-6 chart-label-geojit-dashboard">
            <ul>
              <li style='background-image: url("/assets/dash/green.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Good</li>
              <li style='background-image: url("/assets/dash/yellow.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Adequate</li>
              <li style='background-image: url("/assets/dash/orange.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Need Improvement</li>
            </ul>
          </div>
          <div class="col-md-6 chart-label-geojit-dashboard" style="    text-align: left;">
            <ul>
              <li style="padding-left: 0px;    height: 50px;"><span>Ideal Measure</span><br>Less than 15%</li>
              <li style="padding-left: 0px;    height: 50px;"><span>Your Score</span><br>0%</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="3" *ngSwitchCase="3" style="height: 366px;">
      <div class="modal-body" style="text-align: center;">
        <h6>Financial Wellness Checkup</h6>
        <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [centralLabel]="centralLabel"
          [options]="options" [name]="name2" [bottomLabel]="bottomLabel3">
        </rg-gauge-chart>
        <div class="row">
          <div class="col-md-6 chart-label-geojit-dashboard">
            <ul>
              <li style='background-image: url("/assets/dash/green.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Good</li>
              <li style='background-image: url("/assets/dash/yellow.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Adequate</li>
              <li style='background-image: url("/assets/dash/orange.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Need Improvement</li>
            </ul>
          </div>
          <div class="col-md-6 chart-label-geojit-dashboard" style="    text-align: left;">
            <ul>
              <li style="padding-left: 0px;    height: 50px;"><span>Ideal Measure</span><br>More than 20%</li>
              <li style="padding-left: 0px;    height: 50px;"><span>Your Score</span><br>50%</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section id="4" *ngSwitchCase="4" style="height: 366px;">
      <div class="modal-body" style="text-align: center;">
        <h6>Financial Wellness Checkup</h6>
        <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [centralLabel]="centralLabel"
          [options]="options" [name]="name2" [bottomLabel]="bottomLabel4">
        </rg-gauge-chart>
        <div class="row">
          <div class="col-md-6 chart-label-geojit-dashboard">
            <ul>
              <li style='background-image: url("/assets/dash/green.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Good</li>
              <li style='background-image: url("/assets/dash/yellow.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Adequate</li>
              <li style='background-image: url("/assets/dash/orange.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Need Improvement</li>
            </ul>
          </div>
          <div class="col-md-6 chart-label-geojit-dashboard" style="    text-align: left;">
            <ul>
              <li style="padding-left: 0px;    height: 50px;"><span>Ideal Measure</span><br>Less than 15%</li>
              <li style="padding-left: 0px;    height: 50px;"><span>Your Score</span><br>0%</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="5" *ngSwitchCase="5" style="height: 366px;">
      <div class="modal-body" style="text-align: center;">
        <h6>Financial Wellness Checkup</h6>
        <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [centralLabel]="centralLabel"
          [options]="options" [name]="name2" [bottomLabel]="bottomLabel2">
        </rg-gauge-chart>
        <div class="row">
          <div class="col-md-6 chart-label-geojit-dashboard">
            <ul>
              <li style='background-image: url("/assets/dash/green.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Good</li>
              <li style='background-image: url("/assets/dash/yellow.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Adequate</li>
              <li style='background-image: url("/assets/dash/orange.svg");height: 37px;background-position: 2px 6px;
                    padding-left: 18px;    text-align: left;font-weight: 400'>Need Improvement</li>
            </ul>
          </div>
          <div class="col-md-6 chart-label-geojit-dashboard" style="    text-align: left;">
            <ul>
              <li style="padding-left: 0px;    height: 50px;"><span>Ideal Measure</span><br>Less than 50%</li>
              <li style="padding-left: 0px;    height: 50px;"><span>Your Score</span><br>3%</li>
            </ul>
          </div>
        </div>
      </div>

    </section>
  </div>
  <div class="tab-slider--tabs" style="text-align:center">
    <span class="tab-slider--trigger col-md-3" [class.active]="viewModel == 1 || viewModel == 2 || viewModel == 3 || viewModel == 4  || viewModel == 5" rel="tab" (click)="viewModel =1"
      class="step"></span>
    <span class="tab-slider--trigger col-md-3" [class.active]="viewModel == 2 || viewModel == 3 || viewModel == 4  || viewModel == 5" rel="tab2" (click)="viewModel =2"
      class="step"></span>
    <span class="tab-slider--trigger col-md-3" [class.active]="viewModel == 3 || viewModel == 4  || viewModel == 5" rel="tab3" (click)="viewModel =3"
      class="step"></span>
    <span class="tab-slider--trigger col-md-3" [class.active]="viewModel == 4  || viewModel == 5" rel="tab4" (click)="viewModel =4"
      class="step"></span>
      <span class="tab-slider--trigger col-md-3" [class.active]="viewModel == 5" rel="tab5" (click)="viewModel =5"
      class="step"></span>
  </div>
  <div class="row" style="padding-top: 10px;">
    <div class="col " style="text-align: right;padding: 0px;">
      <button  style="background: transparent;" (click)="stepPrevious(viewModel)" class="nextprev-btn-geojit-main border-0"><img
          *ngIf="viewModel == 1" src="assets/dash/left.svg">

        <img *ngIf="viewModel == 2 || viewModel == 3 || viewModel == 4 || viewModel == 5" src="assets/dash/leftactive.svg">
      </button>

    </div>
    <div class="col " style="padding: 0px;text-align:left">
      <button style="background: transparent;" class="border-0 nextprev-btn-geojit-main" (click)="stepNext(viewModel)"><img
          src="assets/dash/right.svg"></button>

    </div>
  </div>
</ng-template>





