import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class QuestionAnswerComponent implements OnInit {
  isSubmitted = false;
  questionsTab=1;
  riskTab=1;
  constructor(public fb: FormBuilder) { }

  ngOnInit(): void {
  }
    i:any = 0;


 /*########### Form ###########*/
 registrationForm = this.fb.group({
  gender: ['male', [Validators.required]]
})

// Getter method to access form control
get myForm() {
  return this.registrationForm.get('gender');
}
Q1: any[] = ["Less than 30", "Between 30 and 40", "Between 40 and 50", "Between 50 and 60", "Above 60"];
Q2: any[] = ["Capital Protection", "Returns should be fixed. Even though low but steady.", "Reasonable growth with little risk", "Maximum growth over the long term", "High returns even though it may involve higher risk"];
Q3: any[] = ["No savings and huge debt", "A little savings and high debt", "Balanced debt and reasonable savings", "Financially secure with no debt", "Regular savings and short term debts are paid off immediately"];
Q4: any[] = ["I am not concerned", "Yes. I have become cautious with respect to expenditure", "Balanced debt and reasonable savings", "Yes. It is a matter of concern but I can manage", "Yes. It is very worrying and my situation is alarming"];
Q5: any[] = ["Probability of 6% returns with 0% risk of losing capital", "Probability of 15% returns with 5% risk of losing capital", "Probability of 25% returns with 10% risk of losing capital", "Probability of 40% returns with 18% risk of losing capital", "Probability of 50% returns with 24% risk of losing capital or more"];
Q6: any[] = ["Probability of 6% returns with 0% risk of losing capital", "Probability of 15% returns with 5% risk of losing capital", "Probability of 25% returns with 10% risk of losing capital", "Probability of 40% returns with 18% risk of losing capital", "Probability of 50% returns with 24% risk of losing capital or more"];
Q7: any[] = ["I will not go for the vacation", "I will go on a vacation, but will change the venue to a cheaper location", "I will go as planned, because I might not get a chance in the near future"];
Q8: any[] = ["Rs. 5000 as ready cash", "A coin will be tossed, If it is ‘heads’ then you win Rs.10000. Game enrolment fee is Rs.1000", "A dice will be thrown, If number ‘1’ appears you win Rs.25000. Game enrolment fee is 2500", "A chance to pick a card from a deck of10 cards numbered 1 to 10.If you pick up the card numbered ‘1’, you will win Rs.50000 . Game enrolment fee is 5000", "A lottery pick among 50 people with a prize money of Rs.100000. Cost of lottery is Rs.10000"];
Q9: any[] = ["Not secure", "Somewhat secure", "Secure", "Very secure"];
Q10: any[] = ["Not change the portfolio. In fact invest more", "Wait for 1 year and see if any change is required", "Wait for three months and shift the money into stable investments", "Wait for 6 months and then shift the money to fixed income products","Change the portfolio immediately"];
Q11: any[] = ["I am not interested in any investments. I would rather keep my money in the bank account", "I have some investments in equity", "I prefer equity to any other investment because I want my money to grow in long term along with the economy at a higher pace that other asset classes, even if that means I take higher risk in the short term", "I have my investments in equity only","I have done some equity investments but I do panic when I see short term losses in my account"];
Q12: any[] = ["No. I have enough funds to meet such emergencies", "I have to take a loan which I can pay back within a year", "I might forgo my short term goals and use that money to meet the emergency", "I might have to pull funds from long term investments such as RDs, FDs or Insurance Plans"];
Q13: any[] = ["Very true", "Most of the time", "Some times", "I never take advice or opinion while investing"];
Q14: any[] = ["Book Profits and invest at lower levels", "Shift the entire investment into safer options and wait for the next opportunity", "May not book profits. And will continue to invest regularly", "Shift the profits component into safer investments"];

  
progressbar:any;
stepNext(viewModel:any)
  {
    this.questionsTab=viewModel;
    for(let i=1;i<=13;++i)
    {

        if(i==viewModel)
        {

          this.progressbar=i*7+9;
          var elem = document.getElementById("myBar");
          elem!.style.width = this.progressbar +"%";
          this.questionsTab=i+1;
         
        }
       
         
      
    }
    if(viewModel==14)
    {
        this.riskTab=2;
    }
  }
  stepPrevious(viewModel:any)
  {
    this.progressbar=this.progressbar-7;
    this.questionsTab=viewModel;
    for(let i=14;i>1;--i)
    {
        if(i==viewModel)
        {
          var elem = document.getElementById("myBar");
          elem!.style.width = this.progressbar +"%";
          this.questionsTab=i-1;
        }
    }
  }
  public canvasWidth = 350
  public canvasWidth2 = 250
  public needleValue = 60
  public centralLabel = ''
  public name2 = ''
  public arcPadding = 10;
  public options =
   {
    needleUpdateSpeed: 6000,
    arcPadding: 5,
    arcPaddingColor: 'white',
    hasNeedle: true,
    needleColor: "black",
    needleStartValue: 10,
    arcColors: [
      "rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)","rgb(61,204,91)",
      "rgb(239,214,19)","rgb(239,214,19)","rgb(239,214,19)", "rgb(239,214,19)","rgb(239,214,19)",
      "#EF8C57","#EF8C57","#EF8C57","#EF8C57","#EF8C57","#EF8C57",
      "#DA6526","#DA6526","#DA6526","#DA6526","#DA6526"
    ],
    arcDelimiters: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
    rangeLabelFontSize: 0
  }
}
