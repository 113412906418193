<div id="svg_wrap" style="fill: rgb(14 230 4)important;    margin: 23px 48px 0px 228px;width: 390px;display: none;">
</div>
<div>
  <div class="row">
    <div class="tab-slider--nav">
      <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 2px solid #EFEFEF;height: 50px; margin: 0px;">
        <li class="tab-slider--trigger col-md-3" [class.active]="viewMode == 'Investments'" rel="Investments" (click)="tabChange('Investments')">My Investments</li>
        <li class="tab-slider--trigger col-md-3"  [class.active]="viewMode == 'Income'" rel="Income" (click)="tabChange('Income')">Income/<br>Expense </li>
        <li class="tab-slider--trigger col-md-3" rel="Insurance" [class.active]="viewMode == 'Insurance'" (click)="tabChange('Insurance')">Insurance</li>
        <li class="tab-slider--trigger col-md-3" rel="Assets" [class.active]="viewMode == 'Assets'" (click)="tabChange('Assets')">Assets/<br>Liabilities</li>
      </ul>
    </div>
  </div>
</div>
<div [ngSwitch]="viewMode">
  <section id="Investments" *ngSwitchCase="'Investments'" style="padding: 0px;margin: 0px;height: 700px;">
    <app-my-investments></app-my-investments>
  </section>
  <section id="Income" *ngSwitchCase="'Income'" style=" height: 605px;padding: 0px;margin: 0px;">
    <app-income></app-income>
  </section>
  <section style="height: 624px;padding: 0px;margin: 0px;" id="Insurance" *ngSwitchCase="'Insurance'">
    <app-insurance></app-insurance>
  </section>
  <section id="Assets" *ngSwitchCase="'Assets'" style="padding: 0px;margin: 0px;">
    <app-liabilities></app-liabilities>
  </section>
</div>