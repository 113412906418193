<div   class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 364px;margin-top: 11px;">
    <h6 class="bottom-right">INVESTMENT</h6>
    <div class="card-body kyc-card-geojit-dashboard ">
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas #retirement style="width: 106px;height: 106px;"></canvas>
          <canvas  #portfolio style="width: 165px;height: 165px;display: none;"></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 12,563<br><span>Equity</span></li>
            <li style='background-image: url("/assets/dash/blue.svg");height: 58px;'>₹ 11,587<br><span>MF</span></li>
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'>₹ 5,833<br><span>FD</span>
            </li>
          </ul>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/orange.svg");height: 58px;'>₹ 12,563<br><span>Gold</span></li>
            <li style='background-image: url("/assets/dash/greens.svg");height: 58px;'>₹ 11,587<br><span>Proprety</span></li>
            <li style='background-image: url("/assets/dash/violet.svg");height: 58px;'>₹ 5,833<br><span>Others</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  <div  class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 305px;margin-top: 11px;    ">
    <h6 class="bottom-right">RECOMMENDED</h6>
    <div class="card-body kyc-card-geojit-dashboard " >
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas  #netWorth style="width: 165px;height: 165px;" ></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 12,563<br><span>Debt</span></li>
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'>₹ 11,587<br><span>Equity</span></li>
          </ul>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/pink.svg");height: 58px;'>₹ 12,563<br><span>Gold</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>