
  <div class="tab-slider--nav" >
    <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 1px solid #EFEFEF;
      height: 54px; ">
      <li class="tab-slider--trigger col-md-2" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="Tabchange('tab1')">
        Assets</li>
      <li class="tab-slider--trigger col-md-2" [class.active]="viewMode == 'tab2'" rel="my-financial"
        (click)="Tabchange('tab2')">Liabilities</li>
    </ul>
  </div>
  <div [ngSwitch]="viewMode">
  
    <!-- section tab 1 -->
    <div id="tab1" *ngSwitchCase="'tab1'" >
      <form [formGroup]="profileForm">
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
          <div class="form-group col-md-3">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;" >
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Assets Details</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
             ><label>Loan Amount</label>
          </div>
          <div class="form-group col-md-3">
            <input type="date" class="form-control "  placeholder=" " >
            <label>Loan Started Date</label>
        </div>
        <div class="form-group col-md-3">
          <input type="date" class="form-control "  placeholder=" " >
          <label>Loan End Date</label>
      </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
              ><label>Loan Down Payment</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
             ><label>Interest Rate</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
              ><label>EMI Amount</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
              ><label>Loan Outstanding</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
              ><label>Tenture in Months</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" "
             ><label>Outstanding Tenture</label>
          </div>
              <div class="form-group col-md-6" style="text-align: right;">
                <button class="button mr-2"
                  style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                  id="prev">Cancel</button>
                <button class="button" type="submit" style="width: 107px;background: transparent;color: #29B473;"
                  (click)="save()">Save</button>
            <!-- </div> -->
        </div>
    </div>
        <div class="form-row" style="    margin-top: 12px; height: auto;">
          <div class="form-group col-md-12" style="    padding-right: 25px;padding-left: 25px;">
            <table class="table-common-geojit">
              <tr class="header-table-geojit-common">
                <th style="width: 80%;">Items</th>
                <th>Monthly</th>
                <th></th>
              </tr>
              <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                <td>Savings Bank Account</td>
                <td>660,000</td>
                <td>
                  <button class="button-edit-geojit-about" ><img
                      src="assets/about/edit.svg" /></button>
                  <button class="button-edit-geojit-about" ><img
                      src="assets/about/delete.svg" /></button>
                </td>
              </tr>
              <tr style="height: 50px;">
                <td>PPF Balance</td>
                <td>23,6823</td>
                <td>
                  <button class="button-edit-geojit-about" ><img
                      src="assets/about/edit.svg" /></button>
                  <button class="button-edit-geojit-about" ><img
                      src="assets/about/delete.svg" /></button>
                </td>
              </tr>
              
            </table>
          </div>
        </div>
        <div  class="form-row form-rowbtn-geojit-measure">
          <div class="form-group col-md-12" style="    display: contents;">
            <button class="button mr-2"
              style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
              (click)="Tabchange('tab1')">Back</button>
            <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab3')">Next</button>
          </div>
        </div>
      </form>
    </div>
    <div id="tab2" *ngSwitchCase="'tab2'">
        <form [formGroup]="profileForm">
            <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
                <div class="form-group col-md-3">
                    <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                      style="padding: 9px 8px;    height: 42px;" formControlName="Items">
                      <option disabled selected>Select</option>
                      <option value="volvo">Single</option>
                      <option value="saab">Female</option>
                      <option value="mercedes">Other</option>
                    </select>
                    <label>Liabilities Details</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" "
                      formControlName="Monthly"><label>Loan Amount</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="date" class="form-control "  placeholder=" " formControlName="date">
                    <label>Loan Started Date</label>
                </div>
                <div class="form-group col-md-3">
                  <input type="date" class="form-control "  placeholder=" " formControlName="date">
                  <label>Loan End Date</label>
              </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" "
                      formControlName="Monthly"><label>Loan Down Payment</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control " placeholder=" "
                      formControlName="Monthly"><label>Interest Rate</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" "
                      formControlName="Monthly"><label>EMI Amount</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" "
                      formControlName="Monthly"><label>Loan Outstanding</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control "  placeholder=" "
                      formControlName="Monthly"><label>Tenture in Months</label>
                  </div>
                  <div class="form-group col-md-3">
                    <input type="email" class="form-control " placeholder=" "
                      formControlName="Monthly"><label>Outstanding Tenture</label>
                  </div>
                  <div class="form-group col-md-6" style="text-align: right;">
                    <button class="button mr-2"
                      style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                      id="prev">Cancel</button>
                    <button class="button" type="submit" style="width: 107px;background: transparent;color: #29B473;"
                      (click)="save()">Save</button>
                <!-- </div> -->
            </div>
        </div>
            <div class="form-row" style="    margin-top: 12px; height: auto;">
              <div class="form-group col-md-12" style="    padding-right: 25px;padding-left: 25px;">
                <table class="table-common-geojit">
                  <tr class="header-table-geojit-common">
                    <th style="width: 80%;">Items</th>
                    <th>Monthly</th>
                    <th></th>
                  </tr>
                  <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                    <td>PPF Balance</td>
                    <td>23,6823</td>
                    <td>
                      <button class="button-edit-geojit-about" ><img
                          src="assets/about/edit.svg" /></button>
                      <button class="button-edit-geojit-about" ><img
                          src="assets/about/delete.svg" /></button>
                    </td>
                  </tr>
                  <tr style="height: 50px;">
                    <td>Savings Bank Account</td>
                    <td>660,000</td>
                    <td>
                      <button class="button-edit-geojit-about" ><img
                          src="assets/about/edit.svg" /></button>
                      <button class="button-edit-geojit-about" ><img
                          src="assets/about/delete.svg" /></button>
                    </td>
                  </tr>
                  
                </table>
              </div>
            </div>
            <div  class="form-row form-rowbtn-geojit-measure">
              <div class="form-group col-md-12" style="    display: contents;">
                <button class="button mr-2"
                  style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
                  (click)="Tabchange('tab1')">Back</button>
                <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab3')">Next</button>
              </div>
            </div>
          </form>
    </div>
  </div>