
  <div  class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 250px; margin-top: 14px;">
    <h6 class="bottom-right">SURPLUS CHART</h6>
    <div class="card-body kyc-card-geojit-dashboard " >
      <div class="row" style="justify-content: center;">
        <div class="col-md-12" style="text-align: -webkit-center;width: 153px;">
          <canvas  #netWorth style="width: 165px;height: 165px;" ></canvas>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard chart-mobile-geojit-dashboard">
          <ul style="padding-left: 19px">
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'>₹ 12,563<br><span>Surplus</span></li>
          </ul>
        </div>
        <div class="col-md-6 chart-label-geojit-dashboard chart-mobile-geojit-dashboard">
          <ul style="padding-left: 0px">
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'>₹ 12,563<br><span>Expense</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div   class="card bg-gradient-danger card-img-holder text-white  grid-margin kyc-box-geojit-canvas" style="height: 631px;margin-top: 11px;">
    <h6 class="bottom-right">EXPENSE CHART</h6>
    <div class="card-body kyc-card-geojit-dashboard ">
      <div class="row" style="justify-content: center;">
        <div class="col-md-12 scrollmenu" style="text-align: -webkit-center;">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType" style="min-height: 200px;min-width: 390px;">
            </canvas>
        </div>
        <div class="col-md-12 chart-label-geojit-dashboard">
          <ul style="padding-left: 0px;">
            <li style='background-image: url("/assets/dash/pink.svg");height: 58px;'><span>Baby Care</span><label>₹ 2586</label></li>
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'><span>Medical</span><label>₹ 286</label></li>
            <li style='background-image: url("/assets/dash/yellow.svg");height: 58px;'><span>Rent & Maintenance</span><label>₹ 5236</label></li>
            <li style='background-image: url("/assets/dash/blue.svg");height: 58px;'><span>Vehicle Insurance</span><label>₹ 2586</label></li>
            <li style='background-image: url("/assets/dash/orange.svg");height: 58px;'><span>Transportations</span><label>₹ 286</label></li>
            <li style='background-image: url("/assets/dash/violet.svg");height: 58px;'><span>Baby Care</span><label>₹ 5236</label></li>
            <li style='background-image: url("/assets/dash/green.svg");height: 58px;'><span>Medical</span><label>₹ 2586</label></li>
            <li style='background-image: url("/assets/dash/greens.svg");height: 58px;'><span>Rent & Maintenance</span><label>₹ 286</label></li>
            <li style='background-image: url("/assets/dash/orangel.svg");height: 58px;'><span>Vehicle Insurance</span><label>₹ 5236</label></li>
            <li style='background-image: url("/assets/dash/pink.svg");height: 58px;'><span>Transportations</span><label>₹ 5236</label></li>
          </ul>
        </div>
      </div>
    </div>
    </div>