import { Component, ViewChild, Input,OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-liabilities-chart',
  templateUrl: './liabilities-chart.component.html',
  styleUrls: ['./liabilities-chart.component.scss']
})
export class LiabilitiesChartComponent implements  OnInit,AfterViewInit {
  @ViewChild('retirement') retirement:any;
  @ViewChild('assets') assets:any;
  @ViewChild('liability') liability:any;

  constructor() { }

  ngOnInit(): void {
  }
  @Input() colors = ['#29B473', '#FFDD00'];
  @Input() data=  [ 12563,  5833];

  @Input() colors2 = ['#00B16A', '#87D37C','#BBE6B9','#A2DED0'];
  @Input() data2=  [2833,1863,455,0];

  @Input() colors3 = ['#FFDD33', '#F6AE4E'];
  @Input() data3=  [2833,1863];
  canvas: any;
  ctx: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  doughnutChartRef:any;
 ngAfterViewInit() {

  this.canvas=this.retirement.nativeElement;
  this.ctx = this.canvas.getContext('2d');

  this.canvas2=this.assets.nativeElement;
  this.ctx2 = this.canvas2.getContext('2d');
  
  this.canvas3=this.liability.nativeElement;
  this.ctx3= this.canvas3.getContext('2d');
    
  
     
      this.doughnutChartRef = new Chart(this.ctx, {
        type: 'doughnut',
        data: {
        datasets: [{
            borderWidth: 3,
            weight: 20,
            hoverBorderWidth: 1,
            data: this.data,
            backgroundColor: this.colors
        }],
      },
        options: {
          legend: {
            display: true,
          },
          cutoutPercentage: 75,
       elements: {
      }
        }
    });
    this.doughnutChartRef = new Chart(this.ctx2, {
      type: 'doughnut',
      data: {
      datasets: [{
          borderWidth: 3,
          weight: 20,
          hoverBorderWidth: 1,
          data: this.data2,
          backgroundColor: this.colors2
      }],
    },
      options: {
        legend: {
          display: true,
        },
        cutoutPercentage: 75,
     elements: {
    }
      }
  });
  this.doughnutChartRef = new Chart(this.ctx3, {
    type: 'doughnut',
    data: {
    datasets: [{
        borderWidth: 3,
        weight: 20,
        hoverBorderWidth: 1,
        data: this.data3,
        backgroundColor: this.colors3
    }],
  },
    options: {
      legend: {
        display: true,
      },
      cutoutPercentage: 75,
   elements: {
  }
    }
});
  }

}
