
<div id="sidebar" class="sidebar sidebar-geojit-dashboard sideb"  ng-controller="HeaderController">
    <div >
      <ul class="list-unstyled components">
         
          <li style="padding-top: 18px;" (click)="hideSidebar()">
            <a (click)="onClick('dashboard')"   [ngClass]="{'active': tab==='dashboard'}" [routerLink]="['dashboard']" class="active"  > 
              <img  style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial" src="assets/tabs/dash.svg" />Dashboard</a>
          </li>
            <a (click)="onClick('my-financial')"   [ngClass]="{'active': tab==='my-financial'}" > 
              <img style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial" src="assets/tabs/my-fina.svg" />
              My Financial Canvas</a>
              <ul  class="financial-subtabs-geojit-sidebar" [ngClass]="{'display': tab==='my-financial' ||  tab==='my-financial/about'  ||  tab==='my-financial/money-measure' ||  tab==='my-financial/financial-goals' ||  tab==='my-financial/my-plan'}">
                  <li  [ngClass]="{'tab-color-change': tab==='my-financial/about'}" (click)="onClick('my-financial/about')">1. About Me</li>
                  <li [ngClass]="{'tab-color-change': tab==='my-financial/money-measure'}" (click)="onClick('my-financial/money-measure')">2. Money Measures</li>
                  <li [ngClass]="{'tab-color-change': tab==='my-financial/financial-goals'}" (click)="onClick('my-financial/financial-goals')">3. Financial Goals</li>
                  <li [ngClass]="{'tab-color-change': tab==='my-financial/my-plan'}" (click)="onClick('my-financial/my-plan')">4. My Plan</li>
                </ul>
          <li>
            <a (click)="onClick('my-plan')"   [ngClass]="{'active': tab==='my-plan'}" [routerLink]="['my-activity']">
              <img  class="tick-icon-geojit-financial" src="assets/tabs/report-dis.svg" style="padding: 0px 7px 4px 0px;"/>Report</a>
          </li>
          <li>
            <a (click)="onClick('calendar')"   [ngClass]="{'active': tab==='calendar'}" [routerLink]="['profile']" >
              <img  style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial" src="assets/tabs/calender-dis.svg"/>Calendar</a>
          </li>
  
          <li>
            <a (click)="onClick('calculator')"   [ngClass]="{'active': tab==='calculator'}" [routerLink]="['las']" >
              <img  style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial" src="assets/tabs/calcu.svg"/>Calculator</a>
          </li>
          <li>
            <a    [ngClass]="{'active': tab==='tab5'}" [routerLink]="['help-security']">
              <img   style="padding: 0px 7px 4px 0px;" class="tick-icon-geojit-financial" src="assets/tabs/manual.svg" />User Manual</a>
          </li>
      </ul>   
    </div>
    </div>
   