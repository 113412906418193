



<div class="dashboard-start-geojit-dashboard">
    <!-- section 1 -->
    <!-- <div class="container"> -->
    <div class="row " style=" padding-top: 15px;">
        <div class="col-sm-6 grid-view-geojit-mobile">
            <div
                class="card bg-gradient-danger card-img-holder text-white green-cardprofile-geojit-dashboard grid-margin">
                <div class="row" style="    padding: 24px 0px 0px 0px;">

                    <div class="card-body col-sm-6" style="width: 50%;">
                        <!-- <img src="assets/dash/user-valid.svg" class="card-img-absolute"
                                alt="circle-image" /> -->
                    </div>

                    <div class="card-body col-sm-6 " style="width: 50%;">
                        <h4 class=" mb-3 user-name-geojit-dashboard">Hey,<br> Arun Manoharan
                            <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                        </h4>
                        <h6 class="card-text more-person-geojit-dashboard">For More Personalized Services</h6>
                        <button class="button-upgrade-geojit-dashboard"  data-toggle="modal" data-target="#myModal">Upgrade Now</button>
                        <h6 class="card-text user-valid-geojit-dashboard"><span>Your Plan</span><br>Expires On <span
                                style="color: #000000;font-weight: 600;">27 Dec 2021</span> </h6>

                    </div>
                </div>

            </div>
        </div>
        <div class="col-sm-6 grid-view-geojit-mobile">
            <div
                class="card bg-gradient-danger card-img-holder text-white  grid-margin second-cardprofile-geojit-dashboard">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4 text-aligncenter-geojit-mobile" style="padding-left: 40px;">
                            <div [ngSwitch]="viewModel">
                                <section id="1" *ngSwitchCase="1">
                                    <h6 style="margin-bottom:0px;">GOAL WIDGET MODEL</h6>
                                    <img src="assets/header/photoUrl.png" class="" alt="circle-image" />
                                    <h6 class="card-text" style="font-size: 14px;margin-top: 20px;">Eshana gibin
                                        school/,<br>
                                        college admission</h6>
                                </section>
                                <section id="2" *ngSwitchCase="2">
                                    <h6  style="margin-bottom:0px;">RETIREMENT</h6>
                                    <img src="assets/header/photoUrl.png" class="" alt="circle-image" />
                                    <h6 class="card-text" style="font-size: 14px;margin-top: 20px;">Eshana gibin<br> marriage</h6>
                                </section>
                                <section id="3" *ngSwitchCase="3">
                                    <h6  style="margin-bottom:0px;"> NET WORTH</h6>
                                    <img src="assets/header/photoUrl.png" class="" alt="circle-image" />
                                    <h6 class="card-text" style="font-size: 14px;margin-top: 20px;">Eshana gibin
                                        school/,<br>
                                        college admission</h6>
                                </section>
                               
                            </div>
                        <div style="margin-top: 16px;">
                            <input type="image" name="Name of image button" src="assets/dash/left.svg" alt="text"
                                (click)="stepPrevious(viewModel)">
                            <input type="image" name="Name of image button" src="assets/dash/right.svg" alt="text"
                                style="padding-left: 3px;" (click)="stepNext(viewModel)">
                        </div>
                    </div>
                    <div class="col-sm-8 progressbar-geojit-dashboard">
                        <label>Assigned Portfolio</label>
                        <div class="progress-amount-geojit-dashboard"><span>₹ 12,563</span><span
                                style="float: right;">20.45 %</span></div>
                        <progress class="AssignedPortfolio-geojit-dashboard" id="file" value="20.45" max="100">
                            32%</progress>
                        <label>Increment investment</label>
                        <div class="progress-amount-geojit-dashboard"><span>₹ 11,6378</span><span
                                style="float: right;">51.63 %</span></div>
                        <progress class="Incrementinvestment-geojit-dashboard" id="file" value="51.63" max="100">32%
                        </progress>
                        <label>Achieved</label>
                        <div class="progress-amount-geojit-dashboard"><span>₹ 25,586</span><span
                                style="float: right;">35.84 %</span></div>
                        <progress class="Pending-geojit-dashboard" id="file" value="35.84" max="100"> 32%</progress>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
<!-- section 2-->

<div class="row padding-card-geojit-dashboard" >
    <div class="col-sm-4 grid-view-geojit-mobile">
        <div
            class="card bg-gradient-danger card-img-holder text-white stretch-cardprofile-geojit-dashboard grid-margin">
            <div class="card-body">
                <div class="row ">
                    <h6>RETIREMENT</h6>
                    <div class="col-md-6 for-mobile-view-common">
                        <canvas id="myChart" #retirement style="width: 165px;height: 165px;"></canvas>
                    </div>
                    <div class="col-md-6 chart-label-geojit-dashboard for-mobile-view-common">
                        <ul>
                            <li style='background-image: url("/assets/dash/green.svg");'>₹
                                12,563<br><span>Achieved</span></li>
                            <li style='background-image: url("/assets/dash/pink.svg");'>₹
                                11,587<br><span>Pending</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 grid-view-geojit-mobile">
        <div
            class="card bg-gradient-danger card-img-holder text-white stretch-cardprofile-geojit-dashboard grid-margin">
            <div class="card-body">
                    <div class="row">
                        <h6>NET WORTH UPDATE</h6>

                        <div class="col-md-6 for-mobile-view-common">
                            <canvas id="myChart" #netWorth style="width: 165px;height: 165px;"></canvas>
                        </div>
                        <div class="col-md-6 chart-label-geojit-dashboard for-mobile-view-common">
                            <ul>
                                <li style='background-image: url("/assets/dash/green.svg");'>₹
                                    12,563<br><span>Assets</span></li>
                                <li style='background-image: url("/assets/dash/yellow.svg");'>₹
                                    5,833<br><span>Liabilities</span></li>
                               

                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 grid-view-geojit-mobile">
        <div
            class="card bg-gradient-danger card-img-holder text-white  grid-margin second-cardprofile-geojit-dashboard">
            <div class="card-body">
                <div class="row">

                    <h6>MY PORTFOLIO</h6>

                    <div class="col-md-6 text-aligncenter-geojit-mobile width-canvas">
                        <canvas id="myChart" #portfolio style="width: 165px;height: 165px;"></canvas>
                    </div>
                    <div class="col-md-3 chart-label-geojit-dashboard for-mobile-view-common  padding-geojit-mobile width-canvas"
                        style="padding: 0px;">
                        <ul style="padding: 0px;">
                            <li style='background-image: url("/assets/dash/green.svg");'>₹
                                2586<br><span>Equity</span></li>
                            <li style='background-image: url("/assets/dash/blue.svg");'>₹ 2236<br><span>Mutual
                                    Fund</span></li>
                            <li style='background-image: url("/assets/dash/yellow.svg");'>₹ 853<br><span>Fixed
                                    Deposit</span></li>
                        </ul>
                    </div>
                    <div class="col-md-3 chart-label-geojit-dashboard for-mobile-view-common padding-geojit-mobile width-canvas"
                        style="padding: 0px;">
                        <ul style="padding: 0px;">
                            <li style='background-image: url("/assets/dash/orangel.svg");'>₹
                                232<br><span>Gold</span></li>
                            <li style='background-image: url("/assets/dash/greens.svg");'>₹
                                2146<br><span>Proprety</span></li>
                            <li style='background-image: url("/assets/dash/violet.svg");'>₹ 0<br><span>Others</span>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </div>

</div>
<!-- section 3-->


<!-- </div> -->
</div>


<div class="container">
    <div class="modal" id="myModal">
      <div class="modal-dialog modal-dialog-geojit-about">
        <div class="modal-content" style="height:488px;max-width: 385px;">
          <!-- Modal Header -->
          <div class="modal-header" style="padding: 0px;border: none;height: 16px;">
            <button type="button" class="close" data-dismiss="modal" style="padding-top: 25px;padding-right: 25px;"> 
              <img src="assets/dash/close.svg"></button>
          </div>
          <!-- Modal body -->
          <div class="modal-body modal-manual-geojit-about">
            <img src="assets/dash/upgrade.svg" class="img-mobile-geojit-dashboard">
            <h6 style="padding-top: 12px;">Upgrade Now</h6>
            <ul class="list-upgrade-geojit-dashboard">
                <li>Pass on the knowledge to bots, lead them acquire great skills of Understanding.</li>
                <li>The knowledge to bots, lead them acquire great skills of Understanding.</li>
            </ul>
            <button class="save-btn-geojit-common" style="margin-left: 15px;width: 178px;">Make Payment</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  

        <!-- <ng-template #cont id="cont" let-modal class="renew-modal-geojit-dashboard modal-dialog-geojit-about"> -->
            <ng-template #cont id="cont" let-modal style="width: 392px;border: none;height: 100%; display: flex; top: 5%;align-items: center;text-align: -webkit-center;">
             
                <div  style="text-align: -webkit-center;">
                    <div style="text-align: right;">
                      <button class="btn-close-geojit-modal" aria-hidden="true" style="cursor: pointer;background: none;
                      border: none;outline: none;"
                        (click)="modal.dismiss('Cross click')">
                        <img src="assets/dash/close.svg">
                      </button>
                    </div>
             </div>
                <div class="modal-dialog" style="text-align: -webkit-center;">
                
                <!-- Modal body -->
                <!-- <div class="modal-body modal-manual-geojit-about"> -->
                  <img src="assets/dash/renew.svg" style="width: 195px;height: 214px;" class="renew-img-geojit-dashboard">
                  <h6 class="h6-modal-geojit-dashboard">Renew Your Account</h6>
                 <p class="p-modal-geojit-dashboard">Many desktop publishing packages <br>and web page editors now</p>
                  <button class="save-btn-geojit-common" style="width: 178px;align-self: center;">Make Payment</button>
                <!-- </div> -->
              </div>
          </ng-template>



    