<form >
    <div class="form-row input-box-geojit-common" style="    margin-top: 14px;padding: 20px 20px;">
        
        <div class="form-group col-md-4">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                style="padding: 9px 8px;    height: 42px;">
                <option disabled selected>Select</option>
                <option value="volvo">Single</option>
                <option value="saab">Female</option>
                <option value="mercedes">Other</option>
            </select>
            <label>Select family member</label>
        </div>
        <div class="form-group col-md-4">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
                style="padding: 9px 8px;    height: 42px;" >
                <option disabled selected>Select</option>
                <option value="volvo">Single</option>
                <option value="saab">Female</option>
                <option value="mercedes">Other</option>
            </select>
            <label>Goal type</label>
        </div>
        <div class="form-group col-md-4">
            <input type="date" class="form-control " id="inputEmail4" placeholder=" " >
            <label>Goal Date</label>
        </div>
        <div class="form-group col-md-4">
            <input type="email" class="form-control " id="inputEmail4" placeholder=" "><label>Time(in years)</label>
        </div>
        <div class="form-group col-md-4">
            <input type="email" class="form-control " id="inputEmail4" placeholder=" "><label>Cost(Todays value)</label>
        </div>
        <div class="form-group col-md-4">
            <input type="email" class="form-control " id="inputEmail4" placeholder=" " ><label>Prioriy</label>
        </div>
        <div class="form-group col-md-4">
            <input type="email" class="form-control " id="inputEmail4" placeholder=" " ><label>Remarks</label>
        </div>
        <div class="form-group col-md-8" style="text-align: right;">
            <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev">Back</button>
            <button class="button" type="submit" style="width: 107px;color: #29B473;background: transparent;" (click)="save()">Save</button>
        </div>
    </div>
    <div class="form-row" style="height: auto;">
        <div class="form-group col-md-12" style="padding-right: 25px;padding-left: 25px;">
            <table class="table-common-geojit">
                <tr class="header-table-geojit-common">
                    <th>Name of goal</th>
                    <th>Achieve by date</th>
                    <th>Amount required<br> after allocation</th>
                    <th>Single investment <br> to be done</th>
                    <th>Monthly investment <br> to be done</th>
                  
                    <th></th>
                </tr>
                <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                    <td>Eshana gibin school admission</td>
                    <td>02/05/2014</td>
                    <td>126,654</td>
                    <td>76,365</td>
                    <td>2,013</td>
                    <td>
                        <button class="button-edit-geojit-about" ><img src="assets/about/share.svg" /></button>
                        <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                        <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                    </td>
                </tr>
                <tr style="height: 50px;">
                    <td>Gibin new car</td>
                    <td>02/05/2014</td>
                    <td>126,654</td>
                    <td>76,365</td>
                    <td>2,013</td>
                    <td>
                        <button class="button-edit-geojit-about" ><img src="assets/about/share.svg" /></button>
                        <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                        <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div  class="form-row form-rowbtn-geojit-measure">
        <div class="form-group col-md-12" style="    display: contents;">
            <button class="button mr-2"
                style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;" id="prev"
                (click)="Tabchange('tab2')">Back</button>
            <button class="button" type="submit" style="width: 107px;" (click)="Tabchange('tab4')">Next</button>
        </div>
    </div>
</form>

