<div class="tab-slider--nav">
    <ul class="tab-slider--tabs" style="width: 100%;display: flex;padding: 0px;border-bottom: 1px solid #EFEFEF;
    height: 54px; " >
      <li class="tab-slider--trigger col-md-2"  [class.active]="viewMode == 'tab1'" rel="tab1" (click)="Tabchange('tab1')" >Equity</li>
      <li class="tab-slider--trigger col-md-2"   [class.active]="viewMode == 'tab2'" rel="my-financial"(click)="Tabchange('tab2')">Mutual Fund </li>
      <li class="tab-slider--trigger col-md-2"  rel="tab3" [class.active]="viewMode == 'tab3'" (click)="viewMode ='tab3'">Fixed Deposits</li> 
      <li class="tab-slider--trigger col-md-2"  rel="tab4" [class.active]="viewMode == 'tab4'" (click)="viewMode ='tab4'">Gold</li> 
      <li class="tab-slider--trigger col-md-2"  rel="tab5" [class.active]="viewMode == 'tab5'" (click)="viewMode ='tab5'">Property</li>
      <li class="tab-slider--trigger col-md-2"  rel="tab6" [class.active]="viewMode == 'tab6'" (click)="viewMode ='tab6'">Others</li>
    </ul>
  </div>
  <div [ngSwitch]="viewMode">

    <!-- section tab 1 -->

    <div id="tab1" *ngSwitchCase="'tab1'">

        <form [formGroup]="profileForm" autocomplete="off"> 
            <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
              <div class="form-group col-md-4">
                <input type="email" class="form-control " placeholder=" " formControlName="company"><label>Name of company</label>
              </div>
              <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Number of Shares</label>
              </div>
              <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " formControlName="price"><label>Average Buy Price (Optional)</label>
              </div>
              <div class="form-group col-md-4">
                <input type="date" class="form-control "  placeholder=" " formControlName="date">
                <label>Date of Purchase (Optional)</label>
              </div>
              <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Total Current Value</label>
              </div>
              <div class="form-group col-md-4">
                <input type="email" class="form-control "  placeholder=" " formControlName="price"><label>Purpose of Investment</label>
              </div>
            </div>  
            <div class="form-row" style="justify-content: center;">
              <div class="avatar-edit">
                <input type='file' id="imageUpload"  #fileInput (change)="uploadFile($event)"
                  style="border: none;display: none;"  />
                <button class="button-upload-geojit-about" (click)="fileInput.click()" style="padding-top: 14px;"><img src="assets/money-measures/upload.svg" style="padding-right: 5px;"><span *ngIf="!imageUrl" class="button-upload-geojit-about">Upload Document</span><span *ngIf="imageUrl" class="button-upload-geojit-about">{{imageUrl}}</span><br><span>(Max.size
                    30kb)</span></button>
              </div>
            </div>
            <div class="form-row form-rowbtn-geojit-measure">
              
                <div class="form-group col-md-12" style="    display: contents;">
                  <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                    id="prev">Back</button>
                  <button class="button"  type="submit" style="width: 107px;color: #29B473;background: transparent" (click)="save()">Save</button>
                </div>
              </div>
              <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>
              <div class="form-row margin-table-geojit-common" style="height: auto;">
                <div class="form-group col-md-12" style="padding-right: 25px;padding-left: 25px;">
                  <table class="table-common-geojit">
                    <tr class="header-table-geojit-common" >
                      <th>Name</th>
                      <th>Date</th>
                      <th>Share</th>
                      <th>Average Buy Price</th>
                      <th>Last Closing Price</th>
                      <th>Current Value</th>
                      <th></th>

                    </tr>
                    <tr   style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                      <td>ACC</td>
                      <td>28/07/2021</td>
                      <td>450</td>
                      <td>₹ 11925</td>
                      <td>₹ 2586</td>
                      <td>₹ 8,5638</td>
                      <td>
                        <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                        <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                      </td>

                    </tr>
                    <tr   style="height: 50px;">
                      <td>GB Global Limited</td>
                      <td>28/07/2021</td>
                      <td>450</td>
                      <td>₹ 11925</td>
                      <td>₹ 2586</td>
                      <td>₹ 8,568</td>
                      <td>
                        <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                        <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                      </td>

                    </tr>
                    
                  </table>
                  
                </div>
                
              </div>
          
              <div  class="form-row form-rowbtn-geojit-measure" >
                <div class="form-group col-md-12" style="    display: contents;">
                  <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                    id="prev" (click)="Tabchange('tab1')">Back</button>
                  <button class="button"  type="submit" style="width: 107px;" (click)="Tabchange('tab2')" >Next</button>
                </div>
              </div>
        </form>



      </div>
    <!-- section tab 2 -->

    <div id="tab2" *ngSwitchCase="'tab2'">
      <form [formGroup]="profileForm"> 
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
          <div class="form-group col-md-3">
            <!-- <input type="" class="form-control" id="inputEmail4" placeholder=" ">
            <label>Marital Status</label> -->
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">

              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>AMC</label>
          </div>
          
         
          <div class="form-group col-md-3">
            <!-- <input type="" class="form-control" id="inputEmail4" placeholder=" ">
            <label>Marital Status</label> -->
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Type of Scheme</label>
          </div>
          <div class="form-group col-md-3">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Scheme Category</label>
          </div>
          <div class="form-group col-md-3">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Name of Scheme</label>
          </div>
          <div class="form-group col-md-3">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Mode of Investment</label>
          </div>
          <div class="form-group col-md-3">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Investment frequency</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Amount Invested (Optional)</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Invested Date (Optional)</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Currently Available Units</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Currently Value</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>SIP Amount</label>
          </div>
          <div class="form-group col-md-3">
            <input type="date" class="form-control " placeholder=" " formControlName="date">
            <label>SIP Date</label>
          </div>
        </div>  
        <div class="form-row" style="justify-content: center;">
          <div class="avatar-edit">
            <input type='file' id="imageUpload"  #fileInput (change)="uploadFile($event)"
              style="border: none;display: none;"  />
            <button class="button-upload-geojit-about" (click)="fileInput.click()" style="padding-top: 14px;"><img src="assets/money-measures/upload.svg" style="padding-right: 5px;">Upload Document<br><span>(Max.size
                30kb)</span></button>
          </div>
        </div>
        <div class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev" >Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="save()">Save</button>
            </div>
          </div>
          <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>
          <div class="form-row margin-table-geojit-common" style=" height: auto;">
            <div class="form-group col-md-12" style="    padding-right: 25px;
            padding-left: 25px;">
              <table class="table-common-geojit">
                <tr class="header-table-geojit-common" >
                  <th>Name of Scheme</th>
                  <th>Invested Date</th>
                  <th>Amount Invested</th>
                  <th>Avail. Units</th>
                  <th>Current NAV</th>
                  <th>Current Value</th>
                  <th></th>

                </tr>
                <tr   style="height: 50px;">
                  <td>HDFC Balanced Fund - Regular Plan - IDCW</td>
                  <td>28/07/2021</td>
                  <td>₹45036</td>
                  <td>11925</td>
                  <td>25.86</td>
                  <td>₹ 85632</td>
                  <td>
                    <button class="button-edit-geojit-about" ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                
              </table>
              
            </div>
            
          </div>
      
          <div  class="form-row form-rowbtn-geojit-measure">
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev" (click)="Tabchange('tab1')">Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="Tabchange('tab3')">Next</button>
            </div>
          </div>
    </form>

    </div>
        <!-- section tab 3-->

    <div id="tab3" *ngSwitchCase="'tab3'">
      <form [formGroup]="profileForm"> 
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
         
          
         
          <div class="form-group col-md-3">
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option disabled selected>Select</option>
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Investment Type</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Name of Deposit</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control " placeholder=" " formControlName="shares"><label>Amount Invested</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Rate of Interest</label>
          </div>

          <div class="form-group col-md-3">
            <input type="date" class="form-control "  placeholder=" " formControlName="date">
            <label>Date of Investment</label>
          </div>
         
        <div class="form-group col-md-3">
          <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
            style="padding: 9px 8px;    height: 42px;">

            <option disabled selected>Select</option>
            <option value="volvo">Single</option>
            <option value="saab">Female</option>
            <option value="mercedes">Other</option>
          </select>
          <label>Interest Calculation</label>
        </div>
        <div class="form-group col-md-3">
          <input type="date" class="form-control "  placeholder=" " formControlName="date">
          <label>Maturity Date</label>
        </div>
        <div class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Bank NBFC Name</label>
        </div>
        <div class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Name of Account Holder</label>
        </div>
        <div class="form-group col-md-3">
          <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Maturity Value</label>
        </div>
       
        </div>
        <div class="form-row" style="justify-content: center;">
          <div class="avatar-edit">
            <input type='file' id="imageUpload"  #fileInput (change)="uploadFile($event)"
              style="border: none;display: none;"  />
            <button class="button-upload-geojit-about" (click)="fileInput.click()" style="padding-top: 14px;"><img src="assets/money-measures/upload.svg" style="padding-right: 5px;">Upload Document<br><span>(Max.size
                30kb)</span></button>
          </div>
        </div>
        <div class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2 btn-backwidth-geojit-investment" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev">Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="save()">Save</button>
            </div>
          </div>
          <div class="total-value-geojit-common">Grand Total Value :<span>₹ 119,930</span></div>
          <div class="form-row margin-table-geojit-common" style=" height: auto;">
            <div class="form-group col-md-12" style="    padding-right: 25px;
            padding-left: 25px;">
              <table class="table-common-geojit">
                <tr class="header-table-geojit-common" >
                  <th>Name of Deposit</th>
                  <th>Date of Investment</th>
                  <th>Amount Invested</th>
                  <th>Rate of Interest</th>
                  <th>Interest Calculation</th>
                  <th>Today's Value</th>
                  <th></th>

                </tr>
                <tr   style="height: 50px;">
                  <td>Fedral Bank</td>
                  <td>28/07/2021</td>
                  <td>₹45036</td>
                  <td>9</td>
                  <td>Yearly</td>
                  <td>8,56,328</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                
              </table>
              
            </div>
            
          </div>
      
          <div  class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev" (click)="Tabchange('tab2')">Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="Tabchange('tab4')">Next</button>
            </div>
          </div>
    </form>
    </div>
        <!-- section tab 4 -->

    <div id="tab4" *ngSwitchCase="'tab4'">
      <form [formGroup]="profileForm"> 
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
          <div class="form-group col-md-3">
            <!-- <input type="" class="form-control" id="inputEmail4" placeholder=" ">
            <label>Marital Status</label> -->
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Investment Type</label>
          </div>
          
         
          <div class="form-group col-md-3">
            <!-- <input type="" class="form-control" id="inputEmail4" placeholder=" ">
            <label>Marital Status</label> -->
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Scheme Name</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Quantity (Gram/No.Units)</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control " placeholder=" " formControlName="shares"><label>Purchase Date (Optional))</label>
          </div>
          <div class="form-group col-md-3">
            <input type="date" class="form-control "  placeholder=" " formControlName="date">
            <label>Purchase Rate/Gram (Optional)</label>
          </div>
          <div class="form-group col-md-3">
            <input type="date" class="form-control "  placeholder=" " formControlName="date">
            <label>Maturity Date</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Current Value</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Current Rate/Gram/NAV</label>
          </div>
        </div>  


        <div class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <div class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;padding: 7px 4px 4px 45px;"
                id="prev">Back</div>
              <button class="button"  type="submit" style="width: 107px;" (click)="save()">Save</button>
            </div>
          </div>
          <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>

          <div class="form-row margin-table-geojit-common" style="    height: auto;">
            <div class="form-group col-md-12" style="    padding-right: 25px;
            padding-left: 25px;">
              <table class="table-common-geojit">
                <tr class="header-table-geojit-common" >
                  <th>Name</th>
                  <th>Date</th>
                  <th>Share</th>
                  <th>Average Buy Price</th>
                  <th>Last Closing Price</th>
                  <th>Current Value</th>
                  <th></th>

                </tr>
                <tr   style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                  <td>Physical Gold</td>
                  <td>28/07/2021</td>
                  <td>₹0</td>
                  <td>9</td>
                  <td>₹40.32</td>
                  <td>8,56,328</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                <tr   style="height: 50px;">
                  <td>Gold ETF & Fund</td>
                  <td>28/07/2021</td>
                  <td>₹0</td>
                  <td>9</td>
                  <td>₹40.32</td>
                  <td>8,56,328</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                
              </table>
              
            </div>
            
          </div>
      
          <div  class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev" (click)="Tabchange('tab3')">Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="Tabchange('tab5')">Next</button>
            </div>
          </div>
    </form>

    </div>
        <!-- section tab 5 -->

    <div id="tab5" *ngSwitchCase="'tab5'">
      <form [formGroup]="profileForm"> 
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
          
          
         
          <div class="form-group col-md-3">
            <!-- <input type="" class="form-control" id="inputEmail4" placeholder=" ">
            <label>Marital Status</label> -->
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Type of Property</label>
          </div>
          <div class="form-group col-md-3">
            <!-- <input type="" class="form-control" id="inputEmail4" placeholder=" ">
            <label>Marital Status</label> -->
            <select class="form-control dropdown-input-geojit-about" name="cars" id="cars" placeholder=" "
              style="padding: 9px 8px;    height: 42px;">
              <option value="volvo">Single</option>
              <option value="saab">Female</option>
              <option value="mercedes">Other</option>
            </select>
            <label>Area In</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control " placeholder=" " formControlName="shares"><label>Discription</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Current Value</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Total Area</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Current Rate per area</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Percentage of Ownership</label>
          </div>
          <div class="form-group col-md-3">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Location</label>
          </div>
        </div>  


        <div class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <div class="button mr-2 bu" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;padding: 7px 4px 4px 45px;"
                id="prev">Back</div>
              <button class="button"  type="submit" style="width: 107px;" (click)="save()">Save</button>
            </div>
          </div>
          <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>

          <div class="form-row margin-table-geojit-common" style="    height: auto;">
            <div class="form-group col-md-12" style="    padding-right: 25px;
            padding-left: 25px;">
              <table class="table-common-geojit">
                <tr class="header-table-geojit-common" >
                  <th>Type of Property</th>
                  <th>Location</th>
                  <th>Area In</th>
                  <th>Total Area</th>
                  <th>Current Market Rate/unit</th>
                  <th>Current Value</th>
                  <th></th>

                </tr>
                <tr  style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                  <td>House</td>
                  <td>Thodupuza</td>
                  <td>Square Feet</td>
                  <td>65</td>
                  <td>4036</td>
                  <td>8,56,328</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                <tr  style="height: 50px;">
                  <td>Land</td>
                  <td>Muthalakoodam</td>
                  <td>Square Feet</td>
                  <td>65</td>
                  <td>4036</td>
                  <td>8,56,328</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                
              </table>
              
            </div>
            
          </div>
      
          <div  class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev" (click)="Tabchange('tab4')">Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="Tabchange('tab6')">Next</button>
            </div>
          </div>
    </form>
     </div>
             <!-- section tab 6 -->

     <div id="tab6" *ngSwitchCase="'tab6'">
      <form [formGroup]="profileForm"> 
        <div class="form-row input-box-geojit-common" style="    margin-top: 22px;padding: 6px 20px;">
          
          
         
          <div class="form-group col-md-4">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Name of Investment</label>
          </div>
          <div class="form-group col-md-4">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Amount Invested</label>
          </div>
          <div class="form-group col-md-4">
            <input type="date" class="form-control "  placeholder=" " formControlName="date">
            <label>Date of Investment</label>
          </div>
          <div class="form-group col-md-4">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Current Value</label>
          </div>
          <div class="form-group col-md-4">
            <input type="email" class="form-control "  placeholder=" " formControlName="shares"><label>Date of Maturity (if any)</label>
          </div>
        </div>  


        <div class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <button class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;"
                id="prev">Back</button>
              <button class="button"  type="submit" style="width: 107px;" (click)="save()">Save</button>
            </div>
          </div>
          <div class="total-value-geojit-common">Total Value :<span>₹ 119,930</span></div>

          <div class="form-row margin-table-geojit-common"  style="    height: auto;">
            <div class="form-group col-md-12" style="    padding-right: 25px;
            padding-left: 25px;">
              <table class="table-common-geojit">
                <tr class="header-table-geojit-common" >
                  <th>Name of Investment</th>
                  <th>Date of Investment</th>
                  <th>Amount Invested</th>
                  <th>Current Value</th>
                  <th>Maturity Date</th>
                  <th></th>

                </tr>
                <tr  style="height: 50px;border-bottom: 1px solid #F6F8FA;">
                  <td>Health Insurance</td>
                  <td>02/09/2021</td>
                  <td>10230</td>
                  <td>65</td>
                  <td>07/09/2021</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>

                </tr>
                <tr  style="height: 50px;">
                  <td>Chitti</td>
                  <td>02/09/2021</td>
                  <td>10230</td>
                  <td>65</td>
                  <td>07/09/2021</td>
                  <td>
                    <button class="button-edit-geojit-about"  ><img src="assets/about/edit.svg" /></button>
                    <button class="button-edit-geojit-about" ><img src="assets/about/delete.svg" /></button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="form-row form-rowbtn-geojit-measure" >
            <div class="form-group col-md-12" style="    display: contents;">
              <div class="button mr-2" style="width: 107px;border: 1px solid #000000;background: transparent;color: #000000;padding: 7px 4px 4px 45px;"
                id="prev" (click)="Tabchange('tab5')">Back</div>
              <button class="button"  type="submit" style="width: 107px;" >Next</button>
            </div>
          </div>
    </form>
     </div>
  </div>
