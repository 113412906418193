<div style="background: #f5f4f7;">

<div class="section-financial-geojit-myplan">
    <div class="export-geojit-myplan">
        <div class="row">
            <div class="col-sm-6 ">
                <h6>Financial Plan Report</h6>
            </div>
            <div class="col-sm-6 export-btn-geojit-myplan" style="text-align: right;">
                <button class="button btn-back-geojit-common mr-2"  id="prev">Export to
                    PDF</button>
                <button class="button" type="submit" (click)="activateTab('tabactive')" data-toggle="modal" data-target="#invest">Proceed to invest</button>
            </div>
        </div>
    </div>
    <div class="dear-text-geojit-myplan">
        <h6>Dear Mr. Gibin John,</h6>
        <p>We at Geojit are happy to present your Personal Financial Plan.
            The plan has evolved out of the information provided by you in the input
            section as well as on certain assumption regarding future interest rate and inflation expectations.
            However, the accuracy of results solely depends upon the actual performance of the invested portfolios and
            future rate movements.
            Hence frequent review of the goals and investments is important in keeping an accord between the planned
            figures and the actual yield .
            The inputs in the plan (except risk profiling) are editable any time according to changes in life scenarios
            and a
            fresh plan could be generated any time. But it would be wise to keep a copy of the old plan filed for future
            perusal.
            Also it is not wise to replan more than twice in a year.</p>
    </div>
</div>
<div class="summary-analysis-geojit-myplan">
    <div class="row">
        <div class="col-md-7">
            <h6>Analysis Summary</h6>
            <div class="row">
                <div class="col-sm-4" style="display: grid;"><label>Risk Profile</label>
                    <span>Moderate</span>
                </div>
                <div class="col-sm-4" style="display: grid;"><label>Coverage Required</label>
                    <span>₹ 4,454,361.00</span>
                </div>
                <div class="col-sm-4" style="display: grid;"><label>Avail. Life Insurance Coverage</label>
                    <span>₹ 0.00</span>
                </div>
            </div>
            <div class="row" style="padding-top: 30px;">
                <div class="col-sm-8" style="display: grid;"><label>Recommended Portfolio</label>
                    <span>Equity Related 40% Fixed Income 50% Gold 10%</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <h6>Personal Profile</h6>
            <div class="row">
                <div class="col-sm-5" style="display: grid;"><label>Name</label>
                    <span>AHRPJ7658K</span>
                </div>
                <div class="col-sm-5" style="display: grid;"><label>PAN</label>
                    <span>₹ 4,454,361.00</span>
                </div>
            </div>
            <div class="row" style="padding-top: 30px;">
                <div class="col-sm-5" style="display: grid;"><label>Date Of Birth</label>
                    <span>23/07/1984</span>
                </div>
                <div class="col-sm-5" style="display: grid;"><label>Email</label>
                    <span>gibinjohn@ymail.com</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding-top: 40px;">
        <div class="col-md-7">
            <h6>Your current Financial Situation</h6>
            <div class="row">
                <div class="col-sm-3" style="display: grid;"><label>Assets</label>
                    <span>₹ 1,856,732</span>
                </div>
                <div class="col-sm-3" style="display: grid;"><label>Liabilities</label>
                    <span>₹ 50,000</span>
                </div>
                <div class="col-sm-4" style="display: grid;"><label>Net Worth</label>
                    <span>₹ 1,856,732</span>
                </div>
            </div>
        </div>
    </div>
    <table class="table-common-geojit table-myplan-geojit-myplan" >
        <tr class="header-table-geojit-common">
            <th>Family Members</th>
            <th>Date Of Birth</th>
            <th>Goals Defined</th>
        </tr>
        <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
            <td><img src="assets/my-plans/p1.png" class="profile-image-geojit-myplan"><span class="username-tableanalysis-geojit-myplan">Gibin</span></td>
            <td>23/07/1984</td>
            <td>Retirement,Car,Car</td>
        </tr>
        <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
            <td><img src="assets/my-plans/p2.png" class="profile-image-geojit-myplan"><span class="username-tableanalysis-geojit-myplan">Reshmi</span>
            </td>
            <td>23/07/1984</td>
            <td>Retirement,Car,Car</td>
        </tr>
        <tr style="height: 50px;border-bottom: 1px solid #F6F8FA;">
            <td><img src="assets/my-plans/p3.png" class="profile-image-geojit-myplan"><span class="username-tableanalysis-geojit-myplan">Eshana</span></td>
            <td>23/07/1984</td>
            <td>School/College admission,Higher education,Marriage</td>
        </tr>

    </table>
</div>

<div class="RiskProfiling-financial-geojit-myplan">
    <div class="risk-profile-geojit-myplan">
        <div class="row">
            <div class="col-sm-6 ">
                <h6 style="font-size: 15px;">Risk Profiling</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6" style="display: grid;">
                <div class="col-sm-12">
                    <rg-gauge-chart [canvasWidth]="canvasWidth"   [needleValue]="needleValue" [options]="options"
                        [name]="name2" class="gauge-chart-geojit-modal gauge-web-geojit-modal">
                    </rg-gauge-chart>
                    <rg-gauge-chart [canvasWidth]="canvasWidth2" [needleValue]="needleValue" [options]="options"
                    [name]="name2" class="gauge-chart-geojit-modal  gauge-mobile-geojit-modal">
                </rg-gauge-chart>
                </div>
                <span class="moderate-text-geojit-myplan">Moderate</span>
                <div class="col-sm-12" style="padding-top: 131px;padding-left: 0px;">
                    <div class="risk-head-geojit-plan">What is risk profile?
                        <p>Risk profiling is a process of determining the degree of risk an investor can <br>withstand.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" style="display: grid;padding-top: 25px;">
                <div class="col-sm-12" style="padding-left: 0px;">
                    <div class="risk-head-geojit-plan">How we determine your risk profile?
                        <p>It depends on the score you obtain from the questionnaire. The questions<br>
                            are designed in such a way the result is devoid of error. Investment<br>
                            portfolio and asset allocation recommendations is based on your risk profile.</p>
                    </div>
                </div>
                <div class="col-sm-12" style="padding-left: 0px;">
                    <div class="risk-head-geojit-plan">Future Amendments
                        <p>Risk profile is subject to change due to life events and life stages. Any<br> change in these
                            may be intimated through customer care service and upon<br> expert scrutiny, required action
                            will be taken.</p>
                        <p>A Moderate investor values reducing risks and enhancing returns equally.<br> A Moderate
                            investor may endure a short-term loss of principal and lower<br> degree of liquidity in
                            exchange for long-term appreciation.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="risk-head-geojit-plan">Why should we determine Risk profile?
                    <p>Different investments carry different degrees of risk to capital invested.
                        No investment is completely safe or without risk of capital erosion.
                        Even if you hold cash with you, that is subject to erosion due to inflation.
                        Before a person decides to invest for future needs, it is important to find what percentage of
                        his
                        investment has to be channelized to that investment. Thus risk profiling exercise is been
                        identified
                        as the most significant step in financial planning.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="assumptions-financial-geojit-myplan">
    <div class="risk-profile-geojit-myplan">
        <div class="row">
            <div class="col-sm-6 ">
                <h6 style="font-size: 15px;">Assumptions made on Variables</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6" style="display: grid;">
                <div class="col-sm-12" style="padding-left: 0px;    padding-top: 25px;">
                    <div class="risk-head-geojit-plan">
                        <p>While creating your financial plan we have based our calculation on certain assumptions.
                            However you have the options to assume rates of your own.
                            The financial plan and the investment requirements are based on your present financial
                            condition.
                            Following are the rates taken:-
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 table-padding-geojit-myplan" style="padding-left: 0px;">
                    <ul style="padding-left:0px">
                        <li>
                            These Default Return Are Based On Historical Average
                        </li>
                        <li>
                            Any Change In These Return Will Be Updated From Time To Time In The<br> Online Module
                        </li>
                        <li>
                            You Can Change The Return Using The Sliders To View The Changes. <br>You Can Set Different
                            Rates For Each Goal.
                        </li>
                    </ul>
                    <div class="text-recommended-geojit-plan">The recommended asset allocation for a MODERATE risk
                        profile investor is</div>
                    <table class="table-common-geojit table-myplan-geojit-myplan" style="margin-top: 19px;">
                        <tr class="header-table-geojit-common">
                            <th>Allocation Type</th>
                            <th>Equity Related</th>
                            <th>Fixed Income</th>
                            <th>Gold</th>
                        </tr>
                        <tr class="allocation-table-geojit-plan" style="border-bottom: none;">
                            <td>Standard Allocation</td>
                            <td>40</td>
                            <td>50</td>
                            <td>10</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-sm-6" style="display: grid;padding-top: 25px;">
                <div class="col-sm-10 table-padding-geojit-myplan" style="padding-left: 0px;">
                    <table class="table-common-geojit table-myplan-geojit-myplan">
                        <tr class="header-table-geojit-common">
                            <th>Particulars</th>
                            <th>Standard Expected Return</th>
                        </tr>
                        <tr class="allocation-table-geojit-plan">
                            <td>Equity Related</td>
                            <td>12</td>
                        </tr>
                        <tr class="allocation-table-geojit-plan">
                            <td>Fixed Income</td>
                            <td>7</td>
                        </tr>
                        <tr class="allocation-table-geojit-plan">
                            <td>Gold</td>
                            <td>5</td>
                        </tr>
                        <tr class="allocation-table-geojit-plan">
                            <td>Gold</td>
                            <td>6</td>
                        </tr>
                    </table>
                </div>
                <div class="col-sm-12" style="padding-left: 0px;">
                    <div class="risk-head-geojit-plan" style="padding-top: 14px;">
                        <p>The average return of the portfolio, if invested in this fashion is 8.8% .
                            All the monthly as well as single investment amount required to achieve
                            the goal is calculated based on this rate.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px;">

            </div>
        </div>
    </div>
</div>


<div class="weighted-average-geojit-myplan">

    <div class="row">
        <div class="col-sm-6 weighted-display-geojit-myplan">
            <div class="col-sm-11 table-padding-geojit-myplan" style="padding-left: 0px;    padding-top: 25px;">
                <div class="risk-head-geojit-plan">
                    <h6>What is Weighted Average Return?</h6>
                    <p>If a person invests 60% of his money in an instrument yielding
                        10% and 40% in an instrument yielding 12%,
                        then the weighted return is calculated as 60x0.10+40x0.12 = 10.8%
                    </p>
                    <p>The following chart will help you to know your spending pattern.</p>
                    <h6>Expense Chart</h6>
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                        [legend]="barChartLegend" [chartType]="barChartType">
                    </canvas>
                    <p>It is advisable to have separate emergency fund with enough money to cover about three months of
                        expenses.</p>
                    <h6>Net Worth Analysis</h6>
                    <p>The following table shows your financial position as on date.</p>
                    <table class="table-common-geojit table-myplan-geojit-myplan" style="margin-top: 19px;">
                        <tr class="header-table-geojit-common">
                            <th>Assets</th>
                            <th></th>
                            <th>Amount</th>
                        </tr>
                        <tr class="networth-table-geojit-plan">
                            <td style="font-weight: 600;"> Near Cash Assets</td>
                            <td>Cash in hand<br> SB Account<br> Liquid Funds</td>
                            <td>50,000</td>
                        </tr>
                        <tr class="networth-table-geojit-plan">
                            <td style="font-weight: 600;">Invested Assets</td>
                            <td>Stocks<br> MF <br>Fixed Deposit<br> Gold ETF/Funds<br> Insurance Corpus</td>
                            <td>1,523,419</td>
                        </tr>
                        <tr class="networth-table-geojit-plan">
                            <td style="font-weight: 600;">Personal Assets</td>
                            <td>Property<br> Physical Gold<br> Home Contents<br> Others</td>
                            <td>3,061</td>
                        </tr>
                        <tr class="networth-table-geojit-plan">
                            <td style="font-weight: 600;">Deferred Assets</td>
                            <td>PPF<br> EPF<br> Gratuity</td>
                            <td>270,000</td>
                        </tr>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th style="font-size: 16px;" id="total">Total</th>
                                <td style="font-weight: 600;font-size: 16px;">1,846,480</td>
                            </tr>
                        </tfoot>
                    </table>


                </div>
            </div>

        </div>

        <div class="col-sm-6" style="display: grid;padding-top: 25px;">
            <div class="col-sm-12 table-padding-geojit-myplan" style="padding-left: 0px;">
                <div class="risk-head-geojit-plan">
                    <h6>Asset Allocation</h6>
                    <p>Asset allocation is the percentage of funds distributed among different assets.
                        The following pie charts depicts the overall allocation,
                        Financial asset allocation and our recommended allocation for you.
                    </p>
                    <h6 style="padding-top: 36px;">Surplus Chart</h6>
                    <div class="row">
                        <div class="col-sm-5"><canvas id="myChart" #retirement
                                style="width: 165px;height: 165px;"></canvas></div>
                        <div class="col-sm-6 chart-label-geojit-dashboard for-mobile-view-common"
                            style="align-self: center;">
                            <ul class="text-legends-geojit-plan">
                                <li style='background-image: url("/assets/dash/green.svg");'>Expense</li>
                                <li style='background-image: url("/assets/dash/yellow.svg");'>Surplus</li>
                            </ul>
                        </div>
                    </div>
                    <table class="table-common-geojit liabilities-table-geojit-myplan table-myplan-geojit-myplan" >
                        <tr class="header-table-geojit-common">
                            <th>Liabilities</th>
                            <th></th>
                            <th>Amount</th>
                        </tr>
                        <tr class="networth-table-geojit-plan">
                            <td style="font-weight: 600;">Short term</td>
                            <td>Credit Card dues<br> Bills unpaid due<br> Purchase loan Personal Borrowings<br> Gold
                                loan</td>
                            <td>50,000</td>
                        </tr>
                        <tr class="networth-table-geojit-plan">
                            <td style="font-weight: 600;">Long Term</td>
                            <td>Home Loan<br> Personal Loan<br> Business Loan<br> Educational Loan<br> Vehicle Loan<br>
                                Loan Against Property<br> Others</td>
                            <td>0</td>
                        </tr>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th style="font-size: 16px;" id="total">Total</th>
                                <td style="font-weight: 600;font-size: 16px;">50,000</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
        </div>

        <div >
            <div class="col-sm-12 net-worth-geojit-myplan" style="padding-left: 0px;">
                    <div>Net Worth :<span>1,796,480</span> </div>
            </div>
        </div>
        <div >
            <div class="col-sm-12 assets-text-geojit-plan" style="padding-left: 0px;">
                   <div class="row">
                       <div class="col-sm-6">
                           <p>The above table shows that you are net positive in terms of your worth. An<br>
                             excess of Liabilities over assets is never admissible. We will ascertain your<br>
                              financial wellness in terms of your net worth position at a later part of this <br>
                              plan. It is wise to have loans towards asset building investments such as a<br> 
                              home. A home will not only become an asset in future but also serves to plan<br>
                               taxes efficiently. On the other hand a higher outstanding towards short term<br>
                                as well as long term liabilities which are depreciating or avoidable can be<br>
                                 problematic to the financial well being. Refer to Financial wellness Check to<br>
                                  understand your financial position better.</p>
                       </div>
                       <div class="col-sm-6 myChart-padding-geojit-myplan" >
                        <div class="row">
                            <div class="col-sm-4"><canvas id="myChart" #AssetWatch
                                    style="width: 165px;height: 165px;"></canvas></div>
                            <div class="col-sm-4 chart-label-geojit-dashboard for-mobile-view-common"
                                style="align-self: center;">
                                <ul class="text-legends-geojit-plan">
                                    <li style='background-image: url("/assets/dash/green.svg");'>Near Cash Assets</li>
                                    <li style='background-image: url("/assets/dash/yellow.svg");'>Personal Assets</li>
                                </ul>
                            </div>
                            <div class="col-sm-4 chart-label-geojit-dashboard for-mobile-view-common"
                                style="align-self: center;">
                                <ul class="text-legends-geojit-plan">
                                    <li style='background-image: url("/assets/dash/green.svg");'>Invested Assets</li>
                                    <li style='background-image: url("/assets/dash/yellow.svg");'>Deferred Assets</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4"><canvas id="myChart" #LiabilityChart
                                    style="width: 165px;height: 165px;"></canvas></div>
                            <div class="col-sm-4 chart-label-geojit-dashboard for-mobile-view-common"
                                style="align-self: center;">
                                <ul class="text-legends-geojit-plan">
                                    <li style='background-image: url("/assets/dash/green.svg");'>Near Cash Assets</li>
                                    <li style='background-image: url("/assets/dash/yellow.svg");'>Personal Assets</li>
                                </ul>
                            </div>
                            <div class="col-sm-4 chart-label-geojit-dashboard for-mobile-view-common"
                                style="align-self: center;">
                                <ul class="text-legends-geojit-plan">
                                    <li style='background-image: url("/assets/dash/green.svg");'>Invested Assets</li>
                                    <li style='background-image: url("/assets/dash/yellow.svg");'>Deferred Assets</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   </div>
            </div>
        </div>
        </div>
    </div>

    <div class="goalplan-financial-geojit-myplan">
        <div class="risk-profile-geojit-myplan">
            <div class="row">
                <div class="col-sm-6 ">
                    <h6 style="font-size: 15px;">Goal Plan</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6" style="display: grid;">
                    <div class="col-sm-12" style="padding-left: 0px;padding-top: 25px;">
                        <div class="risk-head-geojit-plan">
                            <p>All goals are defined in terms of today's cost.There is a provision to assign the
                                 existing investments as well as insurance schemes to each goal. The excess
                                  amount required after all assignments is calculated. The single as well as
                                   monthly investment required to meet this amount is found out. After the
                                    accomplishment of the goal, the corresponding funds could be withdrawn and 
                                    the goal can be deleted from the list. When you delete the goal, the 
                                    investments assigned to that goal also can be deleted.</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;padding-top: 25px;">
                        <ul style="padding-left:0px">
                            <li>
                                Each goal could be set with different expected rates based on tenure of the
                                 goal.For example, assume lower rates for equity related for short term
                                  goals and higher rates for long term goals.
                            </li>
                           
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6" style="display: grid;padding-top: 25px;">
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <h4>Few points to be noted:</h4>
                       <ul style="padding-left:0px">
                        <li>
                            If you achieve the goal amount ahead of scheduled time, you may either continue
                             with the same investments or else withdraw the funds and park the same in safer
                              investments like liquid funds.
                        </li>
                        <li>
                            You can watch the goal value treading towards the target with the help of goal
                             meter on the dashboard.
                        </li>
                        <li>
                            Any future investments could be assigned to any of the goals by entering the
                             same in "My Investments" tab and editing the goal to assign the new investment
                              to the goal.
                        </li>
                    <li>There are three parts to the Goal meter. The assigned portfolio, the incremental
                         investment portfolio and the amount to be achieved. The value of all assigned
                          investments will get auto updated.</li>
                    </ul>
                    </div>
                    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div><h6 style="font-size: 15px;">Goals In Details</h6>
                        <table class="table-common-geojit" >
                            <tr class="header-table-geojit-common" style="height: 71px;">
                                <th>Name Of Goal</th>
                                <th>Eshana Gibin School/<br>College Admission</th>
                                <th>Eshana Gibin<br> Higher Education</th>
                                <th>Retirement<br> Plan</th>
                                <th>Gibin Car</th>
                                <th>Eshana<br> Marriage</th>
                            </tr>
                            <tr class="goaldetails-table-geojit-plan" *ngFor="let goal of goals" >
                                <td >{{goal.goal}}</td>
                                <td>{{goal.admission}}</td>
                                <td>{{goal.education}}</td>
                                <td>{{goal.retirement}}</td>
                                <td>{{goal.car}}</td>
                                <td>{{goal.marriage}}</td>
                            </tr>    
                        </table>
                    </div>
                    <div><h6 style="font-size: 15px;">Allocated Investments</h6>
                        <table class="table-common-geojit" >
                            <tr class="header-table-geojit-common" style="height: 50px;">
                                <th>Name Of Goal</th>
                                <th>Name Of The Investment</th>
                                <th>Investment Type</th>
                                <th>Total Value / Units</th>
                                <th>Allocated To This Goal</th>
                            </tr>
                            <tr class="goaldetails-table-geojit-plan" >
                                <td >ESHANA GIBIN SCHOOL/COLLEGE ADMISSION</td>
                                <td>ACC</td>
                                <td>EQUITY</td>
                                <td>450</td>
                                <td>50</td>
                            </tr> 
                            <tr class="goaldetails-table-geojit-plan" style="border-bottom: none;">
                                <td >ESHANA MARRIAGE</td>
                                <td>FEDRAL BANK</td>
                                <td>FD</td>
                                <td>105,000</td>
                                <td>105,000</td>
                            </tr>   
                            <tr class="goaldetails-table-geojit-plan" style="border-bottom: none;">
                                <td ></td>
                                <td>PHYSICAL GOLD</td>
                                <td>GOLD</td>
                                <td>50</td>
                                <td>50</td>
                            </tr>  
                            <tr class="goaldetails-table-geojit-plan" style="border-bottom: none;">
                                <td ></td>
                                <td>LIC</td>
                                <td>INSURANCE</td>
                                <td>200,000</td>
                                <td>200,000</td>
                            </tr> 
                            <tr class="goaldetails-table-geojit-plan" style="border-bottom: none;">
                                <td ></td>
                                <td>EPF BALANCE</td>
                                <td>PF</td>
                                <td>250,000</td>
                                <td>250,000</td>
                            </tr>             
                        </table>
                    </div>
                    <h6 style="font-size: 15px;">Retirement Analysis Details</h6>
                    <div class="row">
                        <div class="col-sm-6" style="display: grid;">
                            <div class="col-sm-12 table-padding-geojit-myplan" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <table class="table-common-geojit table-myplan-geojit-myplan" >
                                        <tr class="header-table-geojit-common" style="height: 50px;">
                                            <th>Particulars</th>
                                            <th>Amount</th>      
                                        </tr>
                                        <tr class="goaldetails-table-geojit-plan"  >
                                            <td >Current Monthly expenses</td>
                                            <td>15,000</td>
                                        </tr> 
                                        <tr class="goaldetails-table-geojit-plan"  >
                                            <td >Monthly expenses at the time of retirement (Inflated at 6%)</td>
                                            <td>63,526</td>
                                        </tr>  
                                        <tr class="goaldetails-table-geojit-plan"  >
                                            <td >Monthly expenses at the time of retirement (Inflated at 6%)</td>
                                            <td>11,287,165</td>
                                        </tr>     
                                    </table>
                                </div>
                            </div>
                            <div class="col-sm-12" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <h4>Step 1</h4>
                                    <p>Finding the inflated value of current expenses at your retirement age. With cost of living increasing every year by certain percentage, the cost of monthly expense is found out as a compound interest function of interest and today's living expenses.</p>
                                </div>
                            </div>
                            <div class="col-sm-12" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <h4>Now what is this real rate of return?</h4>
                                    <p>Currently during our investment stage, the money that we invest is taken from our monthly income after our expenses are met. Hence the investment amount is not subject to inflation. But after you retire, the monthly withdrawals is done from accumulated corpus which as a whole is subject to inflation. Even if we invest this accumulated corpus in a safe fixed return investment, the return obtained will be eroded by inflation. The net interest rate obtained from the fixed return investment, after deducting the effect of inflation is called the real rate</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" style="display: grid;">
                            <div class="col-sm-12" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <h4>a) Retirement Report</h4>
                                    <p>On the day you retire, you will require ₹11,287,165 to sustain your lifestyle and meet expenses during your retirement life,considering the monthly expenses of ₹15,000 which will become ₹63,526 at a rate of inflation of 6% after 25 years, the monthly investment required to create the corpus of ₹11,287,165 is ₹8,163 or the lump sum investment to be made today to achieve this corpus is ₹870,101</p>
                                </div>
                            </div>
                            <div class="col-sm-12" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <h4>b) How calculation is done</h4>
                                    <p>The retirement planning mathematics is carried out in three phases.</p>
                                </div>
                            </div>
                            <div class="col-sm-12" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <h4>Step 2</h4>
                                    <p>Finding the corpus required to obtain this inflated cost every month after retirement by discounting the these future cash flows at real rate of return.</p>
                                </div>
                            </div>
                            <div class="col-sm-12" style="padding-left: 0px;">
                                <div class="risk-head-geojit-plan">
                                    <h4>Step 3</h4>
                                    <p>Now that we have found out the corpus required to fund the retirement expenses, the final step is to find out the monthly investment amount or the Lump sum investment amount that to be started today to reach the required corpus on the day of retirement</p>
                                    <p>It is advised to consider retirement as a significant life goal and invest diligently towards it</p>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="insurance-financial-geojit-myplan">
        <div class="risk-profile-geojit-myplan">
            <div class="row">
                <div class="col-sm-6 ">
                    <h6 style="font-size: 15px;">Insurance Planning</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6" style="display: grid;">
                    <div class="col-sm-12" style="padding-left: 0px;    padding-top: 25px;">
                        <div class="risk-head-geojit-plan">
                            <p>The purpose of insurance is risk transfer. It replaces the financial loss that happens to a family when the bread winner meets with unfortunate event of death or permanent disability.<br>
                                 Assume that you have defined all your financial goals in life and now you need different corpus of money at different intervals. All these goals would work as planned only if you supply required funds to each goal on defined intervals in the forms of SIPs or bullet investments. In case you could not fund the goals due to extreme unfortunate reasons like death or disability, an insurance cover will help to find the amount required for goal.
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan">
                            <h6>Insurance Summary</h6>
                            <p>Total goal corpus pertaining to your family is ₹2,600,000. Your total amount of uninsured loan is ₹0.
                                 Your monthly expense excluding EMI is ₹35,850. We have considered income from house property as an ongoing income. 
                                 To meet this expenses for the next 5 years, you require a corpus of ₹1,854,361. Your existing Life cover is ₹0. 
                                 Hence we recommend an excess life cover of ₹4,454,361</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan">
                            <h6>Medical Insurance</h6>
                            <p>In financial planning, the importance of medical insurance is more than life insurance. 
                                This is due to the changing food habits, increasing pollution and unpredictability of diseases. 
                                Considering the high cost of medication these days, it is advisable to have a mediclaim in place.
                                 We are forced by law to have the vehicle insurance in place. We should be forced by fear of losing
                                  our savings and depending on loans for having a mediclaim policy in place. Lets avoid unnecessary 
                                  spending on something which we have an option of transferring the risk.<br>
                                  With a 3 member family, we recommend a med claim floater policy minimum worth ₹3 lakh for you.</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan">
                            <h6>Financial Wellness Check</h6>
                            <p>Financial Health Check up is a process of assessing your financial situation using a few personal
                                 financial ratios to ascertain how safe is your position in terms of fixed income, assets and 
                                 surplus funds availability. We have used 5 major ratios for assessment.</p>
                        </div>
                    </div>
                    
                </div>
    
                <div class="col-sm-6" style="padding-top: 25px;">
                    <div class="col-sm-12 table-padding-geojit-myplan" style="padding-left: 0px;">
                        <table class="table-common-geojit table-myplan-geojit-myplan">
                            <tr class="header-table-geojit-common">
                                <th>Particulars</th>
                                <th>Amount</th>
                            </tr>
                            <tr class="allocation-table-geojit-plan">
                                <td>Total Insurance coverage required</td>
                                <td>4,454,361</td>
                            </tr>
                            <tr class="allocation-table-geojit-plan">
                                <td>Available Insurance Coverage</td>
                                <td>0</td>
                            </tr>
                            <tr class="allocation-table-geojit-plan">
                                <td>Excess Required</td>
                                <td>4,454,361</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan">
                            <h6>Things to note regarding Life Insurance</h6>
                            <p>When you opt for Life insurance always remember to add the permanent disability rider along with that.
                                 A term insurance is a must for anybody with dependant member in the family.</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan">
                            <h6>Estate Planning</h6>
                            <p>Financial Planning is all about financial freedom of self and those dependent on us. Hence planning 
                                is never complete without diligent Estate Planning.</p>
                                <p>Estate planning makes sure that your wealth is inherited by those whom you wish to. It is the best 
                                    practice to have a WILL in place at the earliest. And you can update it any time and any number of times.
                                     One need not grow old to prepare a WILL. As you go on possessing more wealth, it gets more important that
                                      it is been nominated to the right people. And now the NSDL EzeeWill has made it easy for you. This online
                                       WILL preparation platform will help you easy, hassle free and cost effective way of getting a WILL
                                        prepared for you. Do it now through our Estate Planning module*</p>
                        </div>
                    </div>
                </div>
            </div>
                <div class="col-sm-12 insurance-table-geojit-myplan" style="padding-left: 0px;padding-top: 30px;padding-right: 0px;">
                    <table class="table-common-geojit" style="width: 100%;">
                        <tr class="header-table-geojit-common">
                            <th style="width:9%">Ratio</th>
                            <th style="width: 40%;">Explanation</th>
                            <th>Your Score</th>
                            <th style=" padding-top: 10px;">
                                <ul class="text-legends-geojit-plan header-display-geojit-myplan" >
                                <li style='background-image: url("/assets/dash/green.svg");'>Good</li>
                                <li style='background-image: url("/assets/dash/yellow.svg");'>Adequate</li>
                                <li style='background-image: url("/assets/dash/orange.svg");'>Need Improvement</li>
                            </ul>
                        </th>
                        </tr>
                        <tr class="allocation-table-geojit-plan">
                            <td>Contingency Cash Position</td>
                            <td>This ratio tries to find out how much liquid
                                 investment(those which can be easily convertible to
                                  cash) you have with you to support yourself during an
                                   emergency. Theoretically you should have near cash 
                                   funds between 3 to 6 times of take home salary or
                                    monthly income</td>
                                    <td>1.39</td>
                                    <td class="align-gauge-geojit-myplan">
                                        <rg-gauge-chart [canvasWidth]="canvasWidth3" [needleValue]="needleValue" [options]="options"
                                        [name]="name2" class="gauge-chart-geojit-modal  gauge-web-geojit-modal" >
                                    </rg-gauge-chart>

                                    <rg-gauge-chart [canvasWidth]="canvasWidth4" [needleValue]="needleValue" [options]="options"
                                    [name]="name2" class="gauge-chart-geojit-modal gauge-mobile-geojit-modal" >
                                    </rg-gauge-chart>
                                   
                                </td>
                        </tr>
                        <tr class="allocation-table-geojit-plan" style="height: 158px;">
                            <td style="vertical-align: top;">Debt to Asset Position</td>
                            <td style="vertical-align: top;">This ratio gives a picture of your percentage of
                                 debt to Assets.Obviously,you should have more
                                  assets than liabilities. The amount of liability 
                                  has to be ideally less than 50% of assets.</td>
                                  <td>3%</td>
                                  <td class="align-gauge-geojit-myplan">
                                    <rg-gauge-chart [canvasWidth]="canvasWidth3" [needleValue]="needleValue" [options]="options"
                                    [name]="name2" class="gauge-chart-geojit-modal  gauge-web-geojit-modal" >
                                </rg-gauge-chart>

                                <rg-gauge-chart [canvasWidth]="canvasWidth4" [needleValue]="needleValue" [options]="options"
                                [name]="name2" class="gauge-chart-geojit-modal gauge-mobile-geojit-modal" >
                                </rg-gauge-chart>
                               
                            </td> 
                        </tr>
                        <tr class="allocation-table-geojit-plan" style="height: 158px;">
                            <td style="vertical-align: top;">Savings <br>Ratio</td>
                            <td style="vertical-align: top;">This ratio calculates the percentage of surplus money 
                                left out of your monthly income after all the expenses. You must ideally save at least 
                                10% of his monthly income.</td>
                                  <td>3%</td>
                                  <td class="align-gauge-geojit-myplan">
                                    <rg-gauge-chart [canvasWidth]="canvasWidth3" [needleValue]="needleValue" [options]="options"
                                    [name]="name2" class="gauge-chart-geojit-modal  gauge-web-geojit-modal" >
                                </rg-gauge-chart>

                                <rg-gauge-chart [canvasWidth]="canvasWidth4" [needleValue]="needleValue" [options]="options"
                                [name]="name2" class="gauge-chart-geojit-modal gauge-mobile-geojit-modal" >
                                </rg-gauge-chart>
                               
                            </td>
                        </tr>
                        <tr class="allocation-table-geojit-plan" style="height: 158px;">
                            <td style="vertical-align: top;">Solvency <br>Ratio</td>
                            <td style="vertical-align: top;">This ratio compares your net worth against your total assets. Since net worth is asset liabilities, a positive net
                                 worth means that the you will be able to prepay all of your liabilities at any time in case of any unforeseen emergency. A ratio of more than 50% is safe</td>
                            <td>97%</td>
                            <td class="align-gauge-geojit-myplan">
                                <rg-gauge-chart [canvasWidth]="canvasWidth3" [needleValue]="needleValue" [options]="options"
                                [name]="name2" class="gauge-chart-geojit-modal  gauge-web-geojit-modal" >
                            </rg-gauge-chart>

                            <rg-gauge-chart [canvasWidth]="canvasWidth4" [needleValue]="needleValue" [options]="options"
                            [name]="name2" class="gauge-chart-geojit-modal gauge-mobile-geojit-modal" >
                            </rg-gauge-chart>
                           
                        </td>
                        </tr>
                        <tr class="allocation-table-geojit-plan" style="height: 158px;">
                            <td style="vertical-align: top;">Debt Service ratio</td>
                            <td style="vertical-align: top;">This ratio calculates the percentage of monthly
                                 income used to pay debt or EMI. Ideally 
                                 you should not use more than 20% of income for EMI.</td>
                            <td>0%</td>
                            <td class="align-gauge-geojit-myplan">
                                <rg-gauge-chart [canvasWidth]="canvasWidth3" [needleValue]="needleValue" [options]="options"
                                [name]="name2" class="gauge-chart-geojit-modal  gauge-web-geojit-modal" >
                            </rg-gauge-chart>

                            <rg-gauge-chart [canvasWidth]="canvasWidth4" [needleValue]="needleValue" [options]="options"
                            [name]="name2" class="gauge-chart-geojit-modal gauge-mobile-geojit-modal" >
                            </rg-gauge-chart>
                           
                        </td>
                        </tr>
                    </table>
                </div>
        </div>
    </div>
    <div class="recommented-financial-geojit-myplan">
        <div class="risk-profile-geojit-myplan">
            <div class="row">
                <div class="col-sm-6 ">
                    <h6 style="font-size: 15px;">Recommended Allocation Split Up</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6" style="display: grid;">
                    <div class="col-sm-11 table-padding-geojit-myplan" style="padding-left: 0px;">
                        <table class="table-common-geojit table-myplan-geojit-myplan" style="margin-top: 30px;">
                            <tr class="header-table-geojit-common" style="height: 52px;">
                                <th></th>
                                <th>Category</th>
                                <th>Allocation</th>
                            </tr>
                            <tr class="allocation-table-geojit-plan" >
                                <td>Fixed Income</td>
                                <td>Income Fund</td>
                                <td>10%</td>
                            </tr>
                            <tr class="allocation-table-geojit-plan" >
                                <td>Fixed Income</td>
                                <td>Short Term Fund</td>
                                <td>10%</td>
                            </tr>
                            <tr class="allocation-table-geojit-plan" >
                                <td>Fixed Income</td>
                                <td>Dynamic Fund</td>
                                <td>20%</td>
                            </tr>
                            <tr class="allocation-table-geojit-plan" >
                                <td>Equity</td>
                                <td>Flexi Cap Fund</td>
                                <td>10%</td>
                            </tr>
                            <tr class="allocation-table-geojit-plan" style="border-bottom: none;">
                                <td>Gold</td>
                                <td>Gold Fund</td>
                                <td>10%</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-sm-6" style="padding-top: 25px;">
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Fixed Deposit / Post Office Monthly Income Scheme</h6>
                            <p>TMutual fund Debt schemes invest in Government bonds, Treasury Bills, Bank certificate of deposit and Corporate commercial papers. If you wish to invest in Fixed Deposits, Post Office schemes, NSC, KVP, Sukanya Samriddhi Scheme, PPF, etc...then you may do so and update it in the FP module.</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Conclusion</h6>
                            <p>This brings to the end of your financial plan report. Here are a few things which you can keep in mind for future</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <button class="button" type="submit">Proceed to invest</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="conclusion-financial-geojit-myplan">
        <div class="risk-profile-geojit-myplan">
            <div class="row">
                <div class="col-sm-6 ">
                    <h6 style="font-size: 15px;">Conclusion</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12" style="display: grid;">
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <ul style="padding-left:0px">
                            <li>
                                It is important to review your plan every quarter or at least half yearly. File a copy of this report for future comparison of rates and figures.
                            </li>
                            <li>
                                You may try to align and maintain your investment portfolio to match the recommended allocation. This may not be possible suddenly but over a period of time.
                            </li>
                            <li>
                                Your Risk profile could be reviewed every year upon your renewal. However you may contact our customer care in case there are significant events in life which has caused considerable change in your risk taking capacity. In such case you may request for a change in profile (E.g. Job loss or joining a better job, Winning a lottery, Death of family member, Retirement etc.) Always keep a long term view on all investments.
                            </li>
                        <li>Always keep a long term view on all investments.</li>
                        <li>Take the first step in planning by insuring your life and the health of your family.</li>

                        </ul>
                    </div>
                </div>

                    <div class="col-sm-8" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan conclusion-footer-geojit-plan" style="padding-top: 0px;" >
                            We wish you all good health and prosperity. Looking forward for a long and fruitful relationship.
                        </div>
                    </div>
                    <div class="col-sm-4" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan" style="text-align: right;padding-right: 69px;font-weight: 600;" >
                            Authorized Signatory
                        </div>
                    </div>
            </div>
        </div>
    </div>

    <div class="final-conclusion-geojit-myplan">


        
        <div class="risk-profile-geojit-myplan" >
            <div class="row">
                <div class="col-sm-6 ">
                    <h6 style="font-size: 15px;">Recommended Allocation Split Up</h6>
                </div>
            </div>
        </div>
        <div class="risk-profile-geojit-myplan" style="height: auto;">
            <div class="row">
                <div class="col-sm-6">
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">About The Company</h6>
                            <p>Geojit Financial Services Ltd. (hereinafter Geojit),formerly known as Geojit
                                 BNP Paribas, a publically listed company, is engaged in services of retail
                                  broking, credit, portfolio management and marketing investment products
                                   including mutual funds, life and general insurance and properties and
                                    investment advisory services.</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Copyrights</h6>
                            <p>The information provided in this software remains, unless otherwise stated, the
                                 copyright of Geojit. All layout, design, original artwork, concepts and other
                                  Intellectual Properties remains the property and copyright of Geojit and may not
                                   be used in any form or for any purpose whatsoever by any party without the 
                                   express written permission of the copyright holders</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Copyrights</h6>
                            <p>The information provided in this software remains, unless otherwise stated, the copyright of Geojit. All layout, design, original artwork, concepts and other Intellectual Properties remains the property and copyright of Geojit and may not be used in any form or for any purpose whatsoever by any party without the express written permission of the copyright holders</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Not a sole basis for investment decision</h6>
                            <p>Though the analysis/reports generated by the software are prepared using generally accepted valuation methods, these should not form the sole basis for an investment decision and you should consider this particular analysis only as one of the several factors in your investment decision. You should seek professional assessment, including tax advice, on the suitability of an investment in any financial instruments, other investments or investment strategies mentioned in the report or recommended by the report. Mutual fund investments are subject to market risk. Please read the offer document carefully before investing</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Not a sole basis for investment decision</h6>
                            <p>Though the analysis/reports generated by the software are prepared using generally accepted valuation methods, these should not form the sole basis for an investment decision and you should consider this particular analysis only as one of the several factors in your investment decision. You should seek professional assessment, including tax advice, on the suitability of an investment in any financial instruments, other investments or investment strategies mentioned in the report or recommended by the report. Mutual fund investments are subject to market risk. Please read the offer document carefully before investing</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Modifications/Alterations/Updations</h6>
                            <p>This software/service is subject to change without any prior notice. Geojitreserves the right to make modifications and alterations to this software as may be required from time to time. However, Geojit is under no obligation to update or keep your information current. Nevertheless, Geojit is committed to providing independent and transparent recommendation to you and would be happy to provide any information in response to your specific queries</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Your Information*</h6>
                            <p>You acknowledge that the personal profile entered herein matches with the data already available with Geojit in case you are an existing client, and is true to your knowledge and can be substantiated with valid proof in case you are new to Geojit and you may be asked to submit the proofs for any internal or regulatory purposes. Your personal/family details entered herein is for the purpose of defining and tracking goals and does not intend in any way, to serve any other purpose or requirements of Geojit</p>
                        </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Assumptions And Estimates</h6>
                            <p>Your plan is constructed on the basis of historical returns of each asset class. Geojit may make certain assumptions over and above those which are available in the software that is edited by you. GEOJIT has no control over the format and accuracy of the information entered by you, the assumptions made by you or future changes to your profile data, and hence do not assure accuracy of the results or the outcome as the same depends wholly on the accuracy of the information entered by you. The suggested financial plan/output may not yield expected results, if any of the assumptions, both yours and that of the projections by Geojit are proved wrong due to any unforeseen external forces, including but not limited to change in interest rates, change in government policies, regulatory restrictions or systemic or non - systemic factors etc</p>
                       <p>Geojit has put in efforts to minimize deviation from expected results and is not responsible for any sort of losses or complaints owing to reasons beyond its control. All calculated values are derived out of assumptions on future interest rates and based on figures and data supplied by you. The financial planning exercise is intended to present a frame work and guide you in achieving your financial goals. Geojit do not hold any biased view towards any of the contents included in the software and the recommendations given have to be exercised by you after due diligence and understanding</p>
                       <p>All estimates regarding expected returns, inflation, equity expected returns, debt returns and gold returns etc. are based on assessments and analysis of prevailing economic conditions, historical data and market trends, Industry intelligence and third party data including research reports thereto. However, no guarantee of future performance will be given and results may vary from expected depending on the changes in external scenarios. The figures derived in the plan/output generated are a product of such assumptions and may not be accurate. Frequent monitoring of figures is essential to ascertain the deviations from estimated results, if any and you may take individual action to bridge the gaps</p>
                    </div>
                    </div>
                    <div class="col-sm-11" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6 style="margin-bottom: 0px;">Consent</h6>
                            <p>You agree that the information gathered from your profile will be used only to enhance your experience on the portal unless otherwise specific allowed by you. Geojit can change all or any terms of this service without any obligation or notice</p>
                    </div>
                    </div>
                </div>
                <div class="col-sm-6" style="padding-top: 25px;">
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>About The Software</h6>
                            <p>This Software is a tool that has been prepared for Online Financial Planning, by the Investment Advisory Division of Geojit to enable your risk profiling, and to help you with financial planning/ advice related to investment products which suits your derived risk profile. Selection of schemes from our list is recommended as they have been provided to suit your risk profile. However, this is not to be considered as an invitation or persuasion to trade or invest</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Accuracy Disclaimers</h6>
                            <p>Though all efforts have been made from our end to provide you with satisfactory services, we do not represent that the planning or report/output generated is accurate or error free. Geojit shall not in any way be responsible for any loss or damage that may arise to you/any person from any inadvertent error in the input information provided by you or output generated by this software. Neither Geojit nor any of its affiliates, group companies, directors, employees, agents or representatives shall be liable for any damages whether direct, indirect, special or consequential including lost revenue or lost profits that may arise from or in connection with the use of this software or output generated</p>
                            <p>This software is designed to capture Stock as well as mutual fund (MF) information from the DP account for existing investors. Even though the values are set to change on a daily basis, sale and purchase of stocks has to be cross checked manually by you with your DP. Further, F&O, Currency, Commodities and other transactions are not captured in the software and they do not form a part of the portfolio due to their short term nature. Hence Geojit is not liable for any discrepancy in the values reflecting in both the facilities (DP as well as FP Software) and the same is subject to your review</p>
                            <p>The Current value of investment in Insurance reflects mere addition of premiums paid and is not an indicator of any returns. No assumptions are made on Insurance returns, bonuses or maturity values. All this data is provided by you for calculation purposes.</p>
                       <p>The Geojit financial plan will be for the sole use of the person to whom it is addressed. No responsibility will be accepted in case of use of the same either wholly or partly by any third party.</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Confidentiality</h6>
                            <p>The output generated from this software is strictly confidential and is being furnished to you solely for your information. The information contained herein and/or the output generated should not be reproduced or redistributed or passed on directly or indirectly in any form to any other person or published, copied, in whole or in part, for any purpose or use by, any person or entity who is a citizen or resident of or located in any locality, state, country or other jurisdiction, where such distribution, publication, availability or use would be contrary to law, regulation or which would subject Geojit to any registration or licensing requirements within such jurisdiction</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Disclosures</h6>
                            <p>The disclosures of interest statements incorporated in this document are provided solely to enhance the transparency and should not be treated as endorsement of the views expressed in the output. Geojit generally discourages its advisors, persons reporting to advisors and their dependents from maintaining a financial interest in the securities or derivatives of any companies that the advisory covers</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Risk Profiling</h6>
                            <p>The series of questions appearing in risk assessment questionnaire are designed to help Geojit to appraise your risk profile, particular needs, financial situation, investment objectives and your capacity to invest in certain financial instruments. However, the questions, though fair and clear, serve only as a guide to determine your profile and shall not be considered to be conclusive. Further, you are required to provide accurate information about your general behaviour, including but not limited to your age, investment objectives, purpose of investment, income details, existing investment /assets and your risk appetite/tolerance, failing which your result may be adversely affected</p>
                            <p>The risk profiling is done on the basis of data provided by you and notwithstanding the above, is also subject to your lifestyle, health and financial situation, income/expense and appreciation or depreciation of assets/liability details. The assumed interest rates and other assumptions that are shown in the system depend on your risk profiling result.Calculations of required amounts and investment requisitions may vary</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>Force Majeure</h6>
                            <p>Geojit and its owners/affiliates are not liable for damages caused by any performance, failure of performance, error, omission, interruption, deletion, defect, delay in transmission or operations, computer virus, communications line failure, and unauthorized access to the personal accounts. Geojit is not responsible for any technical failure or malfunctioning of the software or delays of any kind or for non-receipt of registration details, alerts or e-mails by you. You shall bear all responsibility of keeping the password and credentials secure. Geojit shall not be responsible for the loss or misuse of your password</p>
                        </div>
                    </div>
                    <div class="col-sm-12" style="padding-left: 0px;">
                        <div class="risk-head-geojit-plan insurance-head-geojit-plan" >
                            <h6>External Content</h6>
                            <p>Geojit is not responsible for the content of any of the linked sites. By providing access to other web-sites, Geojit is neither recommending nor endorsing the content available in the linked websites</p>
                            <p>I acknowledge, agree and accept that my personal information and data shall be shared/exchanged between my Geojit Investment Advisory Profile and Financial Planning Tool and vice versa. I also understand that my personal information/data shall be used for contests or promotional schemes that I may be made party to from time to time during the use of this Investment Advisory Financial Planning Tool. Further, I hereby give my express consent to the sharing of my information/data with regulatory bodies in response to queries by regulatory bodies for any legal and/ or statutory disclosures that may arise. I shall notify Geojit immediately of any change in the material information provided by me.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-green-geojit-myplan">If you do not agree to any of the terms mentioned in this disclaimer, you should exit this software</div>
</div>



<div class="container">
    <div class="modal" id="invest">
      <div class="modal-dialog modal-dialog-geojit-about">
        <div class="modal-content" style="height:426px;max-width: 385px;">
          <!-- Modal Header -->
          <div class="modal-header" style="padding: 0px;border: none;height: 26px;">
            <button type="button" class="close" data-dismiss="modal" style="padding-top: 18px;padding-right: 23px;"> 
              <img src="assets/dash/close.svg"></button>
          </div>
          <!-- Modal body -->
          <div class="modal-body modal-invest-geojit-about">
            <img src="assets/about/modal-img.svg">
            <p>Do you want to allocate your existing investment?</p>
            <button data-dismiss="modal" class="save-btn-geojit-common" style="margin-left: 15px;width: 178px;margin-top: 18px;">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>